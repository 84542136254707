import React from "react";
import { Button } from "react-bootstrap";
import doctor from "../../../Assets/Images/dashboard/doctor.png";
import care from "../../../Assets/Images/dashboard/care.png";
import { FaCheck } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function DashHome() {
  const navigate = useNavigate();

  const handleProviderNavigation = () => {
    navigate("/new-appointment");
  };
  return (
    <div className="dash_home">
      <h4 className="heading_txt">Choose your required ?</h4>
      <div className="choose-required">
        <div className=" d-flex align-items-center justify-content-between">
          <p className="mb-0 title-txt">Choose Required</p>
              <Link to="/login" className="text-decoration-none">
          <Button className="back-btn cmn-grayBtn">Back</Button>
          </Link> 
        </div>
        <div className="require_sec mt-4">
          <Link to="/new-appointment-booking" className="text-decoration-none">
            <div className="require_card text-center active">
              <img src={care} alt="" className="img-fluid cmn-img" />
              <p className="type mb-0 mt-3">Care</p>
              <span className="checked">
                <FaCheck size={12} />
              </span>
            </div>
          </Link>
          <span className="or-txt">(or)</span>
          <div
            className="require_card text-center"
            onClick={handleProviderNavigation}
          >
            <img src={doctor} alt="" className="img-fluid cmn-img" />
            <p className="type mb-0 mt-3">Provider</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashHome;
