import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { FaMinus } from "react-icons/fa6";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import DoctorCustomInput from "../components/DoctorCustomInput";
import { Button, Col, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { isEmpty } from "../../Actions/common/common";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaTrash } from "react-icons/fa";

const serviceCategoryOptions = [
  { value: "catogory1", label: "Category 1" },
  { value: "catogory2", label: "Category 2" },
  { value: "catogory3", label: "Category 3" },
];
const serviceNameOptions = [
  { value: "servicename1", label: "Service Name 1" },
  { value: "servicename2", label: "Service Name 2" },
  { value: "servicename2", label: "Service Name 3" },
];
const chargesOptions = [
  { value: "200", label: "200 $" },
  { value: "250", label: "250 $" },
  { value: "300", label: "300 $" },
];
const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "In Active" },
];

function AvailabilityAddCare() {
  const navigation = useNavigate();
  const [fromDate, setFromDate] = useState(new Date());
  const initdaytiming = {
    Sunday: {
      day: "Sunday",
      Timing: {
        day: "Sunday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Sunday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Sunday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
    Monday: {
      day: "Monday",
      Timing: {
        day: "Monday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Monday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Monday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
    Tuesday: {
      day: "Tuesday",
      Timing: {
        day: "Tuesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Tuesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Tuesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
    Wednesday: {
      day: "Wednesday",
      Timing: {
        day: "Wednesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Wednesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Wednesday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
    Thursday: {
      day: "Thursday",
      Timing: {
        day: "Thursday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Thursday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Thursday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
    Friday: {
      day: "Friday",
      Timing: {
        day: "Friday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Friday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Friday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
    Saturday: {
      day: "Saturday",
      Timing: {
        day: "Saturday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Delivery: {
        day: "Saturday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
      Pickup: {
        day: "Saturday",
        Status: false,
        hours: false,
        error: "",
        timing: [],
      },
    },
  };
  const [DayTimings, setDayTimings] = useState(initdaytiming);
  const [HourMinuteDisplay, SetHourMinuteDisplay] = useState({
    Hours: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ],
    Minutes: [1, 2, 3, 4],
  });
  const [SelectedTime, SetSelectedTime] = useState({
    Timing: [],
    Pickup: [],
    Delivery: [],
  });
  var [selectCheck, SetSelectCheck] = useState(true);

  // const OnChangeTime = (e, index, day, type, timingtype) => {
  //   try {
  //     let setvalue = {};
  //     if (type == "checkbox") {
  //       setvalue = {
  //         ...SelectedTime,
  //         ...{
  //           [timingtype]: [
  //             ...SelectedTime?.[timingtype]?.slice(0, index),
  //             ...[
  //               {
  //                 ...SelectedTime?.[timingtype][index],
  //                 ...{
  //                   [day]: e?.target?.checked,
  //                 },
  //               },
  //             ],
  //             ...SelectedTime?.[timingtype]?.slice(
  //               index + 1,
  //               SelectedTime?.[timingtype]?.length
  //             ),
  //           ],
  //         },
  //       };
  //       // SetSelectedTime(setvalue)
  //     } else if (type == "time") {
  //       e._d = new Date(e._d.setSeconds(0, 0));
  //       console.log(
  //         "lksjkjsfsfs",
  //         e._d,
  //         SelectedTime?.[timingtype]?.[index]?.StartTimeshow,
  //         SelectedTime?.[timingtype]?.[index]?.EndTimeshow
  //       );
  //       if (day == "EndTime") {
  //         if (SelectedTime?.[timingtype]?.[index]?.StartTimeshow >= e._d) {
  //           toast.error("Time Choosen Must be Greater than Start Time");
  //           SetSelectedTime({ ...SelectedTime });
  //           setDayTimings({ ...DayTimings });
  //           return false;
  //         }
  //       } else if (day == "StartTime") {
  //         if (SelectedTime?.[timingtype]?.[index]?.EndTimeshow <= e._d) {
  //           toast.error("Time Choosen Must be Less than End Time");
  //           SetSelectedTime({ ...SelectedTime });
  //           setDayTimings({ ...DayTimings });
  //           return false;
  //         }
  //       }
  //       setvalue = {
  //         ...SelectedTime,
  //         ...{
  //           [timingtype]: [
  //             ...SelectedTime?.[timingtype]?.slice(0, index),
  //             ...[
  //               {
  //                 ...SelectedTime?.[timingtype]?.[index],
  //                 ...{
  //                   [day]:
  //                     MinuteHourFormat(e._d.getHours()) +
  //                     ":" +
  //                     MinuteHourFormat(e._d.getMinutes()),
  //                   [day + "show"]: e._d,
  //                 },
  //               },
  //             ],
  //             ...SelectedTime?.[timingtype]?.slice(
  //               index + 1,
  //               SelectedTime?.[timingtype]?.length
  //             ),
  //           ],
  //         },
  //       };
  //     }
  //     let instdaytiming = DayTimings;
  //     let Time = {
  //       StartTime: HourMinutetoTime(
  //         setvalue?.[timingtype]?.[index]?.StartTime.split(":")[0],
  //         setvalue?.[timingtype]?.[index]?.StartTime.split(":")[1]
  //       ),
  //       EndTime: HourMinutetoTime(
  //         setvalue?.[timingtype]?.[index]?.EndTime.split(":")[0],
  //         setvalue?.[timingtype]?.[index]?.EndTime.split(":")[1]
  //       ),
  //     };

  //     if ((type == "checkbox" && e?.target?.checked) || type == "time") {
  //       Object.keys(setvalue?.[timingtype]?.[index])?.map((val) => {
  //         if (
  //           val != "StartTime" &&
  //           val != "StartTimeshow" &&
  //           val != "EndTime" &&
  //           val != "EndTimeshow"
  //         ) {
  //           if (DayTimings?.[val] && setvalue?.[timingtype]?.[index]?.[val]) {
  //             let curdaytimeind = DayTimings?.[val]?.[
  //               timingtype
  //             ]?.timing?.findIndex(
  //               (findindexval) =>
  //                 findindexval?.StartTime ==
  //                   SelectedTime?.[timingtype]?.[index]?.StartTime &&
  //                 findindexval?.EndTime ==
  //                   SelectedTime?.[timingtype]?.[index]?.EndTime
  //             );
  //             if (curdaytimeind != -1) {
  //               instdaytiming = {
  //                 ...instdaytiming,
  //                 ...{
  //                   [val]: {
  //                     ...instdaytiming?.[val],
  //                     ...{
  //                       [timingtype]: {
  //                         ...instdaytiming?.[val]?.[timingtype],
  //                         ...{
  //                           Status: true,
  //                           timing: [
  //                             ...instdaytiming?.[val]?.[
  //                               timingtype
  //                             ]?.timing?.slice(0, curdaytimeind),
  //                             ...[
  //                               {
  //                                 ...instdaytiming[val]?.[timingtype]?.timing?.[
  //                                   curdaytimeind
  //                                 ],
  //                                 ...{
  //                                   StartTime:
  //                                     setvalue?.[timingtype]?.[index]
  //                                       ?.StartTime,
  //                                   StartHour:
  //                                     setvalue?.[timingtype]?.[
  //                                       index
  //                                     ]?.StartTime?.split(":")[0],
  //                                   StartMinute:
  //                                     setvalue?.[timingtype]?.[
  //                                       index
  //                                     ]?.StartTime?.split(":")[1],
  //                                   EndTime:
  //                                     setvalue?.[timingtype]?.[index]?.EndTime,
  //                                   EndHour:
  //                                     setvalue?.[timingtype]?.[
  //                                       index
  //                                     ]?.EndTime?.split(":")[0],
  //                                   EndMinute:
  //                                     setvalue?.[timingtype]?.[
  //                                       index
  //                                     ]?.EndTime?.split(":")[1],
  //                                 },
  //                               },
  //                             ],
  //                             ...instdaytiming[val]?.[
  //                               timingtype
  //                             ]?.timing?.slice(
  //                               curdaytimeind + 1,
  //                               instdaytiming[val]?.[timingtype]?.timing?.length
  //                             ),
  //                           ],
  //                         },
  //                       },
  //                     },
  //                   },
  //                 },
  //               };
  //               Object.keys(instdaytiming?.[val])?.map((insttimingtype) => {
  //                 if (insttimingtype != "Timing" && insttimingtype != "day") {
  //                   if (
  //                     instdaytiming?.[val]?.[insttimingtype]?.Status == false ||
  //                     instdaytiming?.[val]?.[instdaytiming]?.timing?.length == 0
  //                   ) {
  //                     if (insttimingtype != "DeliveryTimeCharge") {
  //                       instdaytiming = {
  //                         ...instdaytiming,
  //                         ...{
  //                           [val]: {
  //                             ...instdaytiming[val],
  //                             ...{
  //                               [insttimingtype]: {
  //                                 ...instdaytiming[val][insttimingtype],
  //                                 ...{
  //                                   Status: true,
  //                                   AfterPreparation: [],
  //                                 },
  //                               },
  //                             },
  //                           },
  //                         },
  //                       };
  //                     } else {
  //                       instdaytiming = {
  //                         ...instdaytiming,
  //                         ...{
  //                           [val]: {
  //                             ...instdaytiming[val],
  //                             ...{
  //                               [insttimingtype]: {
  //                                 ...instdaytiming[val][insttimingtype],
  //                                 ...{
  //                                   StartHour: "",
  //                                   StartMinute: "",
  //                                   StartTime: "",
  //                                   EndHour: "",
  //                                   EndMinute: "",
  //                                   EndTime: "",
  //                                   Status: true,
  //                                   timing: [],
  //                                 },
  //                               },
  //                             },
  //                           },
  //                         },
  //                       };
  //                     }
  //                   }
  //                 }
  //               });
  //             } else {
  //               instdaytiming = {
  //                 ...instdaytiming,
  //                 ...{
  //                   [val]: {
  //                     ...instdaytiming[val],
  //                     ...{
  //                       [timingtype]: {
  //                         ...instdaytiming[val][timingtype],
  //                         ...{
  //                           Status: true,
  //                           timing: [
  //                             ...instdaytiming[val]?.[timingtype]?.timing,
  //                             ...[
  //                               {
  //                                 StartTime:
  //                                   setvalue?.[timingtype]?.[index]?.StartTime,
  //                                 StartHour:
  //                                   setvalue?.[timingtype]?.[
  //                                     index
  //                                   ]?.StartTime?.split(":")[0],
  //                                 StartMinute:
  //                                   setvalue?.[timingtype]?.[
  //                                     index
  //                                   ]?.StartTime?.split(":")[1],
  //                                 EndTime:
  //                                   setvalue?.[timingtype]?.[index]?.EndTime,
  //                                 EndHour:
  //                                   setvalue?.[timingtype]?.[
  //                                     index
  //                                   ]?.EndTime?.split(":")[0],
  //                                 EndMinute:
  //                                   setvalue?.[timingtype]?.[
  //                                     index
  //                                   ]?.EndTime?.split(":")[1],
  //                               },
  //                             ],
  //                           ],
  //                         },
  //                       },
  //                     },
  //                   },
  //                 },
  //               };
  //             }
  //           }
  //         }
  //       });
  //     } else if (type == "checkbox" && !e?.target?.checked) {
  //       let timingindex = instdaytiming?.[day]?.[timingtype]?.timing?.findIndex(
  //         (daytimeindex) =>
  //           daytimeindex?.StartTime ==
  //             SelectedTime?.[timingtype]?.[index]?.StartTime &&
  //           daytimeindex?.EndTime ==
  //             SelectedTime?.[timingtype]?.[index]?.EndTime
  //       );
  //       if (timingindex != -1) {
  //         let newtiming = {
  //           StartHour: "",
  //           StartMinute: "",
  //           StartTime: "",
  //           StartTimeshow: "",
  //           EndHour: "",
  //           EndMinute: "",
  //           EndTime: "",
  //           EndTimeshow: "",
  //         };
  //         instdaytiming = {
  //           ...instdaytiming,
  //           ...{
  //             [day]: {
  //               ...instdaytiming[day],
  //               ...{
  //                 [timingtype]: {
  //                   ...instdaytiming[day][timingtype],
  //                   ...{
  //                     timing: [
  //                       ...instdaytiming[day]?.[timingtype]?.timing?.slice(
  //                         0,
  //                         timingindex
  //                       ),
  //                       ...instdaytiming[day]?.[timingtype]?.timing?.slice(
  //                         timingindex + 1,
  //                         instdaytiming[day]?.[timingtype]?.timing?.length
  //                       ),
  //                     ],
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         };
  //         if (timingtype == "Timing") {
  //           Object.keys(instdaytiming).map((days) => {
  //             if (
  //               instdaytiming?.[days]?.Timing?.Status &&
  //               instdaytiming?.[days]?.Timing?.timing?.length == 0
  //             ) {
  //               instdaytiming = {
  //                 ...instdaytiming,
  //                 ...{
  //                   [days]: {
  //                     ...instdaytiming[days],
  //                     ...{
  //                       [timingtype]: {
  //                         ...instdaytiming[days][timingtype],
  //                         ...{
  //                           StartHour: "",
  //                           StartMinute: "",
  //                           StartTime: "",
  //                           EndHour: "",
  //                           EndMinute: "",
  //                           EndTime: "",
  //                           Status: false,
  //                         },
  //                       },
  //                     },
  //                   },
  //                 },
  //               };
  //               Object.keys(instdaytiming?.[days])?.map((insttimingtype) => {
  //                 if (insttimingtype != "Timing" && insttimingtype != "day") {
  //                   if (
  //                     instdaytiming?.[days]?.[insttimingtype]?.Status ||
  //                     instdaytiming?.[days]?.[instdaytiming]?.timing?.length > 0
  //                   ) {
  //                     if (insttimingtype != "DeliveryTimeCharge") {
  //                       instdaytiming = {
  //                         ...instdaytiming,
  //                         ...{
  //                           [days]: {
  //                             ...instdaytiming[days],
  //                             ...{
  //                               [insttimingtype]: {
  //                                 ...instdaytiming[days][insttimingtype],
  //                                 ...{
  //                                   Status: false,
  //                                   AfterPreparation: [],
  //                                 },
  //                               },
  //                             },
  //                           },
  //                         },
  //                       };
  //                     } else {
  //                       instdaytiming = {
  //                         ...instdaytiming,
  //                         ...{
  //                           [days]: {
  //                             ...instdaytiming[days],
  //                             ...{
  //                               [insttimingtype]: {
  //                                 ...instdaytiming[days][insttimingtype],
  //                                 ...{
  //                                   StartHour: "",
  //                                   StartMinute: "",
  //                                   StartTime: "",
  //                                   EndHour: "",
  //                                   EndMinute: "",
  //                                   EndTime: "",
  //                                   Status: false,
  //                                   timing: [],
  //                                 },
  //                               },
  //                             },
  //                           },
  //                         },
  //                       };
  //                     }
  //                   }
  //                 }
  //               });
  //             }
  //           });
  //         }
  //       }
  //     }
  //     Object.keys(instdaytiming).map((days) => {
  //       if (instdaytiming[days][timingtype]?.Status) {
  //         let maxtime = GetMaxendMinstart(instdaytiming, days, timingtype);
  //         instdaytiming = {
  //           ...instdaytiming,
  //           ...{
  //             [days]: {
  //               ...instdaytiming[days],
  //               ...{
  //                 [timingtype]: {
  //                   ...instdaytiming[days][timingtype],
  //                   ...maxtime,
  //                 },
  //               },
  //             },
  //           },
  //         };
  //       }
  //     });

  //     SetSelectedTime({ ...setvalue });
  //     setDayTimings({ ...instdaytiming });
  //   } catch (err) {
  //     console.log("OnChangeTime Error", err);
  //   }
  // };
  // const DisableCondition = () => {
  //   // return (shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') ? false : true
  //   return false;
  // };

  const HourMinutetoTime = (hour, minute, from) => {
    if (isEmpty(hour) && isEmpty(minute) && hour !== 0 && minute !== 0) {
      if (from == "end") {
        return new Date(new Date().setHours(0, 0));
      } else if (from == "start") {
        return new Date(new Date().setHours(0, 0, 0));
      }
      return new Date(new Date().setHours(23, 59, 59));
    }
    if (0 == Number(hour) && 0 == Number(minute)) {
      return new Date(new Date().setHours(0, 0, 0, 0));
    }
    return new Date(
      new Date().setHours(
        hour >= 24 ? 23 : hour,
        hour >= 24 ? 59 : minute,
        minute == 59 || hour >= 24 ? 59 : 0
      )
    );
  };

  const handleAddCare = () => {
    navigation("/doctor-care-list");
  };

  return (
    <>
      {/* start of modal import */}

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4  custom_container_padding">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h5 className="text_black_md mb-0">Add Care</h5>
              <Link to="/availability" className="text-decoration-none">
                <Button className="cmn-greenBtn cancel-careBtn d-flex align-items-center justify-content-between gap-2">
                  {" "}
                  <FaMinus />
                  Close Care
                </Button>
              </Link>
            </div>
            <div className="mt-4">
              <div className="row mx-auto">
                <div className="col-sm-8 col-md-7 col-lg-6 col-xl-5 px-0">
                  <div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">
                        Service Category
                      </label>
                      <div className="mt-2 w-100">
                        <Select
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={serviceCategoryOptions}
                          placeholder="Service Category"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">Service Name</label>
                      <div className="mt-2 w-100">
                        <Select
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={serviceNameOptions}
                          placeholder="Service Name"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">Charges</label>
                      <div className="mt-2 w-100">
                        <Select
                          isSearchable={false}
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          options={chargesOptions}
                          placeholder="200 $"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">Status</label>
                      <div className="mt-2 w-100">
                        <Select
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={statusOptions}
                          placeholder="Active"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-10">
                  <div className="d-flex flex-column align-items-start ">
                    <label className="custom_label px-0">Duration</label>
                    <div className="mt-2 w-100">
                      {/* <DoctorCustomInput placeholder="HH : MM" /> */}

                      <div className="mangra_overflow_align mangra_overflow_align_new">
                        <div className="avail_timing">
                          <div className="d-flex align-items-center manual_graph_timing">
                            <p>Day</p>
                            <p className="mid_frm_lft">Midnight</p>
                            <p className="four_frm_lft">4AM</p>
                            <p className="egt_frm_lft">8AM</p>
                            <p className="lnc_frm_lft">Lunch</p>
                            <p className="frpm_frm_lft">4PM</p>
                            <p className="egtpm_frm_lft">8PM</p>
                          </div>

                          {Object.keys(DayTimings)?.map((val) => {
                            return (
                              <div className="manual_graph_single man_grp_sng_gap d-flex align-items-center">
                                <p className="manualgra_yaxis_txt mb-0 me-3">
                                  {val.substring(0, 3)}
                                </p>
                                {HourMinuteDisplay?.Hours?.map((hour) => {
                                  return (
                                    <div className="single_square">
                                      {HourMinuteDisplay?.Minutes?.map(
                                        (minute) => {
                                          return (
                                            <div
                                              className={
                                                DayTimings?.[val]?.Timing
                                                  ?.timing?.length > 0
                                                  ? DayTimings?.[
                                                      val
                                                    ]?.Timing?.timing?.some(
                                                      (val) =>
                                                        HourMinutetoTime(
                                                          hour,
                                                          minute * 15
                                                        ) >=
                                                          HourMinutetoTime(
                                                            val?.StartHour,
                                                            val?.StartMinute,
                                                            "start"
                                                          ) &&
                                                        HourMinutetoTime(
                                                          hour,
                                                          minute * 15
                                                        ) <=
                                                          HourMinutetoTime(
                                                            val?.EndHour,
                                                            val?.EndMinute,
                                                            "end"
                                                          ) &&
                                                        (minute * 15 !== 60 ||
                                                          (minute * 15 == 60 &&
                                                            hour !=
                                                              Number(
                                                                val?.StartHour
                                                              ) -
                                                                1))
                                                    )
                                                    ? "timing_div"
                                                    : "test111"
                                                  : "test111"
                                              }
                                            ></div>
                                          );
                                        }
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                        <div className="mt-5">
                          {selectCheck && (
                            <>
                              <div className="cmn_ttl_aligns d-flex align-items-start">
                                <div className="title_percent_ttl me-5 w-50">
                                  <p className="checkbox_title  text_black_md">
                                    Select day(s):
                                  </p>
                                  <div className="daysslct_ttl  d-flex align-items-center gap-4">
                                    {Object.keys(DayTimings)?.map((val) => {
                                      return (
                                        <div className="show_days_div">
                                          <p className="text_green_md mb-2">
                                            {val?.substring(0, 1)}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>{" "}
                                </div>
                                <div className="title_percent_ttl  w-50">
                                  <p className="checkbox_title  text_black_md">
                                    Select times:
                                  </p>
                                  <div className="d-flex align-items-start gap-3">
                                    <div className="date_min_widt">
                                      <p className="text_green_md mb-2">From</p>
                                    </div>
                                    <div className="date_min_widt">
                                      <p className="text_green_md mb-2">To</p>
                                    </div>
                                    <div className="date_min_widt">
                                      <p className="text_green_md mb-2">
                                        Action
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cmn_ttl_aligns d-flex align-items-start">
                                <div className="title_percent_ttl me-5 w-50">
                                  <div className="daysslct_ttl  d-flex align-items-center gap-4">
                                    {Object.keys(DayTimings)?.map((val) => {
                                      return (
                                        <div className="show_days_div">
                                          <Form.Check type="checkbox">
                                            <Form.Check.Input type="checkbox" />
                                          </Form.Check>
                                        </div>
                                      );
                                    })}
                                  </div>{" "}
                                </div>
                                <div className="title_percent_ttl w-50">
                                  <div className="d-flex align-items-start gap-3">
                                    <div className="date_min_widt">
                                      <div className="customDatePicker">
                                        {/* <DatePicker
                                          selected={fromDate}
                                          onChange={(date) => setFromDate(date)}
                                        /> */}
                                        <Datetime
                                          viewMode={"time"}
                                          // value={
                                          //   SelectedTime?.Timing?.[ind]
                                          //     ?.StartTimeshow
                                          // }
                                          // onViewModeChange={'time'}
                                          dateFormat={false}
                                          // onChange={(e) => {
                                          //   OnChangeTime(
                                          //     e,
                                          //     ind,
                                          //     "StartTime",
                                          //     "time",
                                          //     "Timing"
                                          //   );
                                          // }}
                                          // disabled={DisableCondition()}
                                        />
                                      </div>
                                    </div>
                                    <div className="date_min_widt">
                                      <div className="customDatePicker">
                                        <Datetime
                                          viewMode={"time"}
                                          placeholder="start"
                                          // value={
                                          //   SelectedTime?.Timing?.[ind]
                                          //     ?.StartTimeshow
                                          // }
                                          // onViewModeChange={'time'}
                                          dateFormat={false}
                                          // onChange={(e) => {
                                          //   OnChangeTime(
                                          //     e,
                                          //     ind,
                                          //     "StartTime",
                                          //     "time",
                                          //     "Timing"
                                          //   );
                                          // }}
                                          // disabled={DisableCondition()}
                                        />
                                      </div>
                                    </div>

                                    <button className="date_min_widt bg-transparent outline-0 border-0 text-start">
                                      <FaTrash fontSize={25} fill="#033e30" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="whole_check_datepick mt-3">
                                <div className="dateselect_dtls"></div>
                              </div>

                              <div className="triple_copybtns d-flex align-items-center gap-4 mt-4">
                                <button className="pm_button_one">
                                  Add Time
                                </button>{" "}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 mt_2rem">
                <button className="pm_button_two" onClick={handleAddCare}>
                  Cancel
                </button>
                <button className="pm_button_one" onClick={handleAddCare}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailabilityAddCare;
