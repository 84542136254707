import React from "react";
import Table from "react-bootstrap/Table";
import { data } from "../data/recordsData";
import { useNavigate } from "react-router-dom";

const RecordsTable = (props) => {
  const { handleShowPrescription } = props;
  const navigation = useNavigate();
  const handleReports = (getData) => {
    navigation("/addreport");
  };
  return (
    <div className="custom_bs_table ">
      <Table responsive>
        <thead>
          <tr>
            {data.recordsTable?.tableHead?.map((item, index) => (
              <th key={item.id}>{item.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.recordsTable?.tableBody?.map((tableRow, index) => (
            <tr key={tableRow.id}>
              {tableRow.trValue.map((tableData, index) => (
                <>
                  <td>{tableData.id}</td>
                  <td>{tableData.appointmentDate}</td>
                  <td>{tableData.onlineOrOffline}</td>
                  <td>{tableData.patientsName}</td>
                  <td>{tableData.desease}</td>
                  <td>{tableData.visitInfo}</td>
                  <td>{tableData.paymentStatus}</td>
                  <td>
                    <button
                      className="bg-transparent border-0 outline-0"
                      onClick={() => handleReports(tableData)}
                    >
                      {tableData.reports}
                    </button>
                  </td>
                  <td>
                    {" "}
                    <button
                      className="bg-transparent border-0 outline-0"
                      onClick={handleShowPrescription}
                    >
                      {tableData.Prescriptions}
                    </button>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RecordsTable;
