import React, { useState } from "react";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import DoctorCustomInputTwo from "../components/DoctorCustomInputTwo";
import eyeIcon from "../../Assets/Images/eye.svg";
import { useNavigate } from "react-router-dom";

function DoctorAddReport() {
  const [inputValue, setInputValues] = useState({
    laborder: "",
    labreport: "",
    labresults: "",
    medicalrecords: "",
    patientinstructions: "",
    visitsummary: "",
    mipsvisitsummary: "",
    letters: "",
    insurancecard: "",
    summary: "",
  });

  const navigate = useNavigate();

  const {
    laborder,
    labreport,
    labresults,
    medicalrecords,
    patientinstructions,
    visitsummary,
    mipsvisitsummary,
    letters,
    insurancecard,
    summary,
  } = inputValue;

  const handleReportInput = (e) => {
    const { value, name } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCloseAddReport = () => {
    navigate("/doctor-records");
  };

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="container px-xl-0">
          <div className="mt-4">
            <h5 className="text_green_lg">Add Report</h5>
            <div className="mt-4">
              <div className="row mx-auto">
                <div className="col-12 col-sm-11 col-md-10 col-lg-11 px-0">
                  <div className="row mx-auto">
                    <div className="col-12 col-lg-6 ps-0 pe-4 pe_xl_2rem ">
                      <div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">Lab Order</label>
                          <div className="mt-2 w-100">
                            <div className="choose_custom_input d-flex align-items-center">
                              <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                <p className="mb-0 choosen_file_name">
                                  Demo.png
                                </p>
                                <button className="bg-transparent border-0 outline-0">
                                  <img
                                    src={eyeIcon}
                                    alt="eye"
                                    className="eye_icon"
                                    style={{ width: "18px" }}
                                  />
                                </button>
                              </div>
                              <button className="choose_file_button">
                                Choose File
                              </button>
                              <input
                                type="file"
                                placeholder="Demo"
                                className="d-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">Lab Report</label>
                          <div className="mt-2 w-100">
                            <div className="choose_custom_input d-flex align-items-center">
                              <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                <p className="mb-0 choosen_file_name">
                                  Demo.png
                                </p>
                                <button className="bg-transparent border-0 outline-0">
                                  <img
                                    src={eyeIcon}
                                    alt="eye"
                                    className="eye_icon"
                                    style={{ width: "18px" }}
                                  />
                                </button>
                              </div>
                              <button className="choose_file_button">
                                Choose File
                              </button>
                              <input
                                type="file"
                                placeholder="Demo"
                                className="d-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">
                            Lab Order & Results
                          </label>
                          <div className="mt-2 w-100">
                            <div className="choose_custom_input d-flex align-items-center">
                              <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                <p className="mb-0 choosen_file_name">
                                  Demo.png
                                </p>
                                <button className="bg-transparent border-0 outline-0">
                                  <img
                                    src={eyeIcon}
                                    alt="eye"
                                    className="eye_icon"
                                    style={{ width: "18px" }}
                                  />
                                </button>
                              </div>
                              <button className="choose_file_button">
                                Choose File
                              </button>
                              <input
                                type="file"
                                placeholder="Demo"
                                className="d-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">
                            Medical Records
                          </label>
                          <div className="mt-2 w-100">
                            <div className="choose_custom_input d-flex align-items-center">
                              <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                <p className="mb-0 choosen_file_name">
                                  Demo.png
                                </p>
                                <button className="bg-transparent border-0 outline-0">
                                  <img
                                    src={eyeIcon}
                                    alt="eye"
                                    className="eye_icon"
                                    style={{ width: "18px" }}
                                  />
                                </button>
                              </div>
                              <button className="choose_file_button">
                                Choose File
                              </button>
                              <input
                                type="file"
                                placeholder="Demo"
                                className="d-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">
                            Patient Instructions
                          </label>
                          <div className="mt-2 w-100">
                            <input
                              placeholder="Demo"
                              className="custom_input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 ps-4  pe-0 ps_xl_2rem">
                      <div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">Visit Summary</label>
                          <div className="mt-2 w-100">
                            <input
                              placeholder="Demo"
                              className="custom_input"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">
                            MIPS Visit Summary
                          </label>
                          <div className="mt-2 w-100">
                            <input
                              placeholder="Demo"
                              className="custom_input"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">Letters</label>
                          <div className="mt-2 w-100">
                            <input
                              placeholder="Demo"
                              className="custom_input"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">Insurance Card</label>
                          <div className="mt-2 w-100">
                            <div className="choose_custom_input d-flex align-items-center">
                              <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                <p className="mb-0 choosen_file_name">
                                  Demo.png
                                </p>
                                <button className="bg-transparent border-0 outline-0">
                                  <img
                                    src={eyeIcon}
                                    alt="eye"
                                    className="eye_icon"
                                    style={{ width: "18px" }}
                                  />
                                </button>
                              </div>
                              <button className="choose_file_button">
                                Choose File
                              </button>
                              <input
                                type="file"
                                placeholder="Demo"
                                className="d-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-4">
                          <label className="custom_label">Summary</label>
                          <div className="mt-2 w-100">
                            <input
                              placeholder="Demo"
                              className="custom_input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100">
                    <label>Others</label>
                    <textarea
                      name=""
                      onChange={handleReportInput}
                      className="custom_textarea mt-2"
                      placeholder="Demo"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center gap-3 mt_2rem">
                <button
                  className="pm_button_two"
                  onClick={handleCloseAddReport}
                >
                  Cancel
                </button>

                <button
                  className="pm_button_one"
                  onClick={handleCloseAddReport}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorAddReport;
