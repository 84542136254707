import React, { useState } from "react";
import { Button } from "react-bootstrap";
import care from "../../../Assets/Images/dashboard/care.png";
import pressure from "../../../Assets/Images/dashboard/Blood_Pressure.png";
import Calendar from "react-calendar";
import men from "../../../Assets/Images/dashboard/men.png";
import women from "../../../Assets/Images/dashboard/women.png";
import fitness from "../../../Assets/Images/dashboard/fitness.png";
import immunity from "../../../Assets/Images/dashboard/disease.png";
import sleeping from "../../../Assets/Images/dashboard/sleeping.png";
import { Col, Row } from "react-bootstrap";
import clarity from "../../../Assets/Images/dashboard/eye.png";
import food from "../../../Assets/Images/dashboard/food.png";
import routines from "../../../Assets/Images/dashboard/routines.png";
import herbal from "../../../Assets/Images/dashboard/herbal.png";
import { FaCheck } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import DoctorsList from "../DoctorsList";
import check from "../../../Assets/Images/dashboard/check.png";
import doctor from "../../../Assets/Images/dashboard/doctor-two.png";
import doctorthree from "../../../Assets/Images/dashboard/doctor-three.png";
import doctorbanner from "../../../Assets/Images/dashboard/doctor-banner.png";
import { Link,useNavigate } from "react-router-dom";
import BookingCompleted from "../BookingCompleted";
import Defaultimg from '../../../Assets/Images/default.jpg'
import { isEmpty } from "../../../Actions/common/common";
import { toast } from "react-toastify";
import { patienthelpdeskGrievance } from "../../../Actions/Axios/userAxios";
import { useDispatch, useSelector } from "react-redux";
function RaiseGrievance() {
  const [formdata, setFormdata] = useState({})
  console.log("formdatas", formdata)
  const [ValidateError, SetValidateError] = useState('')
  const [patientGrievance, setPatientGrievance] = useState('')
  console.log("patientGrievance", patientGrievance);

  const navigate = useNavigate()
  const {patientRegister}  = useSelector((state) => state.LoginReducer);
  console.log("payload", patientRegister);
  const dispatch = useDispatch();


  const handlechange = async (e) => {

    e.preventDefault();
    const { id } = e.target
    console.log('dkfjkgjkdjjg', id)
    if (id === "file") {
      var files = e.target.files[0]
      var formData = formdata
      formData.file = files
      console.log("files", files)
      setFormdata({ ...formData })
    } else {
      const { value, id } = e.target;
      var formData = formdata
      formData[id] = value
      console.log("values", value, id)
      setFormdata({ ...formData })
    }
    console.log("formdata", formData)
  }

  const Validation = () => {

    let ValidateError = {}


    if (!formdata.yourproblem) {
      ValidateError.yourproblem = "yourproblem is required";
    }

    if (!formdata?.description) {
      ValidateError.description = "description is required"

    }
    if (!formdata.file) {
      ValidateError.file = "file is required";
    }
    SetValidateError(ValidateError)
    return ValidateError
  }

  const handleSubmit = async () => {

    console.log("formdarta", formdata)
    var errors = {};
    let value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    }
    else{

      if (Object.keys(errors).length == 0) {
  
        console.log("erro length", Object.keys(errors).length, formdata)
         const formDatas = new FormData();
  
         formDatas.append("yourproblems",formdata?.yourproblem)
         formDatas.append("description",formdata?.description);
         formDatas.append("patient_id",patientRegister?._id);
         formDatas.append("image",formdata?.file);

         formDatas.append("action","add")
  
         console.log("payload",formDatas.get('heading'),formDatas.get('img'))
        // let formDatas = {
        //   "yourproblems": formdata?.yourproblem,
        //   "description":formdata?.description,
        //   "patient_id": patientRegister?._id,
        //   "image": formdata?.file,
        //   "action":"add"
  
        // }
        console.log("formDatas",formDatas);
        let resp = await patienthelpdeskGrievance(formDatas);
        console.log("reereee",resp);
        if (resp?.status == true) {
          toast.success(resp.message)
          dispatch({
            type:"patient_grievance",
            PatientGrievance:{PatientGrievance :resp?.data}
          })
          setPatientGrievance(resp?.data)
          // setTimeout(() => {
            navigate("/dashboard")
          // }, 1000);
  
        }
        else return toast.error(resp.message)
  
      }
    }
   
  }

  return (
    <div className="raise_grievance">
      <div className="header_sec">
        <h4 className="cmnDash_heading_txt">Help Desk</h4>
        <p className="des_txt mb-0">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
          <div className="content-sec mt-3">
            <h5 className="Create New Ticket fw-700 mb-2">Create New Ticket</h5>
            <p className="des">
              Fill up all information here, then click submit button
            </p>
            <div className="patientForm create_ticket mt-4">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Your Problems <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control as="textarea" className="cmn_input_field" id="yourproblem" value={formdata?.yourproblem} onChange={(e) => handlechange(e)}></Form.Control>
                  {ValidateError && ValidateError.yourproblem && <span className="error_msg">{ValidateError.yourproblem}</span>}

                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Descriptions <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control as="textarea" className="cmn_input_field" id="description" value={formdata?.description} onChange={(e) => handlechange(e)}></Form.Control>
                  {ValidateError && ValidateError.description && <span className="error_msg">{ValidateError.description}</span>}

                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Attachments <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <div className="custom_Attach_Formcontrol">
                    <div class="upload_file_attachment">
                      <button class="btn">
                        Drag file to attach (or)
                        <span className="browse_txt"> Browse</span>
                      </button>
                      <input type="file" id="file" onChange={(e) => handlechange(e)} />
                      {ValidateError && ValidateError.file && <span className="error_msg">{ValidateError.file}</span>}


                    </div><br/>
                    {(

                      <img
                        src={
                          formdata.file
                            ? typeof formdata.file == "object"
                              ? window.URL.createObjectURL(
                                formdata.file
                              )
                              : ""
                            : Defaultimg
                        }
                        width={100}
                      />

                    )}
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="mt-4 mt-lg-5 d-flex gap-3 align-items-center">
              <Button className="cancelBtn cmn-grayBtn1">Cancel</Button>
              <Button className="submitBtn cmn-greenBtn" onClick={()=>{handleSubmit()}}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaiseGrievance;
