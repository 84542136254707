import React from 'react'

const Notifications = () => {
    const notifications = [
        {
            img : require('../../Assets/Images/dashboard/dashImage/user1.png'),
            desc : 'Lorem Ipsum',
            time : 'Tuesday, October 24'
        },
        {
            img : require('../../Assets/Images/dashboard/dashImage/user2.png'),
            desc : 'Lorem Ipsum',
            time : 'Tuesday, October 24'
        },
        {
            img : require('../../Assets/Images/dashboard/dashImage/user3.png'),
            desc : 'Lorem Ipsum',
            time : 'Tuesday, October 24'
        },
        {
            img : require('../../Assets/Images/dashboard/dashImage/user4.png'),
            desc : 'Lorem Ipsum',
            time : 'Tuesday, October 24'
        },

    ]
  return (
    <>
         <div className=' h-100'>
    <h4 className='notify__titler m-0 mb-4'>Notifications</h4>
    <div className='notify__holder rounded-3 p-2 h-100'>
            
        {notifications.map((item) => 
        <div className='single__notify d-flex justify-content-start align-items-center gap-3 rounded-3 p-2 mb-3'>
            <img src={item.img} className='img-fluid notify__img'/>

            <div className=''>
                <p className='m-0 notify__title'>{item.desc}</p>
                <small className='m-0 notify__time'>{item.time}</small>
            </div>

        </div>
        )}
        </div>
    </div>
    </>
  )
}

export default Notifications