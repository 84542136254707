import React from "react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

import report from "../../../Assets/Images/report.png";

const ViewReport = (props) => {
  const { show, handleClose } = props;
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="custom_modal_border"
      >
        <Modal.Header className="position-relative">
          <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button>
        </Modal.Header>

        <Modal.Body className="">
          <div className="position-relative">
            {/* <div className="d-flex justify-content-end">
              <button className="gren_btn d-flex align-items-center justify-content-center gap-2 ">
                <img src={download} alt="download" className="img-fluid" />
                Download
              </button>{" "}
            </div> */}
            <img src={report} alt="prescription" className="img-fluid" />{" "}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewReport;
