import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { Link } from "react-router-dom";
// import Prescription from "../modal/Prescription";
import PatientTable from "../table/PatientTable";
import eye from "../../Assets/Images/eye.svg";
import CareListTable from "../table/CareListTable";
import CareListDelete from "../modal/CareListDelete";

function DoctorCareList() {
  const [showCareDelete, setShowCareDelete] = useState(false);

  const [careListHeadData, setCareListHeadData] = useState([
    {
      id: 1,
      name: "ID",
    },
    {
      id: 2,
      name: "Service ID",
    },
    {
      id: 3,
      name: "Name",
    },
    {
      id: 4,
      name: "Doctors",
    },
    {
      id: 5,
      name: "Charges",
    },
    {
      id: 6,
      name: "Duration",
    },
    {
      id: 7,
      name: "Category",
    },
    {
      id: 8,
      name: "Status",
    },
    {
      id: 9,
      name: "Actions",
    },
  ]);
  const [careListBodyData, setCareListBodyData] = useState([
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 2,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 3,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 4,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 5,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 6,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 7,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 8,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
    {
      id: 9,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "General Care",
          status: "Active",
        },
      ],
    },
  ]);

  const handleShowCareDelete = () => {
    setShowCareDelete(true);
  };
  const handleCloseCareDelete = () => {
    setShowCareDelete(false);
  };
  return (
    <>
      {/* start of modal import */}
      <CareListDelete
        show={showCareDelete}
        handleClose={handleCloseCareDelete}
      />

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h5 className="text_black_md">Care List</h5>
              <Link
                to="/doctor-addcare"
                className="d-flex align-items-center justify-content-between gap-2 gren_btn text-decoration-none"
              >
                {" "}
                <FaPlus />
                Add Care
              </Link>
            </div>
            <div className="mt-4">
              {" "}
              <CareListTable
                handleShowCareDelete={handleShowCareDelete}
                careListHeadData={careListHeadData}
                careListBodyData={careListBodyData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorCareList;
