import React, { useState, useEffect } from "react";
import Logo from "../../../Assets/Images/dashboard/logo.png";

import dashboard from "../../../Assets/Images/dashboard/sidebar/dashboard.png";
import records from "../../../Assets/Images/dashboard/sidebar/records.png";
import settings from "../../../Assets/Images/dashboard/sidebar/setting.png";
import helpTask from "../../../Assets/Images/dashboard/sidebar/HelpTask.png";
import friends from "../../../Assets/Images/dashboard/sidebar/friends.png";

import dashboardNew from "../../../Assets/Images/dashboard/sidebar/dashboardNew.png";
import recordsNew from "../../../Assets/Images/dashboard/sidebar/recordsNew.png";
import availability from "../../../Assets/Images/dashboard/sidebar/availability.png";

// import homeactive from "../../Assets/Images/dashboard/home-active.png";
// import homeinactive from "../../Assets/Images/dashboard/home-inactive.png";

// import Affiliateactive from "../../Assets/Images/dashboard/affiliate-active.png";
// import Affiliateinactive from "../../Assets/Images/dashboard/affiliate-inactive.png";

// import Plusactive from "../../Assets/Images/dashboard/plus-active.png";
// import Plusinactive from "../../Assets/Images/dashboard/plus-inactive.png";

import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import closeicon from "../../../Assets/Images/dashboard/close-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

function DashSidebar({ onhide }) {
  const location = useLocation();
  const Location = location.pathname;
  const [panelType, setPanelType] = useState(null);
  const [affiliate, setAffiliate] = useState("");
  const [tradingProfit, setTradingProfit] = useState("");
  const [helpDeak, setHelpDeak] = useState(false);
  const [type, setType] = useState("");
  const handleHelpDesk = () => {
    setHelpDeak(!helpDeak);
  };

  useEffect(() => {
    if (true) {
      setType(localStorage.getItem("type"));
    }
    if (Location == "/raise-grievance" || Location == "/rate-experience") {
      setHelpDeak(true);
    }
  }, [Location]);

  useEffect(() => {
    setPanelType(localStorage.getItem("type"));
  }, [panelType]);

  const getLocalStorage = localStorage.getItem("type");

  console.log("getlocalstorage", getLocalStorage);

  return (
    <div className="sidebar_sec">
      <div className="logo_sec">
        <Link to="/">
          <img src={Logo} alt="logo" className="img-fluid logo" />
        </Link>

        <img
          src={closeicon}
          alt="close Btn"
          className="img-fluid close_offcanvas_logo"
          onClick={onhide}
        />
      </div>

      {type == "patient" ? (
        <>
          {/* Patient Panel */}
          <div className="sideheader">
            <div className="cmn-margin">
              <Link
                to="/dashboard"
                className={
                  Location == "/dashboard" ||
                  Location == "/choose-required" ||
                  Location == "/appointment" ||
                  Location == "/appointment-detail" ||
                  Location == "/new-appointmentdate" ||
                  Location == "/cancellationform" ||
                  Location == "/new-appointment" ||
                  Location == "/choose-doctors" ||
                  Location == "/doctor-detail" ||
                  Location == "/completed-appointment-detail" ||
                  Location == "/new-appointment-booking"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={dashboard} alt="" className="sidebar_logo" />
                  <span>Dashboard</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/records"
                className={
                  Location == "/records" || Location == "/addreport"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={records} alt="" className="sidebar_logo" />
                  <span>Records</span>
                </p>
              </Link>
              {/* <NavLink to="/records">records</NavLink> */}
            </div>
            <div className="cmn-margin">
              <Link
                to="/settings"
                className={
                  Location == "/settings"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={settings} alt="" className="sidebar_logo" />
                  <span>Settings</span>
                </p>
              </Link>
            </div>

            <div className="cmn-margin">
              <div className="">
                <Link
                  // to="/raise-grievance"
                  className={
                    Location == "/raise-grievance" ||
                    Location == "/rate-experience" ||
                    helpDeak == true
                      ? "navi-link cmn-hover active DashSide_customDrop"
                      : "navi-link cmn-hover inactive DashSide_customDrop"
                  }
                  onClick={() => handleHelpDesk()}
                >
                  <p className="mb-0 title-txt">
                    <img src={helpTask} alt="" className="sidebar_logo" />
                    <span>Help Desk</span>
                  </p>

                  <Button
                    className="arrow-btn icon active"
                    // onClick={() => handleHelpDesk()}
                  >
                    {helpDeak ? <IoIosArrowDown /> : <IoIosArrowForward />}
                  </Button>
                </Link>
              </div>
              {helpDeak ? (
                <>
                  <div className="sub-headings mt-2">
                    <Link
                      to="/raise-grievance"
                      className={
                        Location == "/raise-grievance" ? "mb-2 active" : "mb-2"
                      }
                    >
                      Raise a Grievance
                    </Link>
                    <Link
                      to="/rate-experience"
                      className={
                        Location == "/rate-experience" ? "active" : "mb-2"
                      }
                    >
                      Rate Your Experience
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="cmn-margin">
              <Link
                to="/refer-friend"
                className={
                  Location == "/refer-friend"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={friends} alt="" className="sidebar_logo" />
                  <span>Refer a friend</span>
                </p>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Doctor Panel */}
          <div className="sideheader">
            <div className="cmn-margin">
              <Link
                to="/doctor-dashboard"
                className={
                  Location == "/doctor-dashboard" ||
                  Location == "/doctor-appointment" ||
                  Location == "/doctor-care-list" ||
                  Location == "/doctor-addcare" ||
                  Location == "/choose-required" ||
                  Location == "/new-appointment-booking"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={dashboardNew} alt="" className="sidebar_logo" />
                  <span>Dashboard</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/availability"
                className={
                  Location == "/availability" ||
                  Location == "/availability-addcare"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={availability} alt="" className="sidebar_logo" />
                  <span>Availability</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/doctor-records"
                className={
                  Location == "/doctor-records" ||
                  Location == "/doctor-addreport"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={recordsNew} alt="" className="sidebar_logo" />
                  <span>Records</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <Link
                to="/doctor-settings"
                className={
                  Location == "/doctor-settings"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={settings} alt="" className="sidebar_logo" />
                  <span>Settings</span>
                </p>
              </Link>
            </div>
            <div className="cmn-margin">
              <div className="">
                <Link
                  // to="/raise-grievance"
                  className={
                    Location == "/raise-grievance" ||
                    Location == "/rate-experience" ||
                    helpDeak == true
                      ? "navi-link cmn-hover active DashSide_customDrop"
                      : "navi-link cmn-hover inactive DashSide_customDrop"
                  }
                  onClick={() => handleHelpDesk()}
                >
                  <p className="mb-0 title-txt">
                    <img src={helpTask} alt="" className="sidebar_logo" />
                    <span>Help Desk</span>
                  </p>

                  <Button
                    className="arrow-btn icon active"
                    // onClick={() => handleHelpDesk()}
                  >
                    {helpDeak ? <IoIosArrowDown /> : <IoIosArrowForward />}
                  </Button>
                </Link>
              </div>
              {helpDeak ? (
                <>
                  <div className="sub-headings mt-2">
                    <Link
                      to="/raise-grievance"
                      className={
                        Location == "/raise-grievance" ? "mb-2 active" : "mb-2"
                      }
                    >
                      Raise a Grievance
                    </Link>
                    <Link
                      to="/rate-experience"
                      className={
                        Location == "/rate-experience" ? "active" : "mb-2"
                      }
                    >
                      Rate Your Experience
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            {/* <div className="cmn-margin">
              <Link
                to="/refer-friend"
                className={
                  Location == "/refer-friend"
                    ? "navi-link cmn-hover active"
                    : "navi-link cmn-hover inactive"
                }
              >
                <p className="mb-0 title-txt">
                  <img src={friends} alt="" className="sidebar_logo" />
                  <span>Refer a friend</span>
                </p>
              </Link>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
}

export default DashSidebar;
