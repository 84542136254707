import React from "react";
import Table from "react-bootstrap/Table";
import { data } from "../data/data";
import { useNavigate } from "react-router-dom";

import editImage from "../../Assets/Images/edit.png";
import deleteImage from "../../Assets/Images/delete.png";

const PatientTable = (props) => {
  const {
    totalPatientBodyData,
    totalPatientHeadData,
    handleShowDeletePatient,
  } = props;
  const navigation = useNavigate();
  const handleReports = (getData) => {
    navigation("/addreport");
  };
  return (
    <div className="custom_bs_table ">
      <Table responsive>
        <thead>
          <tr>
            {totalPatientHeadData &&
              totalPatientHeadData.length &&
              totalPatientHeadData.map((item, index) => (
                <th key={item.id}>{item.name}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {totalPatientBodyData &&
            totalPatientBodyData.length &&
            totalPatientBodyData.map((tableRow, index) => (
              <tr key={tableRow.id}>
                {tableRow.trValue.map((tableData, index) => (
                  <>
                    <td>{tableData.sno}</td>
                    <td>{tableData.patientname}</td>
                    <td>{tableData.providername}</td>
                    <td>{tableData.date}</td>
                    <td>{tableData.carename}</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-center ">
                        <button
                          className="bg-transparent border-0 outline-0"
                          onClick={() => handleShowDeletePatient()}
                        >
                          <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid eye_img"
                            style={{ width: "18px" }}
                          />
                        </button>
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PatientTable;
