import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useLocation, NavLink } from "react-router-dom";
import Logo from "../../../Assets/Images/black-logo.png";
import bell from "../../../Assets/Images/green-bell.png";
import search from "../../../Assets/Images/green-search.png";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

function Header({ handleFocus }) {
  const [searchView, setSearchView] = useState(false);
  return (
    <div className="header default-header">
      <div className="custom-container container">
        <Navbar expand="lg" className="custom-nav">
          <Container fluid className="px-0">
            <Navbar.Brand href="#" className="me-0">
              <NavLink to="/">
                <img src={Logo} className="img-fluid logo" />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className="nav-collap">
              <Nav className="my-2 my-lg-0 " navbarScroll>
                <div
                  className={
                    searchView ? "headerContents" : "headerContents false"
                  }
                >
                  <div
                    className={
                      searchView ? "header-textList" : "header-textList false"
                    }
                  >
                    <NavLink to="/dashboard">Home</NavLink>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to="meetDoctor"
                      className="pointer"
                    >
                      Meet The Doctor
                    </Link>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to="bestServices"
                      className="pointer"
                    >
                      Our Services
                    </Link>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to="appointments"
                      className="pointer"
                    >
                      Appoinments
                    </Link>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to="shop"
                      className="pointer"
                    >
                      Shop
                    </Link>
                    <Link
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      to="contact"
                      className="pointer"
                    >
                      Contact
                    </Link>
                  </div>
                  {searchView ? (
                    <div className="header-search">
                      <input
                        type="text"
                        className=""
                        placeholder="Search...."
                      />
                      <img src={search} className="search-logo" />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="header-btns">
                    <img src={bell} className="img-fluid bell-icon" />
                    <img
                      src={search}
                      className="img-fluid srch_logo"
                      onClick={() => setSearchView(!searchView)}
                    />

                    <NavLink to="/signup">
                      <Button className="register-btn cmn-greenBtn">
                        Register
                      </Button>
                    </NavLink>
                    <NavLink to="/login">
                      <Button className="login-btn cmn-greenBtn-bdr">
                        Login
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}

export default Header;
