import React, { useState } from "react";
import signupImg from "../../Assets/Images/signup_Img.png";
import logo from "../../Assets/Images/footer-logo.png";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { isEmpty } from "../../Actions/common/common";
import { verify } from "../../Actions/Axios/userAxios";
function OtpVerification() {
  // const  email  = useSelector((state) => state.Patientregister);
  // console.log("payload",email);

  const [Otpnumber, setOtpnumber] = useState('')
  console.log("Otpnumber", Otpnumber);

  const [ValidateError, SetValidateError] = useState('')
  console.log("ValidateErrorw33", ValidateError);

  const navigate = useNavigate()
  // const location = useLocation()
  // console.log("location",location);

const dispatch = useDispatch();
  const onchangeFunction = async (e) => {
    console.log("values", e.target.value)
    const { id, value } = e.target;
    console.log("datasval", id, value);
    setOtpnumber(value)
  }

  const Validation = () => {
    let ValidateError = {}
    console.log("ValidateError",ValidateError);

    if (!Otpnumber) {
      ValidateError.Otpnumber = "OTP is required";
    }


    SetValidateError(ValidateError)

    return ValidateError;


  }
  const email = localStorage.getItem("Email")
  console.log("email",email);

  const handleSubmit = async () => {

    var errors = {};
    let value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    }
    else {

      if (Object.keys(errors).length == 0) {

        console.log("erro length", Object.keys(errors).length)

        let formDatas = {
          "email": email,
          "OTP":Otpnumber
        }
        let resp = await verify(formDatas);
        console.log("responsee",resp);
        if (resp?.status == true) {
          toast.success(resp?.msg)
          dispatch({
            type:"Register_patient",
            Patientregister:{patientRegister :resp?.data}
          })
          // setTimeout(() => {
            navigate("/create-password", { state: { Email: email} })
          // }, 1000);

        }
        else return toast.error(resp?.msg)

      }
    }

  }




  return (
    <div className="cmn-signup otp-verification">
      <di className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_left_img">
              <img src={signupImg} alt="" className="img-fluid sign_up_img" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_input_sec">
              <div className="w-100">
                <div className="logo_sec d-flex align-items-center justify-content-center">
                  <img src={logo} alt="" className="img-fluid logo" />
                </div>
                <div className="input_details mt-4 pt-3">
                  <h3 className="heading_txt mb-0">OTP Verification</h3>
                  <div className="mt-4">
                    <div className="">
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Enter Verification Code
                        </Form.Label>
                        <Form.Control type="text" className="cmn_input_field" id="OTP" value={Otpnumber} onChange={(e) => onchangeFunction(e)}/>
                        {ValidateError && ValidateError.Otpnumber && <span className="error_msg">{ValidateError.Otpnumber}</span>}

                      </div>
                    </div>
                    <div className="cmn_inp_btn mt-4">
                      {/* <Link
                        to="/create-password"
                        className="text-decoration-none"
                      > */}
                        <Button className="cmn-greenBtn" onClick={() => handleSubmit()}>Submit</Button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </di>
    </div>
  );
}

export default OtpVerification;
