import { FaEye } from "react-icons/fa";
import eye from "../../Assets/Images/eye.svg";

export const data = {
  recordsTable: {
    tableHead: [
      {
        id: 1,
        name: "S.No",
      },
      {
        id: 2,
        name: "Appointment Date",
      },
      {
        id: 3,
        name: "Online / Offline",
      },
      {
        id: 4,
        name: "Provider Name",
      },
      {
        id: 5,
        name: "Care Name",
      },
      {
        id: 6,
        name: "Visit Info",
      },
      {
        id: 7,
        name: "Payment Status",
      },
      {
        id: 8,
        name: "Reports",
      },
      {
        id: 9,
        name: "Prescriptions",
      },
    ],
    tableBody: [
      {
        id: 1,
        trValue: [
          {
            id: 1,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 2,
        trValue: [
          {
            id: 2,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 3,
        trValue: [
          {
            id: 3,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 4,
        trValue: [
          {
            id: 4,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 5,
        trValue: [
          {
            id: 5,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 6,
        trValue: [
          {
            id: 6,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 7,
        trValue: [
          {
            id: 7,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 8,
        trValue: [
          {
            id: 8,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 9,
        trValue: [
          {
            id: 9,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
      {
        id: 10,
        trValue: [
          {
            id: 10,
            appointmentDate: "07/04/2024",
            onlineOrOffline: "Online",
            providerName: "Provider Name",
            careName: "Care Name",
            visitInfo: "Completed",
            paymentStatus: "Completed",
            reports: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
            Prescriptions: (
              <img
                src={eye}
                alt="eye"
                className="img-fluid eye_img"
                style={{ width: "18px" }}
              />
            ),
          },
        ],
      },
    ],
  },
};
