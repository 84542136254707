import React from "react";
import Lottie from "lottie-react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

const PatientDeleteModal = (props) => {
  const { show, handleClose } = props;
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button>
        </Modal.Header>

        <Modal.Body className="">
          <div>
            <h6 className="fw_550">Are You Delete this patients?</h6>
            <p className="text_black_xs my-3">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            </p>
            <div className="mt-4 d-flex align-items-center gap-3">
              <button className="pm_button_one">Confirm</button>
              <button className="pm_button_two">Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientDeleteModal;
