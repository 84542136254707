import React from "react";
import Table from "react-bootstrap/Table";
import { data } from "../data/data";
import { Link, useNavigate } from "react-router-dom";

import editImage from "../../Assets/Images/edit.png";
import deleteImage from "../../Assets/Images/delete.png";

const AvailabilityTable = (props) => {
  const { careListHeadData, careListBodyData, handleShowCareDelete } = props;
  const navigation = useNavigate();

  return (
    <div className="custom_bs_table ">
      <Table responsive>
        <thead>
          <tr>
            {careListHeadData &&
              careListHeadData.length &&
              careListHeadData.map((item, index) => (
                <th key={item.id}>{item.name}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {careListBodyData &&
            careListBodyData.length &&
            careListBodyData.map((tableRow, index) => (
              <tr key={tableRow.id}>
                {tableRow.trValue.map((tableData, index) => (
                  <>
                    <td>{tableData.id}</td>
                    <td>{tableData.serviceid}</td>
                    <td>{tableData.name}</td>
                    <td>{tableData.doctor}</td>
                    <td>{tableData.charges}</td>
                    <td>{tableData.duration}</td>
                    <td>{tableData.category}</td>
                    <td>{tableData.status}</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-center gap-3">
                        <Link
                          to="/doctor-addcare"
                          className="bg-transparent border-0 outline-0"
                          // onClick={() => handleReports(tableData)}
                        >
                          <img
                            src={editImage}
                            alt="edit"
                            className="img-fluid eye_img"
                            style={{ width: "18px" }}
                          />
                        </Link>
                        <button
                          className="bg-transparent border-0 outline-0"
                          onClick={handleShowCareDelete}
                        >
                          <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid eye_img"
                            style={{ width: "18px" }}
                          />
                        </button>
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AvailabilityTable;
