import { React, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import DashSidebar from "../Pages/DashboardPages/DashSidebar";
import DashHeader from "../Pages/DashboardPages/DashHeader";

const NewAppointment = () => {
  const morningtime = [
    "09:20",
    "09:40",
    "10:00",
    "10:20",
    "10:40",
    "11:00",
    "11:20",
    "11:40",
    "12:00",
  ];
  const afternoontime = [
    "12:20",
    "12:40",
    "01:00",
    "01:20",
    "01:40",
    "02:00",
    "02:20",
    "02:40",
    "03:00",
  ];
  const eveningtime = [
    "03:20",
    "03:40",
    "04:00",
    "04:20",
    "04:40",
    "05:00",
    "05:20",
    "05:40",
    "06:00",
  ];

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments appointmentsdate">
          <h4 class="h4">New Appointment Booking</h4>
          <div className="formbox">
            <div className="flexbox mb-4">
              <div>
                <p className="formhead">Select Date & Time</p>
              </div>
              <div>
                <Link
                  className="btn btn-details bgdarkgreen me-3"
                  to="/choose-doctors"
                >
                  Next
                </Link>
                <Link className="btn btn-details" to="/new-appointment">
                  Back
                </Link>
                {/* <button type="button" className="btn btn-details bgdarkgreen me-3">Next</button>
                        <button type="button" className="btn btn-details">Back</button> */}
              </div>
            </div>
            <div className="flexbox datebox">
              <div className="flexbox datebox">
                <div className="patientsappointment calendarbox">
                  <Calendar />
                </div>
                <div className="patientsappointment dateboxtwo">
                  <div>
                    <p>Morning</p>
                    <div className="gridbox">
                      <button
                        type="button"
                        className="btn btn-details bgdarkgreen"
                      >
                        09:00
                      </button>
                      {morningtime.map((e) => (
                        <button type="button" className="btn btn-details">
                          {e}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p>Afternoon</p>
                    <div className="gridbox">
                      <button
                        type="button"
                        className="btn btn-details bgdarkgreen"
                      >
                        12:00
                      </button>
                      {afternoontime.map((e) => (
                        <button type="button" className="btn btn-details">
                          {e}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p>Evening</p>
                    <div className="gridbox">
                      <button
                        type="button"
                        className="btn btn-details bgdarkgreen"
                      >
                        03:00
                      </button>
                      {eveningtime.map((e) => (
                        <button type="button" className="btn btn-details">
                          {e}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAppointment;
