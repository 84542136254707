import React, { useState } from "react";

import DashSidebar from "./DashboardPages/DashSidebar";
import DashHeader from "./DashboardPages/DashHeader";
import CustomInput from "../Components/CustomInput";
import ViewReport from "../modal/ViewReport";
import { useNavigate } from "react-router-dom";

import eyeIcon from "../../Assets/Images/eye.svg";

function AddReport() {
  const navigate = useNavigate();
  const [inputValue, setInputValues] = useState({
    laborder: "",
    labreport: "",
    labresults: "",
    medicalrecords: "",
    patientinstructions: "",
    visitsummary: "",
    mipsvisitsummary: "",
    letters: "",
    insurancecard: "",
    summary: "",
  });

  const [viewReport, setViewReport] = useState(false);

  const {
    laborder,
    labreport,
    labresults,
    medicalrecords,
    patientinstructions,
    visitsummary,
    mipsvisitsummary,
    letters,
    insurancecard,
    summary,
  } = inputValue;

  const handleReportInput = (e) => {
    const { value, name } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleViewReportClose = () => {
    setViewReport(false);
  };
  const addReportData = [
    {
      id: 1,
      label: "Lab Order",
      type: "name",
      name: "laborder",
      value: laborder,
      placeholder: "Demo",
      ischooseFile: true,
    },
    {
      id: 2,
      label: "Lab Report",
      type: "file",
      name: "labreport",
      value: labreport,
      placeholder: "No Choose File",
      ischooseFile: true,
    },
    {
      id: 3,
      label: "Lab Order & Results",
      type: "name",
      name: "labresults",
      value: labresults,
      placeholder: "Demo",
      ischooseFile: true,
    },
    {
      id: 4,
      label: "Medical Records",
      type: "name",
      name: "medicalrecords",
      value: medicalrecords,
      placeholder: "Demo",
      ischooseFile: true,
    },
    {
      id: 5,
      label: "Patient Instructions",
      type: "name",
      name: "patientinstructions",
      value: patientinstructions,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 6,
      label: "Visit Summary",
      type: "name",
      name: "visitsummary",
      value: visitsummary,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 7,
      label: "MIPS Visit Summary",
      type: "name",
      name: "mipsvisitsummary",
      value: mipsvisitsummary,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 8,
      label: "Letters",
      type: "name",
      name: "letters",
      value: letters,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 9,
      label: "Insurance Card",
      type: "file",
      name: "insurancecard",
      value: insurancecard,
      placeholder: "Demo",
      ischooseFile: true,
    },
    {
      id: 10,
      label: "Summary",
      type: "name",
      name: "summary",
      value: summary,
      placeholder: "Demo",
      ischooseFile: false,
    },
  ];

  console.log("inputs", inputValue);

  const handleRecords = () => {
    navigate("/records");
  };

  return (
    <>
      {/* start of modal import */}

      <ViewReport show={viewReport} handleClose={handleViewReportClose} />

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />
          <div className="container px-xl-0">
            <div className="mt-4">
              <h5 className="text_green_lg">Add Report</h5>
              <div className="mt-4">
                <div className="row mx-auto">
                  <div className="col-12 col-sm-11 col-md-10 col-lg-11 px-0">
                    <div className="row mx-auto">
                      <div className="col-12 col-lg-6 ps-0 pe-4 pe_xl_2rem ">
                        {/* <div>
                          {addReportData
                            .slice(0, Math.floor(addReportData.length / 2))
                            .map((item, index) => (
                              <div className="d-flex flex-column align-items-start mb-4">
                                <label className="custom_label">
                                  {item.label}
                                </label>
                                <div className="mt-2 w-100">
                                  <CustomInput
                                    setViewReport={setViewReport}
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    type={item.type}
                                    name={item.name}
                                    onChange={handleReportInput}
                                    ischooseFile={item.ischooseFile}
                                  />
                                </div>
                              </div>
                            ))}
                        </div> */}
                        <div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">Lab Order</label>
                            <div className="mt-2 w-100">
                              <input
                                type="text"
                                // value=""
                                placeholder="Demo"
                                // onChange={onChange}
                                // name={name}
                                className="custom_input"
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">Lab Report</label>
                            <div className="mt-2 w-100">
                              <div className="choose_custom_input d-flex align-items-center">
                                <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                  <p className="mb-0 choosen_file_name">
                                    Demo.png
                                  </p>
                                  <button
                                    className="bg-transparent border-0 outline-0"
                                    onClick={() => setViewReport(true)}
                                  >
                                    <img
                                      src={eyeIcon}
                                      alt="eye"
                                      className="eye_icon"
                                      style={{ width: "18px" }}
                                    />
                                  </button>
                                </div>
                                <button className="choose_file_button">
                                  Choose File
                                </button>
                                <input
                                  type="file"
                                  // value=""
                                  placeholder="No Choose File"
                                  // onChange={onChange}
                                  className="d-none"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">
                              Lab Order & Results
                            </label>
                            <div className="mt-2 w-100">
                              <div className="choose_custom_input d-flex align-items-center">
                                <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                  <p className="mb-0 choosen_file_name">
                                    Demo.png
                                  </p>
                                  <button
                                    className="bg-transparent border-0 outline-0"
                                    onClick={() => setViewReport(true)}
                                  >
                                    <img
                                      src={eyeIcon}
                                      alt="eye"
                                      className="eye_icon"
                                      style={{ width: "18px" }}
                                    />
                                  </button>
                                </div>
                                <button className="choose_file_button">
                                  Choose File
                                </button>
                                <input
                                  type="file"
                                  // value=""
                                  placeholder="No Choose File"
                                  // onChange={onChange}
                                  className="d-none"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">
                              Medical Records
                            </label>
                            <div className="mt-2 w-100">
                              <div className="choose_custom_input d-flex align-items-center">
                                <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                  <p className="mb-0 choosen_file_name">
                                    Demo.png
                                  </p>
                                  <button
                                    className="bg-transparent border-0 outline-0"
                                    onClick={() => setViewReport(true)}
                                  >
                                    <img
                                      src={eyeIcon}
                                      alt="eye"
                                      className="eye_icon"
                                      style={{ width: "18px" }}
                                    />
                                  </button>
                                </div>
                                <button className="choose_file_button">
                                  Choose File
                                </button>
                                <input
                                  type="file"
                                  // value=""
                                  placeholder="No Choose File"
                                  // onChange={onChange}
                                  className="d-none"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">
                              Patient Instructions
                            </label>
                            <div className="mt-2 w-100">
                              <input
                                type="text"
                                // value=""
                                placeholder="Demo"
                                // onChange={onChange}
                                // name={name}
                                className="custom_input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 ps-4  pe-0 ps_xl_2rem">
                        {/* <div>
                          {addReportData
                            .slice(
                              Math.floor(addReportData.length / 2),
                              addReportData.length
                            )
                            .map((item, index) => (
                              <div className="d-flex flex-column align-items-start mb-4">
                                <label className="custom_label">
                                  {item.label}
                                </label>
                                <div className="mt-2 w-100">
                                  <CustomInput
                                    setViewReport={setViewReport}
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    type={item.type}
                                    name={item.name}
                                    onChange={handleReportInput}
                                    ischooseFile={item.ischooseFile}
                                  />
                                </div>
                              </div>
                            ))}
                        </div> */}

                        <div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">
                              Visit Summary
                            </label>
                            <div className="mt-2 w-100">
                              <input
                                type="text"
                                // value=""
                                placeholder="Demo"
                                // onChange={onChange}
                                // name={name}
                                className="custom_input"
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">
                              MIPS Visit Summary
                            </label>
                            <div className="mt-2 w-100">
                              <input
                                type="text"
                                // value=""
                                placeholder="Demo"
                                // onChange={onChange}
                                // name={name}
                                className="custom_input"
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">Letters</label>
                            <div className="mt-2 w-100">
                              <input
                                type="text"
                                // value=""
                                placeholder="Demo"
                                // onChange={onChange}
                                // name={name}
                                className="custom_input"
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">
                              Insurance Card
                            </label>
                            <div className="mt-2 w-100">
                              <div className="choose_custom_input d-flex align-items-center">
                                <div className="choose_file d-flex align-items-center justify-content-between pe-3">
                                  <p className="mb-0 choosen_file_name">
                                    Demo.png
                                  </p>
                                  <button
                                    className="bg-transparent border-0 outline-0"
                                    onClick={() => setViewReport(true)}
                                  >
                                    <img
                                      src={eyeIcon}
                                      alt="eye"
                                      className="eye_icon"
                                      style={{ width: "18px" }}
                                    />
                                  </button>
                                </div>
                                <button className="choose_file_button">
                                  Choose File
                                </button>
                                <input
                                  type="file"
                                  // value=""
                                  placeholder="No Choose File"
                                  // onChange={onChange}
                                  className="d-none"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start mb-4">
                            <label className="custom_label">Summary</label>
                            <div className="mt-2 w-100">
                              <input
                                type="text"
                                // value=""
                                placeholder="Demo"
                                // onChange={onChange}
                                // name={name}
                                className="custom_input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start w-100">
                      <label>Others</label>
                      <textarea
                        name=""
                        onChange={handleReportInput}
                        className="custom_textarea mt-2"
                        placeholder="Demo"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3 mt_2rem">
                  <button className="pm_button_two" onClick={handleRecords}>
                    Cancel
                  </button>
                  <button className="pm_button_one" onClick={handleRecords}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddReport;
