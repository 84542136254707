import React, { useState } from "react";
import { toast } from "react-toastify";
// import { useHistory } from "react-router-dom";
import signupImg from "../../Assets/Images/signup_Img.png";
import logo from "../../Assets/Images/footer-logo.png";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "../../Actions/common/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "../../Assets/Images/calender.png";

import { RegisterPatient } from "../../Actions/Axios/userAxios";
function SignUp() {
  const [formdata, setFormdata] = useState({});
  const [fromDate, setFromDate] = useState(new Date());
  const [ValidateError, SetValidateError] = useState("");
  const navigate = useNavigate();
  // const navigate = u
  console.log("formdata", formdata);
  const dispatch = useDispatch();
  const onchangeFunction = async (e) => {
    console.log("values", e.target.value);
    const { id, value } = e.target;
    console.log("datasval", id, value);
    setFormdata({ ...formdata, [id]: value });
  };

  const Validation = () => {
    let ValidateError = {};

    if (!formdata.firstname) {
      ValidateError.firstname = "firstname is required";
    }
    if (!formdata.lastname) {
      console.log("fdsgdfgdfgdfgdfg");
      ValidateError.lastname = "lastname is required";
    }
    if (!formdata?.address) {
      ValidateError.address = "address is required";
    }
    if (!formdata.zipcode) {
      ValidateError.zipcode = "zipcode is required";
    }
    if (!formdata.gender) {
      ValidateError.gender = "gender is required";
    }
    if (!formdata.Dob) {
      ValidateError.Dob = "Dob is required";
    }
    if (!formdata.email) {
      ValidateError.email = "email is required";
    }
    if (!formdata.mobile) {
      ValidateError.mobile = "mobile is required";
    }

    SetValidateError(ValidateError);

    return ValidateError;
  };

  const handleSubmit = async () => {
    var errors = {};
    let value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    } else {
      if (Object.keys(errors).length == 0) {
        console.log("erro length", Object.keys(errors).length, formdata);

        let formDatas = {
          FirstName: formdata.firstname,
          LastName: formdata?.lastname,
          Address: formdata?.address,
          ZIPCode: formdata?.zipcode,
          Gender: formdata?.gender,
          DOB: formdata?.Dob,
          Country: formdata?.country,
          email: formdata?.email,
          Mobileno: formdata?.mobile,
        };
        let resp = await RegisterPatient(formDatas);
        console.log("responsee", resp);
        if (resp?.status == true) {
          toast.success(resp?.msg);
          dispatch({
            type: "Register_patient",
            Patientregister: { patientRegister: resp?.data },
          });
          localStorage.setItem("Email", resp?.data?.email);
          // setTimeout(() => {
          navigate("/otp-verification");
          // }, 1000);
        } else return toast.error(resp?.msg);
      }
    }
  };
  return (
    <div className="cmn-signup">
      <di className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_left_img">
              <img src={signupImg} alt="" className="img-fluid sign_up_img" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_input_sec">
              <div className="w-100">
                <div className="logo_sec d-flex align-items-center justify-content-center">
                  <img src={logo} alt="" className="img-fluid logo" />
                </div>
                <div className="input_details mt-3">
                  <h3 className="heading_txt mb-0">Sign up</h3>
                  <div className="mt-4">
                    <div className="cmn_inp_scroll pe-3">
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label ps-0">
                          First Name
                        </Form.Label>
                        {/* <input type="text" id="firstname" onChange={(e) => onchangeFunction(e)} /> */}
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="firstname"
                          value={formdata?.firstname}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.firstname && (
                          <span className="error_msg">
                            {ValidateError.firstname}
                          </span>
                        )}
                      </div>

                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label ps-0">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="lastname"
                          value={formdata?.lastname}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.lastname && (
                          <span className="error_msg">
                            {ValidateError.lastname}
                          </span>
                        )}
                      </div>

                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Country
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="country"
                          value={formdata?.country}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.country && (
                          <span className="error_msg">
                            {ValidateError.country}
                          </span>
                        )}
                      </div>

                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Zip Code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="zipcode"
                          value={formdata?.zipcode}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.zipcode && (
                          <span className="error_msg">
                            {ValidateError.zipcode}
                          </span>
                        )}
                      </div>

                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Sex Assigned At Birth
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="gender"
                          value={formdata?.gender}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.gender && (
                          <span className="error_msg">
                            {ValidateError.gender}
                          </span>
                        )}
                      </div>

                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="address"
                          value={formdata?.address}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.address && (
                          <span className="error_msg">
                            {ValidateError.address}
                          </span>
                        )}
                      </div>

                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Date Of Birth
                        </Form.Label>
                        {/* <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="Dob"
                          value={formdata?.Dob}
                          onChange={(e) => onchangeFunction(e)}
                        /> */}
                        <div className="customDatePicker">
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            icon={
                              <img
                                src={calendar}
                                className="img-fluid calendar_logo"
                              />
                            }
                            showIcon
                          />
                        </div>

                        {ValidateError && ValidateError.Dob && (
                          <span className="error_msg">{ValidateError.Dob}</span>
                        )}
                      </div>
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          className="cmn_input_field"
                          id="email"
                          value={formdata?.email}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.email && (
                          <span className="error_msg">
                            {ValidateError.email}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Mobile Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="cmn_input_field"
                          id="mobile"
                          value={formdata?.mobile}
                          onChange={(e) => onchangeFunction(e)}
                        />
                        {ValidateError && ValidateError.mobile && (
                          <span className="error_msg">
                            {ValidateError.mobile}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="cmn_inp_btn mt-4">
                      {/* <Link to="/otp-verification" className="text-decoration-none"> */}
                      <Button
                        className="cmn-greenBtn"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </Button>
                      {/* </Link> */}
                    </div>
                    <div className="already_have mt-4 text-center">
                      <span className="">
                        Already have a account?
                        <Link to="/login" className="ms-2">
                          Signin
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </di>
    </div>
  );
}

export default SignUp;
