import React, { useState } from "react";
import { Button } from "react-bootstrap";
import care from "../../../Assets/Images/dashboard/care.png";
import pressure from "../../../Assets/Images/dashboard/Blood_Pressure.png";
import Calendar from "react-calendar";
import men from "../../../Assets/Images/dashboard/men.png";
import women from "../../../Assets/Images/dashboard/women.png";
import fitness from "../../../Assets/Images/dashboard/fitness.png";
import immunity from "../../../Assets/Images/dashboard/disease.png";
import sleeping from "../../../Assets/Images/dashboard/sleeping.png";
import { Col, Row } from "react-bootstrap";
import clarity from "../../../Assets/Images/dashboard/eye.png";
import food from "../../../Assets/Images/dashboard/food.png";
import routines from "../../../Assets/Images/dashboard/routines.png";
import herbal from "../../../Assets/Images/dashboard/herbal.png";
import { FaCheck } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import DoctorsList from "../DoctorsList";
import check from "../../../Assets/Images/dashboard/check.png";
import doctor from "../../../Assets/Images/dashboard/doctor-two.png";
import doctorthree from "../../../Assets/Images/dashboard/doctor-three.png";
import doctorbanner from "../../../Assets/Images/dashboard/doctor-banner.png";
import { Link, useNavigate } from "react-router-dom";
import BookingCompleted from "../BookingCompleted";
import SuccessfulModal from "../Modals/SuccessfulModal";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import successful from "../../../Assets/Images/dashboard/successful.json";

function NewAppointBooking() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const [show, setShow] = useState(false);

  const handlePaynow = () => setShow(true);
  const handlePaynowClose = () => {
    setStep(step + 1);
    setShow(false);
    navigate("/dashboard");
  };

  const [date, setDate] = useState([
    "09:00",
    "09:00",
    "09:00",
    "09:00",
    "09:00",
    "09:00",
    "09:00",
    "09:00",
    "09:00",
  ]);

  const CHRONIC = [
    {
      img: care,
      name: "Diabetes",
      active: true,
    },
    {
      img: care,
      name: "Blood Pressure",
      active: false,
    },
  ];

  const PHYSICAL = [
    {
      img: men,
      name: "Men's Health",
    },
    {
      img: women,
      name: "Women's Health",
    },
    {
      img: fitness,
      name: "Fitness",
    },
    {
      img: immunity,
      name: "Immunity Wellness",
    },
    {
      img: sleeping,
      name: "Difficulty sleeping",
    },
    {
      img: sleeping,
      name: "Difficulty sleeping",
    },
  ];

  const WAYOFLIFE = [
    {
      img: clarity,
      name: "Clarity",
    },
    {
      img: food,
      name: "Food",
    },
    {
      img: routines,
      name: "Routines",
    },
    {
      img: herbal,
      name: "Herbal",
    },
  ];

  const cmnTime = [
    {
      time: "09:00",
      active: true,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
  ];

  const doctorsList = [
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
  ];

  const treatment = [
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
  ];

  const handleStep = (val) => {
    setStep(val);
  };

  const handleStepBack = (val) => {
    if (step == 0) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };

  console.log("step val", step);
  return (
    <div className="new_appointment_booking">
      <h4 className="cmnDash_heading_txt">New Appointment Booking</h4>

      <div className="row mt-4">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4 mb-lg-0">
          {/* <BookingCompleted /> */}
          <div className="booking_completed">
            <div
              className={
                step == 1 || step == 2 || step == 3 || step == 4 || step == 5
                  ? "cmn-sec active"
                  : "cmn-sec"
              }
            >
              <div className="lft_sec">
                <span className="center-dot"></span>
              </div>
              <div className="ryt_sec">
                <p className="heading_txt mb-2">Doctor Services</p>
                <p className="sub_heading_txt mb-0">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
            <div
              className={
                step == 2 || step == 3 || step == 4 || step == 5
                  ? "cmn-sec active"
                  : "cmn-sec"
              }
            >
              <div className="lft_sec">
                <span className="center-dot"></span>
              </div>
              <div className="ryt_sec">
                <p className="heading_txt mb-2">Select Date & Time</p>
                <p className="sub_heading_txt mb-0">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>

            <div
              className={
                step == 3 || step == 4 || step == 5
                  ? "cmn-sec active"
                  : "cmn-sec"
              }
            >
              <div className="lft_sec">
                <span className="center-dot"></span>
              </div>
              <div className="ryt_sec">
                <p className="heading_txt mb-2">Fills Consultation Form</p>
                <p className="sub_heading_txt mb-0">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>

            <div
              className={step == 4 || step == 5 ? "cmn-sec active" : "cmn-sec"}
            >
              <div className="lft_sec">
                <span className="center-dot"></span>
              </div>
              <div className="ryt_sec">
                <p className="heading_txt mb-2">Choose the doctors</p>
                <p className="sub_heading_txt mb-0">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>

            <div className={step == 5 ? "cmn-sec active" : "cmn-sec"}>
              <div className="lft_sec">
                <span className="center-dot"></span>
              </div>
              <div className="ryt_sec">
                <p className="heading_txt mb-2">Book the appointment</p>
                <p className="sub_heading_txt mb-0">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          <div className="ryt-sec">
            {step == 0 ? (
              <>
                {/* Select Care */}
                <>
                  <div className="cmn_dash_header_sec">
                    <div className="mbl d-flex align-items-center justify-content-between">
                      <p className="mb-0 title-txt">Select Care</p>
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          className="next-btn cmn-greenBtn"
                          onClick={() => handleStep(1)}
                        >
                          Next
                        </Button>
                        <Button
                          className="back-btn cmn-grayBtn"
                          onClick={() => handleStepBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4">
                      <h5 className="mb-0 fw-600 dark-gray">CHRONIC</h5>
                      <div className="select-type mt-4">
                        {CHRONIC.map((item, index) => {
                          return (
                            <>
                              <div
                                className={
                                  item.active
                                    ? "cmn_smlCard text-center active"
                                    : "cmn_smlCard text-center"
                                }
                              >
                                <img
                                  src={item.img}
                                  alt=""
                                  className="img-fluid cmn-img"
                                />
                                <p className="type mb-0 mt-3">{item.name}</p>
                                {item.active ? (
                                  <span className="checked">
                                    <FaCheck size={12} />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    <div className="mt-4">
                      <h5 className="mb-0 fw-600 dark-gray">
                        PHYSICAL & MENTAL
                      </h5>
                      <div className="select-type mt-4">
                        {PHYSICAL.map((item, index) => {
                          return (
                            <>
                              <div
                                className={
                                  item.active
                                    ? "cmn_smlCard text-center active"
                                    : "cmn_smlCard text-center"
                                }
                              >
                                <img
                                  src={item.img}
                                  alt=""
                                  className="img-fluid cmn-img"
                                />
                                <p className="type mb-0 mt-3">{item.name}</p>
                                {item.active ? (
                                  <span className="checked">
                                    <FaCheck size={12} />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    <div className="mt-4">
                      <h5 className="mb-0 fw-600 dark-gray">WAY OF LIFE</h5>
                      <div className="select-type mt-4">
                        {WAYOFLIFE.map((item, index) => {
                          return (
                            <>
                              <div
                                className={
                                  item.active
                                    ? "cmn_smlCard text-center active"
                                    : "cmn_smlCard text-center"
                                }
                              >
                                <img
                                  src={item.img}
                                  alt=""
                                  className="img-fluid cmn-img"
                                />
                                <p className="type mb-0 mt-3">{item.name}</p>
                                {item.active ? (
                                  <span className="checked">
                                    <FaCheck size={12} />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              </>
            ) : step == 1 ? (
              <>
                {/* Select Date & Time */}
                <div className="">
                  <div className="cmn_dash_header_sec">
                    <div className="mbl d-flex align-items-center justify-content-between">
                      <p className="mb-0 title-txt">Select Date & Time</p>
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          className="next-btn cmn-greenBtn"
                          onClick={() => handleStep(2)}
                        >
                          Next
                        </Button>
                        <Button
                          className="back-btn cmn-grayBtn"
                          onClick={() => handleStepBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="row">
                      <div className="mb-3 col-12 col-sm-12 col-xl-12 col-xxl-6">
                        <div className="appointment_customCalendar">
                          <Calendar />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-xl-12 col-xxl-6">
                        <div className="patientsappointment dateboxtwo custom_TimeDisplay">
                          <div>
                            <p className="heading_txt mb-0">Morning</p>
                            <div className="gridbox mt-2">
                              {cmnTime.map((item, index) => (
                                <button
                                  type="button"
                                  className={
                                    item.active
                                      ? "active btn-green"
                                      : "btn-green"
                                  }
                                >
                                  {item.time}
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="mt-3">
                            <p className="heading_txt mb-0">Afternoon</p>
                            <div className="gridbox mt-2">
                              {cmnTime.map((item, index) => (
                                <button
                                  type="button"
                                  className={
                                    item.active
                                      ? "active btn-green"
                                      : "btn-green"
                                  }
                                >
                                  {item.time}
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="mt-3">
                            <p className="heading_txt mb-0">Evening</p>
                            <div className="gridbox mt-2">
                              {cmnTime.map((item, index) => (
                                <button
                                  type="button"
                                  className={
                                    item.active
                                      ? "active btn-green"
                                      : "btn-green"
                                  }
                                >
                                  {item.time}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : step == 2 ? (
              <>
                {/* Patient Form */}
                <div className="">
                  <div className="cmn_dash_header_sec">
                    <div className="mbl d-flex align-items-center justify-content-between">
                      <p className="mb-0 title-txt">
                        Patient Fills Consultation Form :
                      </p>
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          className="next-btn cmn-greenBtn"
                          onClick={() => handleStep(3)}
                        >
                          Next
                        </Button>
                        <Button
                          className="back-btn cmn-grayBtn"
                          onClick={() => handleStepBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="patientForm mt-4">
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Reason for consultation{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control as="textarea"></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Symptoms <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <Form.Control as="textarea"></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          History of similar symptoms/ illness{" "}
                          <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <Form.Control as="textarea"></Form.Control>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </>
            ) : step == 3 ? (
              <>
                {/* Choose Doctor */}
                <div className="choose-doctors">
                  <div className="cmn_dash_header_sec">
                    <div className="mbl d-flex align-items-center justify-content-between">
                      <p className="mb-0 title-txt">Choose the doctors :</p>
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          className="back-btn cmn-grayBtn"
                          onClick={() => handleStepBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 row">
                    {doctorsList.map((item, index) => (
                      <div
                        className="doc_CusCard col-12 col-sm-6 col-xl-6 col-xxl-6 mb-3"
                        onClick={() => handleStep(4)}
                      >
                        <DoctorsList doctorsList={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Doctor Profile */}
                <div className="doctors-profile">
                  <div className="cmn_dash_header_sec">
                    <div className="mbl d-flex align-items-center justify-content-between">
                      <p className="mb-0 title-txt">
                        Dr.AKULE VIVIAN Details :
                      </p>
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          className="back-btn cmn-grayBtn"
                          onClick={() => handleStepBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 doctorProfiledetails">
                    <div className="">
                      <img
                        src={doctorbanner}
                        alt="Detail Image"
                        className="bannerImg"
                      />
                      <div className="profileDetail">
                        <div className="row">
                          <div className="col-xl-5 mb-4 mb-lg-0">
                            <img
                              src={doctor}
                              alt="Detail Image"
                              className="doctorimg"
                            />
                            <p className="doctorname mb-0 mt-2">
                              Dr.Akule Vivian
                            </p>
                            <span className="doctorsubname">
                              Heart Specalist
                            </span>
                            <p className="content mt-1">
                              Lorem ipsum dolor sit, amet consectetur
                              adipisicing elit. Aut, corporis libero ratione
                              dignissimos quia a repellat omnis et beatae non
                              consectetur provident? Id omnis repellendus,
                              numquam modi quasi tempore nulla.
                            </p>
                          </div>
                          <div className="col-xl-7">
                            <div className="treatments">
                              <span className="treatmenthead">Treatments</span>
                              <ul className="treatmentslist mt-3">
                                {treatment.map((t) => (
                                  <li>
                                    <img
                                      src={check}
                                      alt="Check"
                                      className="checked-img"
                                    />
                                    <span>{t}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="btmContent mt-4">
                          <Form.Check
                            type="checkbox"
                            label="I accept Payment Terms & Conditions"
                            className="cusCheckbox"
                          ></Form.Check>
                          <div>
                            <Button
                              className="btn cmn-greenBtn paynow-btn me-3"
                              onClick={handlePaynow}
                            >
                              Paynow
                            </Button>
                            <Button className="btn cmn-darkBinkBtn paylater-btn">
                              Paylater
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Booking Successfull Modal */}
      <div className="appoinment_booking_modal">
        <Modal
          show={show}
          className="modalbox appoinment_booking_modal"
          centered
        >
          <Modal.Body>
            <div className="successfulimg">
              <Lottie animationData={successful} loop={true}></Lottie>
            </div>
            <p className="content booking">Booking Successful !</p>
            <p className="content bookquote">Have a great day ahead.</p>

            {/* <Link className="btn bgdarkgreen" to="/"> */}
            <Button
              className="cmn-greenBtn home-btn"
              onClick={handlePaynowClose}
            >
              Home
            </Button>
            {/* </Link> */}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default NewAppointBooking;
