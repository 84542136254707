import React from 'react';
import DashSidebar from "../Pages/DashboardPages/DashSidebar";
import DashHeader from "../Pages/DashboardPages/DashHeader";
import Doctors from './Doctors';

const ChooseDoctors = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments choosedoctors">
          <h4 class="h4">New Appointment Booking</h4>
          <div className="formbox">
              <p className="formhead">Choose the doctors :</p>
              <Doctors />
          </div>
        </div>
      </div>
    </div>
   
  )
}

export default ChooseDoctors;