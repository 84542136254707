var EnvName = "local";

var key = {};
key.KEY = "CardBo@rD1290%6Fine3";
key.ONEDAYINSECONDS = 0;
key.BLOCKS_PER_YEAR = 0;
key.RPAD_ADDRESS = "";
key.ROUTER = "";
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
key.MOBILE = /^\d{10}$/;
key.NumOnly = /^\d+$/;
key.PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
key.OnlyAlbhabets = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
key.notSpecil = /^[a-zA-Z0-9]+$/;
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/;

key.limit = 50;
key.NumDigitOnly = /[^0-9\.]/g;
key.NumberOnly = /[^0-9]/g;

if (EnvName === "local") {
  key.Patient = "http://localhost:3000";
  key.Provider = "http://localhost:3000";
  key.FRONT_URL = "http://localhost:3000";
  key.BACK_URL = "http://localhost:5000";
  key.IMG_URL = "http://localhost:5000";

  // key.ADMIN_URL       =   'http://localhost:2000/'
} else if (EnvName === "demo") {

} else if (EnvName === "demossl") {

} else if (EnvName === "production") {

}
key.Keys = process.env.Decryptkey;
key.register = "XyZ";
key.login = "XYZ";

export default key;
