import React from "react";
import { IoMdClose } from "react-icons/io";

import signupImg from "../../Assets/Images/signup_Img.png";
import logo from "../../Assets/Images/footer-logo.png";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import uploadImage from "../../Assets/Images/upload.svg";
import prescriptionImage from "../../Assets/Images/prescription.svg";
function EducationDetails() {
  return (
    <div className="cmn-signup otp-verification">
      <di className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_left_img">
              <img src={signupImg} alt="" className="img-fluid sign_up_img" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            {/* <div className="cmn_input_sec">
              <div>
                <div className="logo_sec d-flex align-items-center justify-content-center">
                  <img src={logo} alt="" className="img-fluid logo" />
                </div>
                <div className="input_details mt-4 pt-3">
                  <h3 className="heading_txt mb-0">Education Details</h3>
                  <div className="mt-4">
                    <div>
                      <button className="uplaod_container d-flex flex-column  align-items-center justify-content-center mt-5 mb-4 w-100">
                        <img
                          src={uploadImage}
                          alt="upload"
                          className="img-fluid"
                          style={{ width: "20px" }}
                        />
                        <p className="mt-2">
                          Drag & Drop or{" "}
                          <span className="link_span">Choose</span> file here
                        </p>
                      </button>

                      <div>
                        <div className="upload_prescription mb-3">
                          <div className="row">
                            <div className="col-10 ps-0">
                              <div>
                                <div className="d-flex align-items-center gap-3">
                                  <img
                                    src={prescriptionImage}
                                    alt="prescription"
                                    className="img-fluid"
                                  />
                                  <div>
                                    <p className="fw_450 mb-1">
                                      Doctor prescriptions
                                    </p>
                                    <p className="mb-0 text_black_xxs">
                                      Doctor prescriptions
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                              <button className="border-0 outline-0 bg-transparent">
                                <IoMdClose fontSize={20} />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="upload_prescription">
                          <div className="row mx-auto">
                            <div className="col-10 ps-0">
                              <div>
                                <div className="d-flex align-items-center gap-3">
                                  <img
                                    src={prescriptionImage}
                                    alt="prescription"
                                    className="img-fluid"
                                  />
                                  <div>
                                    <p className="fw_450 mb-1">
                                      Doctor prescriptions
                                    </p>
                                    <p className="mb-0 text_black_xxs">
                                      Doctor prescriptions
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                              <button className="border-0 outline-0 bg-transparent">
                                <IoMdClose fontSize={20} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cmn_inp_btn mt-4">
                      <Link
                        to="/create-password"
                        className="text-decoration-none"
                      >
                        <Button className="cmn-greenBtn">Submit</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="cmn_input_sec ">
              <div className="w-100">
                <div className="logo_sec d-flex align-items-center justify-content-center">
                  <img src={logo} alt="" className="img-fluid logo" />
                </div>
                <div className="input_details mt-4 pt-3">
                  <h3 className="heading_txt mb-0">Education Details</h3>
                  <div className="mt-4 edu_qual">
                    <div>
                      <button className="uplaod_container d-flex flex-column  align-items-center justify-content-center mt-5 mb-4 w-100">
                        <img
                          src={uploadImage}
                          alt="upload"
                          className="img-fluid"
                          style={{ width: "20px" }}
                        />
                        <p className="mt-2">
                          Drag & Drop or{" "}
                          <span className="link_span">Choose</span> file here
                        </p>
                      </button>

                      <div>
                        <div className="upload_prescription mb-3">
                          <div className="row">
                            <div className="col-10 ps-0">
                              <div>
                                <div className="d-flex align-items-center gap-3">
                                  <img
                                    src={prescriptionImage}
                                    alt="prescription"
                                    className="img-fluid"
                                  />
                                  <div>
                                    <p className="fw_450 mb-1">
                                      Doctor prescriptions
                                    </p>
                                    <p className="mb-0 text_black_xxs">
                                      Doctor prescriptions
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                              <button className="border-0 outline-0 bg-transparent">
                                <IoMdClose fontSize={20} />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="upload_prescription">
                          <div className="row mx-auto">
                            <div className="col-10 ps-0">
                              <div>
                                <div className="d-flex align-items-center gap-3">
                                  <img
                                    src={prescriptionImage}
                                    alt="prescription"
                                    className="img-fluid"
                                  />
                                  <div>
                                    <p className="fw_450 mb-1">
                                      Doctor prescriptions
                                    </p>
                                    <p className="mb-0 text_black_xxs">
                                      Doctor prescriptions
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                              <button className="border-0 outline-0 bg-transparent">
                                <IoMdClose fontSize={20} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cmn_inp_btn mt-4">
                      <Link
                        to="/create-password"
                        className="text-decoration-none"
                      >
                        <Button className="cmn-greenBtn">Submit</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </di>
    </div>
  );
}

export default EducationDetails;
