import { React, useState } from "react";
// import Nav from 'react-bootstrap/Nav';
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppointmentsPatients from "./AppointmentsPatients";
import DashSidebar from "../Pages/DashboardPages/DashSidebar";
import DashHeader from "../Pages/DashboardPages/DashHeader";
import CompletedAppointDetails from "./CompletedAppointmentDetails";
import UpcomingAppointments from "./UpcomingAppointments";
import RescheduleAppointment from "./RescheduleAppointments";
import CancelledAppointments from "./CancelledAppointments";
import CompletedAppointments from "./CompletedAppointments";

const Appointments = () => {
  const [cancel, setCancel] = useState("Canceled");
  const [complete, setComplete] = useState("Completed");

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments appointmentsbox">
          <div className="flexbox">
            <div>
              <h4 className="h4">Appointments</h4>
            </div>
            <div>
              <Link className="back" to="/">
                Back
              </Link>
            </div>
          </div>
          {/* <Nav variant="tabs">
                <Nav.Item>
                    <Nav.Link href="/" className="active">Upcoming</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/">Reschedule</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/">Canceled</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/">Completed</Nav.Link>
                </Nav.Item>
            </Nav> */}
          <Tabs className="mb-3">
            <Tab eventKey="Upcoming" title="Upcoming">
              {/* <AppointmentsPatients /> */}
              <UpcomingAppointments />
            </Tab>
            <Tab eventKey="Reschedule" title="Reschedule">
              {/* <AppointmentsPatients /> */}
              <RescheduleAppointment />
            </Tab>
            <Tab eventKey="Canceled" title="Canceled">
              {/* <AppointmentsPatients cancel={true} /> */}
              <CancelledAppointments />
            </Tab>
            <Tab eventKey="Completed" title="Completed">
              {/* <AppointmentsPatients complete={true} /> */}
              <CompletedAppointments />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Appointments;
