import React from "react";
import DashSidebar from "./DashSidebar";
import DashHeader from "./DashHeader";
import DashHome from "../../Components/DashboardComponent/DashHome";
import DashCalendar from "../../Components/DashboardComponent/DashCalendar";

function ChooseRequiredPage() {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-sm-4 dashboard-content">
          <DashHome />
        </div>
      </div>
    </div>
  );
}

export default ChooseRequiredPage;
