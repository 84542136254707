import React from "react";
import { Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import NewAppoint from "../../Assets/Images/dashboard/dashImage/lotties/newAppoint.json";
import { NavLink } from "react-router-dom";
import DocCalendarCont from "./DocCalendarCont";
import DocNotifications from "./DocNotifications";
import DashDoctorAppointments from "../../UserPanel/Components/DashDoctorAppointments";

const DoctorDashCalendar = () => {
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} className="mb-4">
          <NavLink
            to="/total-patients"
            className="dash__singleBox dash__newSkyBox d-flex flex-column justify-content-between overflow-hidden rounded-4 text-decoration-none"
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../Assets/Images/dashboard/dashImage/patient.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">
                  Total Patients
                </p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../Assets/Images/dashboard/dashImage/skyGraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </NavLink>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <NavLink
            to="/doctor-appointment"
            className="dash__singleBox dash__greenBox d-flex flex-column justify-content-between overflow-hidden rounded-4 text-decoration-none"
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../Assets/Images/dashboard/dashImage/calendar.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">
                  Total Appointment
                </p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../Assets/Images/dashboard/dashImage/greenGraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </NavLink>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <NavLink
            to="/doctor-care-list"
            className="dash__singleBox dash__orangeBox d-flex flex-column justify-content-between overflow-hidden rounded-4 text-decoration-none"
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../Assets/Images/dashboard/dashImage/care.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">Total Care</p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../Assets/Images/dashboard/dashImage/orangeGraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </NavLink>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <NavLink
            to="/doctor-appointment"
            className="dash__singleBox dash__violetBox d-flex flex-column justify-content-between overflow-hidden rounded-4 text-decoration-none"
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../Assets/Images/dashboard/dashImage/wallet.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">
                  Reschedule Appointment
                </p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../Assets/Images/dashboard/dashImage/vioGraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </NavLink>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <NavLink
            to="/doctor-new-appointment"
            className="text-decoration-none"
          >
            <div className="dash__lottieBox d-flex flex-column justify-content-between overflow-hidden rounded-4">
              <div className="d-flex justify-content-between align-items-center  px-3 pt-1">
                <p className="m-0 dash__singleBoxWhiteLabel">
                  New Appointment Booking
                </p>
                <div className="dash__lottieHolder">
                  <Lottie animationData={NewAppoint} loop={true}></Lottie>
                </div>
              </div>
              <img
                src={
                  require("../../Assets/Images/dashboard/dashImage/lottieGraph.svg")
                    .default
                }
                className="img-fluid  dash__singleBoxGraph"
              />
            </div>
          </NavLink>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={8} className="mb-4 mb-xxl-0">
          <DocCalendarCont />
        </Col>
        <Col lg={4}>
          <DocNotifications />
        </Col>
      </Row>

      <div className="dash__docAppointsHolder mt-5">
        <DashDoctorAppointments />
      </div>
    </>
  );
};

export default DoctorDashCalendar;
