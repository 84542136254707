import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import Tip from "../../../Assets/Images/dashboard/dashImage/lotties/tips.json";
import ViewReport from "../modals/ViewReport";
import PaymentMethod from "../modals/PaymentMethod";
import WaitingTime from "../modals/WaitingTime";
import Timeout from "../modals/Timeout";
import PaymentSuccessModal from "../modals/PaymentSuccessfullModal";
import RescheduleAppointmentModal from "../modals/RescheduleAppointment";
import BookingSuccessfullModal from "../modals/BookingSuccessfullModal";
import ConfirmCancelAppointment from "../modals/ConfirmCancelAppointment";
import AppointmentCancelModal from "../modals/AppointmentCancelModal";

const PatientDetails = () => {
  const [showReport, setShowReport] = useState(false);
  const [showWaitingTime, setShowWaitingTime] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showTimeout, setShowTimeout] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showBookingSuccess, setShowBookingSuccess] = useState(false);
  const [showConfirmCancelAppointment, setShowConfirmCancelAppointment] =
    useState(false);
  const [showAppoinmentCancel, setShowAppointmentCancel] = useState(false);

  const handleShowViewReport = () => {
    setShowReport(true);
  };
  const handleCloseViewReport = () => {
    setShowReport(false);
  };

  const handleWaitMoreTime = () => {
    setShowWaitingTime(false);
    const timer = setTimeout(() => {
      setShowTimeout(true);
    }, 10000);
    return () => clearTimeout(timer);
  };
  const handleTimeoutCancel = () => {
    setShowTimeout(false);
    setShowConfirmCancelAppointment(true);
  };
  const handleTimeoutReschedule = () => {
    setShowTimeout(false);
    setShowReschedule(true);
  };
  const handleClosePaymentSuccess = () => {
    setShowPaymentSuccess(false);
  };
  const handleShowRescheduleAppointment = () => {
    setShowWaitingTime(false);
    setShowReschedule(true);
  };
  // const handleCloseRescheduleAppointment = () => {
  //   setShowReschedule(false);
  //   setShowBookingSuccess(true);
  // };
  const handleCloseRescheduleAppointment = () => {
    setShowReschedule(false);
  };
  const handleNextRescheduleAppointment = () => {
    setShowReschedule(false);
    setShowBookingSuccess(true);
  };

  const handleCloseBookingSuccess = () => {
    setShowBookingSuccess(false);
  };

  const handleCloseConfirmCancel = () => {
    setShowConfirmCancelAppointment(false);
    setShowAppointmentCancel(true);
  };

  const handleCloseCancelAppointment = () => {
    setShowAppointmentCancel(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWaitingTime(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* start of modal import */}

      <ViewReport show={showReport} handleClose={handleCloseViewReport} />

      <WaitingTime
        show={showWaitingTime}
        handleWaitMoreTime={handleWaitMoreTime}
        handleShowRescheduleAppointment={handleShowRescheduleAppointment}
      />
      <Timeout
        show={showTimeout}
        handleTimeoutReschedule={handleTimeoutReschedule}
        handleTimeoutCancel={handleTimeoutCancel}
      />
      <PaymentSuccessModal
        show={showPaymentSuccess}
        handleClose={handleClosePaymentSuccess}
      />
      <RescheduleAppointmentModal
        show={showReschedule}
        handleNext={handleNextRescheduleAppointment}
        handleClose={handleCloseRescheduleAppointment}
      />

      <BookingSuccessfullModal
        show={showBookingSuccess}
        handleClose={handleCloseBookingSuccess}
      />

      <ConfirmCancelAppointment
        show={showConfirmCancelAppointment}
        handleClose={handleCloseConfirmCancel}
      />

      <AppointmentCancelModal
        show={showAppoinmentCancel}
        handleClose={handleCloseCancelAppointment}
      />

      {/* end of modal import */}
      <Container fluid className="p-2 patientDetailsWhole">
        <div className="pd__wholeShadowBox rounded-1 p-2">
          <div className="pd__wholeShadowBox rounded-2 p-2 pb-5">
            <Row>
              <Col
                lg={2}
                md={3}
                sm={5}
                xs={12}
                className="d-flex justify-content-center mb-3 mb-sm-0"
              >
                <img
                  src={require("../../../Assets/Images/patientnew.png")}
                  className="img-fluid pd__patientImg rounded-3"
                />
              </Col>
              <Col lg={10} md={9} sm={7}>
                <div className="pd__patientTop pb-2">
                  <h5 className="m-0 pd__patientName text-center text-sm-start">
                    Diane Cooper
                  </h5>
                  <p className="m-0 mt-2 pd__patientBadge text-center text-sm-start">
                    Patient
                  </p>
                </div>
                <Row className="mt-3 justify-content-between">
                  <Col lg={6} md={12} className="mb-4">
                    <Row className="justify-content-between">
                      <Col lg={5} md={6} className="mb-4 mb-md-0">
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Date of Birth :
                          </label>
                          <p className="m-0 pd__labelValue">29-08-1992</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Email Id :
                          </label>
                          <p className="m-0 pd__labelValue">diane@gmail.com</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Country :
                          </label>
                          <p className="m-0 pd__labelValue">Canada</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Zip code :
                          </label>
                          <p className="m-0 pd__labelValue">102314601</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Gender :
                          </label>
                          <p className="m-0 pd__labelValue">Female</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Mobile No :
                          </label>
                          <p className="m-0 pd__labelValue">98745613230</p>
                        </div>
                      </Col>
                      <Col lg={5} md={6}>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Visit Type :
                          </label>
                          <p className="m-0 pd__labelValue">General</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Height :
                          </label>
                          <p className="m-0 pd__labelValue">160 cm</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Weight :
                          </label>
                          <p className="m-0 pd__labelValue">60 kg</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            medications :
                          </label>
                          <p className="m-0 pd__labelValue">Lorem ipsum</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Allergies :
                          </label>
                          <p className="m-0 pd__labelValue">Lorem ipsum</p>
                        </div>
                        <div className="pd__singleFlexd d-flex justify-content-between align-items-start mb-2">
                          <label className="m-0 p-0 pd__grayLabel">
                            Symtoms :
                          </label>
                          <p className="m-0 pd__labelValue">Lorem ipsum</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} md={12}>
                    <label className="m-0 p-0 pd__grayLabel">
                      Reason for consultation :
                    </label>
                    <p className="m-0 pd__topDesc">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row className="mt-4 h-100">
            <Col lg={3} md={4} sm={5}>
              <div className="pd__wholeShadowBox p-4 rounded-2">
                <h6 className="m-0 pd__appointTitle mb-3">
                  Appointment Details
                </h6>

                <div className="d-flex justify-content-start align-items-center gap-3 mb-3">
                  <img
                    src={require("../../../Assets/Images/idverify.svg").default}
                    className="img-fluid pd__appointImg"
                  />
                  <div>
                    <p className="m-0 pd__apointId pd__appointValue">ID</p>
                    <p className="m-0 pd__appointValue">102145304</p>
                  </div>
                </div>

                <div className="d-flex justify-content-start align-items-center gap-3 mb-3">
                  <img
                    src={require("../../../Assets/Images/redcal.svg").default}
                    className="img-fluid pd__appointImg"
                  />
                  <div>
                    <p className="m-0 pd__appointValue pd__apointDate">Date</p>
                    <p className="m-0 pd__appointValue">Fri, 27 Apr, 2024</p>
                  </div>
                </div>

                <div className="d-flex justify-content-start align-items-center gap-3 mb-3">
                  <img
                    src={
                      require("../../../Assets/Images/timerclock.svg").default
                    }
                    className="img-fluid pd__appointImg"
                  />
                  <div>
                    <p className="m-0 pd__apointTime pd__appointValue">Time</p>
                    <p className="m-0 pd__appointValue">03 :30 PM</p>
                  </div>
                </div>

                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img
                    src={
                      require("../../../Assets/Images/videocall.svg").default
                    }
                    className="img-fluid pd__appointImg"
                  />
                  <div>
                    <p className="m-0 pd__apointVisit pd__appointValue">
                      Visit Type
                    </p>
                    <p className="m-0 pd__appointValue">Video Call</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={9} md={8} sm={7}>
              <div className="pd__wholeShadowBox p-4 rounded-2 h-100">
                <h6 className="m-0 pd__appointTitle mb-3">Uploaded Form</h6>

                <Row className="mt-3">
                  <Col lg={2} md={4} sm={6} xs={6}>
                    <button
                      className="bg-transparent border-0 outline-0"
                      onClick={handleShowViewReport}
                    >
                      <img
                        src={require("../../../Assets/Images/dashboard/document.png")}
                        className="img-fluid"
                      />
                      <p className="m-0 mt-2 pd__documentTitle">
                        Test report.pdf
                      </p>
                    </button>
                  </Col>

                  <Col lg={2} md={4} sm={6} xs={6}>
                    <button
                      className="bg-transparent border-0 outline-0"
                      onClick={handleShowViewReport}
                    >
                      <img
                        src={require("../../../Assets/Images/dashboard/document.png")}
                        className="img-fluid"
                      />
                      <p className="m-0 mt-2 pd__documentTitle">
                        Test report.pdf
                      </p>
                    </button>
                  </Col>

                  <Col lg={2} md={4} sm={6} xs={6}>
                    <button
                      className="bg-transparent border-0 outline-0"
                      onClick={handleShowViewReport}
                    >
                      <img
                        src={require("../../../Assets/Images/dashboard/document.png")}
                        className="img-fluid"
                      />
                      <p className="m-0 mt-2 pd__documentTitle">
                        Test report.pdf
                      </p>
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <button className="pd__waitingBtn rounded-2 w-100 p-2 mt-4 d-flex justify-content-center align-items-center">
            <p className="pd__waitingBtnTxt m-0">
              Please wait the patients will be shortly
            </p>
            <Lottie animationData={Tip} className="pd__tipJson" loop={true} />
          </button>
        </div>
      </Container>
    </>
  );
};

export default PatientDetails;
