import { React, useState } from "react";
import doctor from "../../Assets/Images/dashboard/doctor-two.png";
import doctorthree from "../../Assets/Images/dashboard/doctor-three.png";
import care from "../../Assets/Images/dashboard/care.png";
import { Link } from "react-router-dom";
import DoctorDetails from "./DoctorDetails";
import { Button } from "react-bootstrap";

const DoctorsList = ({ doctorsList }) => {
  return (
    <div className="doctors_list">
      <div className="customCard">
        <div className="cus_flexbox">
          <div className="position-relative">
            <img src={doctorsList.docimg} alt="Doctor" className="doctorimg" />
            <img
              src={doctorsList.careimg}
              alt="Doctor"
              className="doctoricon"
            />
          </div>
          <div>
            <p className="doctorname">{doctorsList.doctorname}</p>
            <span className="doctorsubname">{doctorsList.doctorsubname}</span>
            <div className="btn-grp">
              <Button className="cmn-grayBtn1 btn btn-details me-2">Details</Button>
              <Button className="btn btn-details cmn-greenBtn">Accepts</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorsList;
