import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";

import { Form, Button } from "react-bootstrap";

const RescheduleAppointmentModal = (props) => {
  const { show, handleClose, handleNext } = props;

  const cmnTime = [
    {
      time: "09:00",
      active: true,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
    {
      time: "09:00",
      active: false,
    },
  ];

  return (
    <div className="bg-danger">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        centered
        className="custom_modal_pads reschedule_Appointment"
      >
        <Modal.Header className="position-relative d-none">
          {/* <button
            className="circle_cls_button position-absolute"
            onClick={handleCloseWaiting}
          >
            <IoClose fontSize={23} />
          </button> */}
        </Modal.Header>

        <Modal.Body className="">
          <div className="content_sec">
            <div className="pre_header">Reschedule Appointment</div>
            <div className="p-3">
              <div className="cmn_dash_header_sec">
                <div className="mbl d-flex align-items-center justify-content-between">
                  <p className="mb-0 title-txt">Select Date & Time</p>
                  <div className="d-flex gap-3 align-items-center">
                    <Button
                      className="next-btn cmn-greenBtn"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                    <Button
                      className="back-btn cmn-grayBtn"
                      onClick={handleClose}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="mt-4">
                  <div className="row">
                    <div className="mb-3 mb-lg-0 col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="appointment_customCalendar">
                        <Calendar />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="patientsappointment dateboxtwo custom_TimeDisplay">
                        <div>
                          <p className="heading_txt mb-0">Morning</p>
                          <div className="gridbox mt-2">
                            {cmnTime.map((item, index) => (
                              <button
                                type="button"
                                className={
                                  item.active ? "active btn-green" : "btn-green"
                                }
                              >
                                {item.time}
                              </button>
                            ))}
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="heading_txt mb-0">Afternoon</p>
                          <div className="gridbox mt-2">
                            {cmnTime.map((item, index) => (
                              <button
                                type="button"
                                className={
                                  item.active ? "active btn-green" : "btn-green"
                                }
                              >
                                {item.time}
                              </button>
                            ))}
                          </div>
                        </div>
                        <div className="mt-3">
                          <p className="heading_txt mb-0">Evening</p>
                          <div className="gridbox mt-2">
                            {cmnTime.map((item, index) => (
                              <button
                                type="button"
                                className={
                                  item.active ? "active btn-green" : "btn-green"
                                }
                              >
                                {item.time}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RescheduleAppointmentModal;
