import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";

import { IoClose } from "react-icons/io5";
import { IoVideocamOutline } from "react-icons/io5";

import calenderGreen from "../../../Assets/Images/calendergreen.svg";
import timeGreen from "../../../Assets/Images/timegreen.svg";
import profile from "../../../Assets/Images/patientprofile.png";
import logo from "../../../Assets/Images/black-logo.png";
import waitingJson from "../../../Assets/Json/waiting.json";
import { Form } from "react-bootstrap";
import PaymentMethod from "./PaymentMethod";
import { useNavigate } from "react-router-dom";
import ViewReport from "./ViewReport";

const DoctorAppointmentMeeting = () => {
  const navigate = useNavigate();
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(true);
  const [checker, setChecker] = useState(false);

  const handleAppointment = () => {
    setShowAppointmentDetails(false);
    navigate("/appointment-patient-details");
  };

  return (
    <>
      <div>
        <Modal
          show={showAppointmentDetails}
          onHide={handleAppointment}
          backdrop="static"
          size="md"
          centered
          className="custom_modal_pads"
        >
          <Modal.Header className="position-relative d-none">
            {/* <button
            className="circle_cls_button position-absolute"
            onClick={handleCloseWaiting}
          >
            <IoClose fontSize={23} />
          </button> */}
          </Modal.Header>

          <Modal.Body className="">
            <div className="position-relative">
              <div className="top_border_green"></div>
              <div className="mdl_appoint_dtls">
                <p className="mdl_text_xs">Your Appointment</p>
                <div className="d-flex flex-wrap gap-3 align-items-center justify-content-between mb-2 ">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={calenderGreen}
                      alt="calender"
                      className="img-fluid"
                      style={{ width: "16px" }}
                    />
                    <p className="mb-0 mdl_text_bold_sm">
                      Friday, April 27<sup>th</sup> 2024
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={timeGreen}
                      alt="time"
                      className="img-fluid"
                      style={{ width: "16px" }}
                    />
                    <p className="mb-0 mdl_text_bold_sm">03:30 - 04:30 PM</p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap gap-4 align-items-center justify-content-between mdl_pfl_section">
                <div className="d-flex align-items-center gap-3">
                  <div className="mdl_profile_wraper">
                    <img
                      src={profile}
                      alt="profile"
                      className="img_container_fit"
                    />
                  </div>
                  <div>
                    <p className="mb-0 mdl_text_bold_md">Diane Cooper</p>
                    <p className="mb-0 gray_text_sm mt-1">Patient</p>
                  </div>
                </div>
                <div>
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid"
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center ">
                <Lottie
                  animationData={waitingJson}
                  className="mdl_waiting_json"
                  loop={true}
                />
              </div>
              <div className="d-flex align-items-start gap-2 mdl_terms_cntn">
                {/* <input type="checkbox" className="custom_chckbox" /> */}
                <Form.Check
                  type="checkbox"
                  label=""
                  className="d-flex align-items-center mdl_checkbox"
                  checked={checker}
                  onChange={() => setChecker(!checker)}
                ></Form.Check>
                <p className="mb-0 mdl_gray_text_sm">
                  I accept Notice of PrivacyPractices, Terms of Service and
                  Notice of Non-discrimination And Accessibility Requirements
                </p>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  className="d-flex align-items-center gap-2 mdl_green_btn mb-5 mt-3"
                  disabled={!checker}
                  onClick={handleAppointment}
                >
                  <IoVideocamOutline
                    fontSize={18}
                    style={{ marginBottom: "2px" }}
                  />
                  Go to Video Consulatation
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default DoctorAppointmentMeeting;
