import React, { useState } from "react";
import DashSidebar from "./DashboardPages/DashSidebar";
import DashHeader from "./DashboardPages/DashHeader";
import RejectReasonModal from "../modal/RejectReasonModal";
import AppointmentCancelModal from "../modal/AppointmentCancelModal";
import { Link } from "react-router-dom";

function CancellationForm() {
  const [showAppointmentCancel, setShowAppointmentCancel] = useState(false);

  const handleShowAppointmentCancel = () => {
    setShowAppointmentCancel(true);
  };
  const handleCloseAppointmentCancel = () => {
    setShowAppointmentCancel(false);
  };
  return (
    <>
      {/* start of modal import */}

      <AppointmentCancelModal
        show={showAppointmentCancel}
        handleClose={handleCloseAppointmentCancel}
      />
      {/* <RejectReasonModal
        show={showAppointmentCancel}
        handleClose={handleCloseAppointmentCancel}
      /> */}

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4">
            <h5 className="text_black_lg">Cancellation Form</h5>
            <div className="row">
              <div className="col-xl-9">
                <div className="primary_card mt_2rem">
                  <div className="heads_action d-flex flex-wrap align-items-start gap-3 justify-content-between">
                    <p className="mb-0 fw_450">Patient Cancellation Form :</p>
                    <div className="d-flex flex-wrap align-items-center gap-4">
                      <button
                        className="pm_button_one"
                        onClick={handleShowAppointmentCancel}
                      >
                        Next
                      </button>
                      <Link
                        to="/appointment-detail"
                        className="pm_button_two text-decoration-none"
                      >
                        Back
                      </Link>
                    </div>
                  </div>

                  <div className="mt_2rem d-flex flex-column">
                    <label className="text_black_sm">
                      Cancel Reason <span className="requried_icon">*</span>
                    </label>
                    <textarea className="mt-3 prm_textarea"></textarea>
                  </div>
                  <div className="mt_2rem d-flex flex-column">
                    <label className="text_black_sm">
                      Comments <span className="requried_icon">*</span>
                    </label>
                    <textarea className="mt-3 prm_textarea_md"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancellationForm;
