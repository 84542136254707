import React, { useState } from "react";
import { Button } from "react-bootstrap";
import care from "../../../Assets/Images/dashboard/care.png";
import pressure from "../../../Assets/Images/dashboard/Blood_Pressure.png";
import Calendar from "react-calendar";
import men from "../../../Assets/Images/dashboard/men.png";
import women from "../../../Assets/Images/dashboard/women.png";
import fitness from "../../../Assets/Images/dashboard/fitness.png";
import immunity from "../../../Assets/Images/dashboard/disease.png";
import sleeping from "../../../Assets/Images/dashboard/sleeping.png";
import { Col, Row } from "react-bootstrap";
import clarity from "../../../Assets/Images/dashboard/eye.png";
import food from "../../../Assets/Images/dashboard/food.png";
import routines from "../../../Assets/Images/dashboard/routines.png";
import herbal from "../../../Assets/Images/dashboard/herbal.png";
import { FaCheck } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import DoctorsList from "../DoctorsList";
import check from "../../../Assets/Images/dashboard/check.png";
import doctor from "../../../Assets/Images/dashboard/doctor-two.png";
import doctorthree from "../../../Assets/Images/dashboard/doctor-three.png";
import doctorbanner from "../../../Assets/Images/dashboard/doctor-banner.png";
import { Link } from "react-router-dom";
import BookingCompleted from "../BookingCompleted";
import { FaStar } from "react-icons/fa";
function RateExperience() {
  return (
    <div className="rate_experience">
      <div className="header_sec">
        <h4 className="cmnDash_heading_txt">Rating</h4>
        <p className="des_txt mb-0">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea
        </p>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-8">
          <div className="cmn-steps">
            <div className="count">
              <span className="">1</span>
            </div>
            <div className="details">
              <p className="heading_txt mb-3">Rate Your Experience</p>
              <p className="des mb-0">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore
              </p>
              <div className="all-ratings mt-3">
                <FaStar className="star-fill" />
                <FaStar className="star-fill" />
                <FaStar className="star-fill" />
                <FaStar className="star-fill" />
                <FaStar className="star-unfill" />
              </div>
            </div>
          </div>
          <div className="cmn-steps mt-4">
            <div className="count">
              <span className="">2</span>
            </div>
            <div className="details w-100">
              <p className="heading_txt mb-3">Add a comment</p>
              <Form.Group className="">
                <Form.Control as="textarea"></Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="ftr_BtnSec mt-4 mt-lg-5 d-flex gap-3 align-items-center">
            <Button className="cancelBtn cmn-grayBtn1">Cancel</Button>
            <Button className="submitBtn cmn-greenBtn">Submit</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RateExperience;
