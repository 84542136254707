import { React, useState } from "react";
// import Nav from 'react-bootstrap/Nav';
import { Tab, Tabs } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import AppointmentsPatients from './AppointmentsPatients';
import AppointmentsDoctors from "../DoctorPanel/AppointmentsDoctors";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import UpcomingAppointmentDetails from "./components/UpcomingAppointmentDetails";
import UpcomingAppointments from "./components/UpcomingAppointments";
import RescheduleAppointments from "./components/RescheduleAppointments";
import CompletedAppointments from "./components/CompletedAppointments";

const DoctorAppointment = () => {
  const [upcoming, setupcoming] = useState("upcoming");

  const [activeTabs, setActiveTabs] = useState(0);

  const doctorAppointmentData = [
    {
      id: 1,
      title: "Upcoming",
      linkTo: "/doctor-appointment",
      component: <UpcomingAppointments />,
    },
    {
      id: 2,
      title: "Reschedule",
      linkTo: "/doctor-reschedule-appointments",
      component: <RescheduleAppointments />,
    },
    {
      id: 3,
      title: "Completed",
      linkTo: "/doctor-completed-appointments",
      component: <CompletedAppointments />,
    },
  ];

  const handleTabsChange = (getIndex) => {
    setActiveTabs(getIndex);
  };

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="doctorappointment">
          <div className="appointments appointmentsbox">
            <div className="flexbox">
              <div>
                <h4 className="h4">Appointments</h4>
              </div>
              <div>
                <Link className="back" to="/doctor-dashboard">
                  Back
                </Link>
              </div>
            </div>
            {/* <Tabs className="mb-3">
              <Tab eventKey="Upcoming" title="Upcoming">
                <AppointmentsDoctors upcoming={upcoming} />
              </Tab>
              <Tab eventKey="Reschedule" title="Reschedule">
                <AppointmentsDoctors />
              </Tab>
              <Tab eventKey="Completed" title="Completed">
                <AppointmentsDoctors />
              </Tab>
            </Tabs> */}
            <div className="mb-3 mt-5">
              <div className="custom_tabs_wrap mb-5">
                {doctorAppointmentData.map((item, index) => (
                  <button
                    className={`custom_tabs ${
                      index === activeTabs ? "active" : ""
                    } `}
                    key={index}
                    onClick={() => handleTabsChange(index)}
                  >
                    {item.title}
                  </button>
                ))}
              </div>

              <div>{doctorAppointmentData[activeTabs].component}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorAppointment;
