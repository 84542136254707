import { combineReducers } from "redux";
import { Register_Patient, Initial_Register, LogOut,Patient_Grievance } from "./constants";

const Initial_State = {
  patientRegister:{},
  PatientGrievance:{}
};

function LoginReducer(state = Initial_State, action) {
  console.log("Register_Section", action,action.patientRegister,action.type);
  switch (action.type) {
    case Register_Patient:
      return {
        ...state,
        ...action.patientRegister,
      };
    case Patient_Grievance:
      return {
        ...state,
        ...action.PatientGrievance,
      };
    case LogOut:
      return Initial_State;
    default:
      return Initial_State;
  }
}

const Telehealth = combineReducers({ LoginReducer: LoginReducer });

export default Telehealth;
