import React from "react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

import doctorIcon from "../../../Assets/Images/doctorIcon.png";
import { useNavigate } from "react-router-dom";

const WaitingTime = (props) => {
  const { show, handleWaitMoreTime, handleShowRescheduleAppointment } = props;

  return (
    <div>
      <Modal
        show={show}
        onHide={handleWaitMoreTime}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          {/* <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button> */}
        </Modal.Header>

        <Modal.Body className="">
          <div className="d-flex flex-column align-items-center">
            <img
              src={doctorIcon}
              alt="calender"
              className="img-fluid"
              style={{ width: "40px" }}
            />
            <p className="mdl_title_sm mt-3">Waiting for provider</p>
            <p className="text_black_xs text-center">
              Your provider are scheduled in another appointment. You will wait
              for another 10 mins ( or ) Rescheduled now
            </p>
            <div className="d-flex flex-column  align-items-center justify-content-center gap-2 mt-2 w_80">
              <button
                className="mdl_blue_btn_sm mt-3 w-100"
                onClick={handleWaitMoreTime}
              >
                Wait for Another 10 mins
              </button>
              <button
                className="mdl_gray_btn_sm mt-2 w-100"
                onClick={handleShowRescheduleAppointment}
              >
                Reschedule Appointment
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WaitingTime;
