import React from "react";
import Lottie from "lottie-react";
import Modal from "react-bootstrap/Modal";

import cancelJson from "../../Assets/Json/cancel.json";
import { useNavigate } from "react-router-dom";
// import cancelJson from "../../Assets/json/cancel.json";

const AppointmentCancelModal = (props) => {
  const navigate = useNavigate();
  const { show, handleClose } = props;

  const handleAppointmentCancel = () => {
    handleClose();
    navigate("/new-appointmentdate");
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-center">
              <Lottie
                animationData={cancelJson}
                className="th_json"
                loop={true}
              />
            </div>
            <h6 className="text-center mt-4 fw_550">
              Your Appointment was cancelled
            </h6>
            <p className="text-center gray_text_sm my-2">Lorem Ipsum</p>
            <div className="d-flex align-items-center gap-4 justify-content-center mt-5">
              <button className="pm_button_two" onClick={handleClose}>
                Later
              </button>
              <button
                className="pm_button_one"
                onClick={handleAppointmentCancel}
              >
                Reschedule
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AppointmentCancelModal;
