import React from "react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

import creditCard from "../../../Assets/Images/creditcard.png";
import { useNavigate } from "react-router-dom";

const PaymentMethod = (props) => {
  const { show, handlePayNow, handlePayLater } = props;

  const navigate = useNavigate();

  return (
    <div>
      <Modal
        show={show}
        onHide={handlePayLater}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          {/* <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button> */}
        </Modal.Header>

        <Modal.Body className="">
          <div className="d-flex flex-column align-items-center">
            <img
              src={creditCard}
              alt="calender"
              className="img-fluid"
              style={{ width: "40px" }}
            />
            <p className="mdl_title_sm mt-3">Payment Method</p>
            <p className="text_black_xs text-center">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et
            </p>
            <div className="d-flex align-items-center justify-content-center gap-3">
              <button className="mdl_green_btn_sm mt-3" onClick={handlePayNow}>
                Paynow
              </button>
              <button className="mdl_red_btn_sm mt-3" onClick={handlePayLater}>
                Paylater
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentMethod;
