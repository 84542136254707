import React from "react";
import Logo from "../../../Assets/Images/footer-logo.png";
import arrow from "../../../Assets/Images/green-right-arrow.png";
import { NavLink } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
function Footer() {
  return (
    <div className="footer" name='contact'>
      <div className="container">
        <div className="footer-content">
          <div className="text-center">
            <img src={Logo} className="img-fluid footer-logo" />
            <div className="heading-sec mt-4">
              <NavLink className="heading-txt" to="/">
                Home
              </NavLink>
              <Link spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="meetDoctor" className="pointer footer__linkAlign">Meet The Doctor</Link>
                    <Link spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="bestServices" className="pointer footer__linkAlign">Our Services</Link>
                    <Link spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="appointments" className="pointer footer__linkAlign">Appoinments</Link>
                    <Link spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="shop" className="pointer footer__linkAlign">Shop</Link>
                    <Link spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        to="contact" className="pointer footer__linkAlign">Contact</Link>
            </div>
            <div className="mt-3 d-flex align-items-center justify-content-center">
              <span className="meet_doctor">
                Meet The Doctor
                <GoArrowRight size={20} />
              </span>
            </div>
          </div>
        </div>
        <hr className="footer-hr my-0" />
        <div className="post-footer my-3 text-center">
          ©2024 by twintreeobgyn & integrated health
        </div>
      </div>
    </div>
  );
}

export default Footer;
