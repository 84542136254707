export const isEmpty = (value) =>
  value === undefined ||
  value == "undefined" ||
  value === null ||
  value == false ||
  value == "false" ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0) ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0);

  export const getlogintype = async (data)=>{
    console.log("datas",data);
    try {
      let type = localStorage.getItem("type")
      console.log("types",type);
      return type
    } catch (error) {
      console.log("error",error);
    }
  
  }