import { React, useState } from "react";
// import serviceLeaf from "../../Assets/Images/landing/service-leaf.png";
import patient from "../../Assets/Images/dashboard/patient.png";
import patienttwo from "../../Assets/Images/dashboard/patientgirl.png";
import { IoMailOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { RxCountdownTimer } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";
import { PiCalendarCheck } from "react-icons/pi";
import { PiCalendarXBold } from "react-icons/pi";
import { Link } from "react-router-dom/dist";
import RescheduleAppointmentDetails from "./RescheduleAppointmentDetails";
import CompletedAppointmentDetails from "./CompletedAppointmentDetails";
import tablecalender from "../../Assets/Images/tablecalender.svg";

const CompletedAppointments = (props) => {
  const [contents, setContents] = useState([
    {
      img: patient,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
      paymentType: "Paid",
    },
    {
      img: patienttwo,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
      paymentType: "Paid",
    },
    {
      img: patient,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
      paymentType: "Paid",
    },
    {
      img: patient,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
      paymentType: "Paid",
    },
    {
      img: patienttwo,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
      paymentType: "Paid",
    },
  ]);
  const [showCompletedAppointmentDetails, setShowCompletedAppointmentDetails] =
    useState(false);
  const [selectedCompletedAppointment, setSelectedCompletedAppointment] =
    useState([]);
  const handleCompletedAppointmentDetails = (getValue) => {
    setSelectedCompletedAppointment(getValue);
    setShowCompletedAppointmentDetails(true);
  };

  return (
    <>
      {showCompletedAppointmentDetails ? (
        <CompletedAppointmentDetails
          selectedCompletedAppointment={selectedCompletedAppointment}
        />
      ) : (
        <>
          {contents.map((e) => (
            <div className="patientsappointment">
              <div className="patientsbox">
                <div>
                  <div className="flex fleximg">
                    <div>
                      <img src={e.img} alt="Image" className="patientimg" />
                    </div>
                    <div>
                      <p>{e.name}</p>
                      <small className="t-darkgreen">{e.subname}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                    <IoMailOutline /> <span>{e.email}</span>
                  </p>
                  <p>
                    <FiPhone /> <span>{e.phone}</span>
                  </p>
                </div>
                <div>
                  <div className="flex flextime">
                    <div>
                      <p>
                        <RxCountdownTimer />
                      </p>
                    </div>
                    <div>
                      <p>
                        <span>{e.datetime}</span>
                        <div className="flex">
                          <span className="text-green">{e.gv}</span>
                          <span>|</span>
                          <span className="text-blue">{e.vc}</span>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flextime">
                    <div>
                      <img
                        src={tablecalender}
                        alt="calender"
                        className="img-fluid"
                        style={{ width: "17px" }}
                      />
                    </div>
                    <div>
                      <p>
                        <span>Payment Type</span>
                        <div className="flex">
                          <span
                            className={
                              e.paymentType === "Paid"
                                ? "text-green"
                                : "text_red_xs"
                            }
                          >
                            {e.paymentType}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    onClick={() => handleCompletedAppointmentDetails(e)}
                    className="btn btn-details"
                  >
                    View details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CompletedAppointments;
