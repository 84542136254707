import React from "react";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import Land from "../Components/Land";


function Landing() {
  return (
    <div className="landing-page">
      <Header />
      <Land />
      <Footer />
    </div>
  );
}

export default Landing;
