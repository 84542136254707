import React from "react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

import logo from "../../Assets/Images/black-logo.png";
import sign from "../../Assets/Images/sign.png";
import download from "../../Assets/Images/download.png";
import pres from "../../Assets/Images/pres.png";

const Prescription = (props) => {
  const { show, handleClose } = props;
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="custom_modal_border"
      >
        <Modal.Header className="position-relative">
          <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button>
        </Modal.Header>

        <Modal.Body className="">
          {/* <div className="modal_border">
            <div className="mdl_body_title position-relative">
              <div className="d-flex justify-content-end mb-3 mb-sm-0">
                <button className="gren_btn d-flex align-items-center justify-content-center gap-2">
                  <img src={download} alt="download" className="img-fluid" />
                  Download
                </button>
              </div>

              <div className="d-flex flex-column align-items-center">
                <img src={logo} alt="logo" className="img-fluid mdl_logo " />
                <p className=" my-2">Prescription</p>
              </div>
            </div>
            <div className="mdl_body_content mt-3 ">
              <p className="text_black_xs_bold mb-2">Doctor's Statement</p>
              <p className="text_black_xxs mb-2">Dear Sir / Madam</p>
              <p className="text_black_xxs">
                Please find below a Prescription for
              </p>
              <p className="text_black_xxs mt-3 mb-2">Name : VENKY</p>
              <p className="text_black_xxs mb-2">
                Address : Ganapathy Nagar,Madurai, Tamil Nadu, 630015
              </p>
              <p className="text_black_xxs mb-3">DOB : 06-10-2024</p>
            </div>
            <div className="mdl_body_footer mt-3">
              <p className="text_black_xs_bold mb-2">Rx :</p>
              <div className="text_wt_border">
                <p className="text_black_xxs mb-0">Steve</p>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-sm-6 d-flex align-items-center">
                  <div>
                    <p className="text_black_xxs mb-0">Doctor's Signature</p>
                    <img src={sign} alt="sign" className="sign_img mt-2" />
                  </div>
                </div>
                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                  <div>
                    <p className="text_black_xxs mb-0">MANI</p>
                    <p className="text_black_xxs mb-0">Cardiology</p>
                    <p className="text_black_xxs mb-0">BDS</p>
                    <p className="text_black_xxs mb-0">Phone : +9183943992</p>
                    <p className="text_black_xxs mb-0">Email:mani@gmail.com</p>
                    <p className="text_black_xxs mb-0">
                      1962,Ganapathy nagar, Madurai
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="position-relative">
            <div className="d-flex justify-content-end">
              <button className="gren_btn d-flex align-items-center justify-content-center gap-2 ">
                <img src={download} alt="download" className="img-fluid" />
                Download
              </button>{" "}
            </div>
            <img src={pres} alt="prescription" className="img-fluid" />{" "}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Prescription;
