import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { Link } from "react-router-dom";
// import Prescription from "../modal/Prescription";
import PatientTable from "../table/PatientTable";
import PatientDeleteModal from "../modal/PatientDeleteModal";
import AppointmentAccepted from "../modal/AppointmentAccepted";
import CancelAppointment from "../modal/CancelAppointment";

function TotalPatients() {
  const [showDeletePatient, setShowDeletePatient] = useState(false);

  const handleShowDeletePatient = () => {
    setShowDeletePatient(true);
  };
  const handleCloseDeletePatient = () => {
    setShowDeletePatient(false);
  };

  const [totalPatientHeadData, setTotalPatientHeadData] = useState([
    {
      id: 1,
      name: "S.No",
    },
    {
      id: 2,
      name: "Patient Name",
    },
    {
      id: 3,
      name: "Provider Name",
    },
    {
      id: 4,
      name: "Date",
    },
    {
      id: 5,
      name: "Care Name",
    },
    {
      id: 6,
      name: "Action",
    },
  ]);
  const [totalPatientBodyData, setTotalPatientBodyData] = useState([
    {
      id: 1,
      trValue: [
        {
          sno: 1,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 2,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 3,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 4,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 5,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 6,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 7,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 8,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 9,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          sno: 10,
          patientname: "Steve",
          providername: "John",
          date: "07/04/24",
          carename: "Demo",
        },
      ],
    },
  ]);

  return (
    <>
      {/* start of modal import */}

      <PatientDeleteModal
        show={showDeletePatient}
        handleClose={handleCloseDeletePatient}
      />

      {/* <AppointmentAccepted
        show={showDeletePatient}
        handleClose={handleCloseDeletePatient}
      /> */}

      {/* <CancelAppointment
        show={showDeletePatient}
        handleClose={handleCloseDeletePatient}
      /> */}

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4">
            {/* <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h5 className="text_green_lg">Records</h5>
              <Link
                to="/addreport"
                className="d-flex align-items-center justify-content-between gap-2 border_less_button text-decoration-none"
              >
                {" "}
                <FaPlus />
                Add Report
              </Link>
            </div> */}
            <div className="mt-4">
              <PatientTable
                handleShowDeletePatient={handleShowDeletePatient}
                totalPatientHeadData={totalPatientHeadData}
                totalPatientBodyData={totalPatientBodyData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalPatients;
