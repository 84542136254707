import React from "react";
import { Accordion, Form } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

import DoctorCustomInput from "./DoctorCustomInput";
import uploadImage from "../../Assets/Images/upload.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import prescriptionImage from "../../Assets/Images/prescription.svg";
import { useState } from "react";
import calendar from "../../Assets/Images/calender.png";

const DoctorSettingsAccordion = () => {
  const userInformationDataOne = [
    {
      id: 1,
      label: "First Name",
      type: "text",
    },
    {
      id: 2,
      label: "Last Name",
      type: "text",
    },
    {
      id: 3,
      label: "Country",
      type: "text",
    },
    {
      id: 4,
      label: "Zip Code",
      type: "text",
    },
    {
      id: 5,
      label: "Sex assigned at birth",
      type: "text",
    },
    {
      id: 6,
      label: "Date of Birth",
      type: "text",
    },
    {
      id: 7,
      label: "Email Address",
      type: "email",
    },
  ];
  const userInformationDataTwo = [
    {
      id: 1,
      label: "Mobile number",
      type: "text",
    },
    {
      id: 2,
      label: "Height",
      type: "text",
    },
    {
      id: 3,
      label: "Weight",
      type: "text",
    },
    {
      id: 4,
      label: "Medications",
      type: "text",
    },
    {
      id: 5,
      label: "Allergies",
      type: "text",
    },
    {
      id: 6,
      label: "Allergies",
      type: "text",
    },
  ];
  const changePasswordData = [
    {
      id: 1,
      label: "Old Password",
      type: "password",
    },
    {
      id: 2,
      label: "New Password",
      type: "passwords",
    },
    {
      id: 3,
      label: "Confirm Password",
      type: "password",
    },
  ];
  const [fromDate, setFromDate] = useState(new Date());
  return (
    <div className="custom_accor">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>User Information</Accordion.Header>
          <Accordion.Body>
            <div>
              <div className="row">
                <div className="col-12 col-lg-6 pe-lg-4">
                  {/* <div>
                    {userInformationDataOne.map((item, index) => (
                      <div key={index} className="mb-4">
                        <label className="custom_label ps-0 mb-2">
                          {item.label}
                        </label>
                        <div>
                          <DoctorCustomInput />
                        </div>
                      </div>
                    ))}
                  </div> */}
                  <div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">
                        First Name
                      </label>
                      <div>
                        <input
                          placeholder="First Name"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">
                        Last Name
                      </label>
                      <div>
                        <input
                          placeholder=" Last Name"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">Country</label>
                      <div>
                        <input
                          placeholder="Country"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">Zip Code</label>
                      <div>
                        <input
                          placeholder="Zip Code"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6  ps-lg-4">
                  {/* <div>
                    {userInformationDataTwo.map((item, index) => (
                      <div key={index} className="mb-4">
                        <label className="custom_label ps-0 mb-2">
                          {item.label}
                        </label>
                        <div>
                          <DoctorCustomInput />
                        </div>
                      </div>
                    ))}
                  </div> */}
                  <div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">
                        Email Address
                      </label>
                      <div>
                        <input
                          placeholder="Email Address"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">
                        Mobile number
                      </label>
                      <div>
                        <input
                          placeholder="Mobile number"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">Gender</label>
                      <div>
                        <input
                          placeholder="Gender"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="custom_label ps-0 mb-2">
                        Date of Birth
                      </label>
                      <div className="customDatePicker acc_settings ">
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => setFromDate(date)}
                          icon={
                            <img
                              src={calendar}
                              className="img-fluid calendar_logo"
                            />
                          }
                          showIcon
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap  align-items-center gap-3 mt_2rem">
                <button className="pm_button_two">Cancel</button>
                <button className="pm_button_one">Submit</button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Enable Two-step Verification</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            <div className="mt-3">
              <Form.Check
                type="checkbox"
                label="Allow user to turn on 2-step Verification"
                className="d-flex align-items-center custom_chckbox"
              ></Form.Check>

              <div className="mt-4 ps-5 ms-4">
                <p className="text_green_md">Enforcement</p>
                <div className="custom_radio_button">
                  <label class="container">
                    <input type="radio" name="radio" />
                    <span class="checkmark"></span>
                    Off
                  </label>
                  <label class="container">
                    <input type="radio" name="radio" />
                    <span class="checkmark"></span>
                    on
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Notification Preferences</Accordion.Header>
          <Accordion.Body>
            <div className="mt-4 ps-5 ms-4">
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0">Return and Refunds</p>
                <div className="custom_toggle">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className="mb-0">Lorem ipsum</p>
                <div className="custom_toggle">
                  <label class="switch">
                    <input type="checkbox" checked />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Change Password</Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-10 col-md-9 col-lg-8 col-xl-7 mx-auto">
                {changePasswordData.map((item, index) => (
                  <div key={index} className="mb-4">
                    <label className="custom_label ps-0 mb-2">
                      {item.label}
                    </label>
                    <div>
                      <DoctorCustomInput />
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex flex-wrap justify-content-center  align-items-center gap-3 mt_2rem">
                <button className="pm_button_two">Cancel</button>
                <button className="pm_button_one">Submit</button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>{" "}
        <Accordion.Item eventKey="4">
          <Accordion.Header>Education Details</Accordion.Header>
          <Accordion.Body className="doctor_education_body">
            <div className="">
              <div className="row">
                <div className="col-12 col-md-9 col-lg-10 col-xxl-9  mx-auto">
                  <div>
                    <button className="uplaod_container d-flex flex-column  align-items-center justify-content-center mt-5 mb-4 w-100">
                      <img
                        src={uploadImage}
                        alt="upload"
                        className="img-fluid"
                        style={{ width: "20px" }}
                      />
                      <p className="mt-2">
                        Drag & Drop or <span className="link_span">Choose</span>{" "}
                        file here
                      </p>
                    </button>

                    <div>
                      <div className="upload_prescription mb-3">
                        <div className="row mx-auto">
                          <div className="col-10 ps-0">
                            <div>
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={prescriptionImage}
                                  alt="prescription"
                                  className="img-fluid"
                                />
                                <div>
                                  <p className="fw_450 mb-1">
                                    Doctor prescriptions
                                  </p>
                                  <p className="mb-0 text_black_xxs">
                                    Doctor prescriptions
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                            <button className="border-0 outline-0 bg-transparent">
                              <IoMdClose fontSize={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="upload_prescription">
                        <div className="row mx-auto">
                          <div className="col-10 ps-0">
                            <div>
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={prescriptionImage}
                                  alt="prescription"
                                  className="img-fluid"
                                />
                                <div>
                                  <p className="fw_450 mb-1">
                                    Doctor prescriptions
                                  </p>
                                  <p className="mb-0 text_black_xxs">
                                    Doctor prescriptions
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                            <button className="border-0 outline-0 bg-transparent">
                              <IoMdClose fontSize={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>{" "}
        <Accordion.Item eventKey="5">
          <Accordion.Header>Payment Information</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default DoctorSettingsAccordion;
