import React from "react";
import DashSidebar from "./DashSidebar";
import DashHeader from "./DashHeader";
import NewAppointBooking from "../../Components/DashboardComponent/NewAppointBooking";

function NewAppointBookingPage() {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="py-2 py-sm-4 dashboard-content">
          <div className="container">
            <NewAppointBooking />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAppointBookingPage;
