import React from "react";
import eyeIcon from "../../Assets/Images/eye.svg";

const DoctorCustomInput = (props) => {
  const { type, value, placeholder, onChange, name, ischooseFile } = props;
  return (
    <div>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        className="custom_input w-100"
      />
    </div>
  );
};

export default DoctorCustomInput;
