import React, { useState } from "react";
import { Button } from "react-bootstrap";
import care from "../../../Assets/Images/dashboard/care.png";
import pressure from "../../../Assets/Images/dashboard/Blood_Pressure.png";
import Calendar from "react-calendar";
import men from "../../../Assets/Images/dashboard/men.png";
import women from "../../../Assets/Images/dashboard/women.png";
import fitness from "../../../Assets/Images/dashboard/fitness.png";
import immunity from "../../../Assets/Images/dashboard/disease.png";
import sleeping from "../../../Assets/Images/dashboard/sleeping.png";
import { Col, Row } from "react-bootstrap";
import clarity from "../../../Assets/Images/dashboard/eye.png";
import food from "../../../Assets/Images/dashboard/food.png";
import routines from "../../../Assets/Images/dashboard/routines.png";
import herbal from "../../../Assets/Images/dashboard/herbal.png";
import { FaCheck } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import DoctorsList from "../DoctorsList";
import check from "../../../Assets/Images/dashboard/check.png";
import doctor from "../../../Assets/Images/dashboard/doctor-two.png";
import doctorthree from "../../../Assets/Images/dashboard/doctor-three.png";
import doctorbanner from "../../../Assets/Images/dashboard/doctor-banner.png";
import { Link } from "react-router-dom";
import BookingCompleted from "../BookingCompleted";
import { FaStar } from "react-icons/fa";
import { RxCopy } from "react-icons/rx";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
function ReferFriend() {
  return (
    <div className="refer_friend">
      <div className="header_sec">
        <h4 className="heading_txt">Refer a friends</h4>
        <p className="des_txt mb-0">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea
        </p>
      </div>
      <div className="share_friends">
        <div className="row h-100 d-flex align-items-center justify-content-center">
          <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 col-xxl-6">
            <div className="share_details text-center">
              <p className="title_txt mb-0">Share to Earn Refer a Friends</p>
              <div className="refer_code">
                <span className="address">YUIOKJNHFVBG8908876</span>
                <span className="copy-icon">
                  <RxCopy />
                </span>
              </div>
              <div className="social_icons">
                <a href="#" target="_blank" className="cmn-icons">
                  <div className="icons">
                    <FaFacebookF />
                  </div>
                </a>
                <a href="#" target="_blank" className="cmn-icons">
                  <div className="icons">
                    <FaXTwitter />
                  </div>
                </a>
                <a href="#" target="_blank" className="cmn-icons">
                  <div className="icons">
                    <FaLinkedinIn />
                  </div>
                </a>
                <a href="#" target="_blank" className="cmn-icons">
                  <div className="icons">
                    <FaWhatsapp size={18} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header_sec">
        <h4 className="heading_txt">How does refer works ?</h4>
        <p className="des_txt mb-0">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea
        </p>
      </div>
    </div>
  );
}

export default ReferFriend;
