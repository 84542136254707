import React, { useState } from "react";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { Link } from "react-router-dom";
import DoctorDashCalendar from "../components/DoctorDashCalendar";

function DoctorDashboard() {
  return (
    <>
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4">
            <DoctorDashCalendar />
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorDashboard;
