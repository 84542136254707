import { React, useState } from "react";
import { Link } from "react-router-dom";
import doctor from "../../Assets/Images/dashboard/doctor-two.png";
import doctorbanner from "../../Assets/Images/dashboard/doctor-banner.png";
import check from "../../Assets/Images/dashboard/check.png";
import { Col, Row, Form } from "react-bootstrap";
import SuccessfulModal from "./Modals/SuccessfulModal";
import DashSidebar from "../Pages/DashboardPages/DashSidebar";
import DashHeader from "../Pages/DashboardPages/DashHeader";

const DoctorDetails = () => {
  const treatment = [
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
    "Heart Specalist",
  ];

  const [show, setShow] = useState(false);

  const handlemodal = () => setShow(true);

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments doctorsdetail">
          <h4 class="h4">New Appointment Booking</h4>
          <div className="formbox">
            <div className="flexbox">
              <div>
                <p className="formhead">Dr.Akule Vivian Details :</p>
              </div>
              <div>
                <Link className="btn btn-details" to="/choose-doctors">
                  Back
                </Link>
              </div>
            </div>
            <div className="docdetails">
              <img
                src={doctorbanner}
                alt="Detail Image"
                className="detailimg"
              />
              <div className="docprofile">
                <Row>
                  <Col xs={12} md={12} lg={5} className="mb-4 mb-lg-0">
                    <img
                      src={doctor}
                      alt="Detail Image"
                      className="doctorimg"
                    />
                    <p className="doctorname">Dr.Akule Vivian</p>
                    <span className="doctorsubname">Heart Specalist</span>
                    <p className="content mt-2">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Aut, corporis libero ratione dignissimos quia a repellat
                      omnis et beatae non consectetur provident? Id omnis
                      repellendus, numquam modi quasi tempore nulla.
                    </p>
                  </Col>
                  <Col xs={12} md={12} lg={7}>
                    <div className="treatments">
                      <span className="treatmenthead">Treatments</span>
                      <ul className="treatmentslist">
                        {treatment.map((t) => (
                          <li>
                            <img src={check} alt="Check" />
                            <span>{t}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
                <div className="bottomcontent">
                  <Form.Check
                    type="checkbox"
                    label="I accept Payment Terms & Conditions"
                  ></Form.Check>
                  <button
                    type="button"
                    className="btn bgdarkgreen me-3"
                    onClick={handlemodal}
                  >
                    Paynow
                  </button>
                  <Link className="btn btn-red" to="/">
                    Paylater
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <SuccessfulModal show={show} />
        </div>
      </div>
    </div>
  );
};

export default DoctorDetails;
