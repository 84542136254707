import React from 'react'
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const CalendarCont = () => {
  const DnDCalendar = withDragAndDrop(Calendar);
  const localizer = globalizeLocalizer(globalize);
  const events = [
    {
      id: 1,
      title: "Meeting",
      start: new Date(2024, 3, 1, 10, 40),
      end: new Date(2024, 3, 1, 12, 40),
    },
    {
      id: 2,
      title: "Marriage",
      start: new Date(2024, 4, 12, 10, 50),
      end: new Date(2024, 4, 12, 12, 50),
    },
    {
      id: 3,
      title: "Reception",
      start: new Date(2024, 4, 18, 3, 40),
      end: new Date(2024, 4, 18, 5, 30),
    },
    {
      id: 4,
      title: "Exam",
      start: new Date(2024, 5, 25, 10, 30),
      end: new Date(2024, 5, 28, 50, 50),
    },
  ];


  const Event = ({ event }) => {

    const fullDate = event.start;
    const startYear = fullDate.getFullYear();
    const startMonth = fullDate.getMonth();
    const startDate = fullDate.getDate();
    const startHour = fullDate.getHours();
    const startMinute = fullDate.getMinutes();
    const startSeconds = fullDate.getSeconds();
  
    const fullEndDate = event.end;
    const endYear = fullDate.getFullYear();
    const endMonth = fullDate.getMonth();
    const endDate = fullEndDate.getDate();
    const endHour = fullEndDate.getHours();
    const endMinute = fullEndDate.getMinutes();
    const endSeconds = fullEndDate.getSeconds();

    console.log(startDate,"safasfas");
    return (
      <>
        <div className={`cal__eventHolder ${startDate <= 10 ? "cal__skyEventHolder" : (startDate > 10 && startDate <=15) ? 'cal__brownEventHolder' : (startDate > 15 && startDate <= 20) ? 'cal__newSkyEventHolder' : (startDate > 20 && startDate <= 31) ? 'cal__violeEventHolder' : '' }`}>
          <div
            className={`cal__holderTab ${startDate <= 10 ? "cal__skyHolder" : (startDate > 10 && startDate <=15) ? 'cal__brownHolder' : (startDate > 15 && startDate <= 20) ? 'cal__newSkyHolder' : (startDate > 20 && startDate <= 31) ? 'cal__violetHolder' : '' }`}
          ></div>
          <div className="d-flex justify-content-between align-items-start px-1 py-2">
            <div>
              <p className="cal__eventTitle m-0">{event.title}</p>
              <p className={`m-0 cal__eventTime mt-1 ${startDate <= 10 ? "cal__skyEventTime" : (startDate > 10 && startDate <=15) ? 'cal__brownEventTime' : (startDate > 15 && startDate <= 20) ? 'cal__newSkyEventTime' : (startDate > 20 && startDate <= 31) ? 'cal__violetEventTime' : '' }`}>
                {`${startHour}:${startMinute}`} - {`${endHour}:${endMinute}`}
              </p>
            </div>
            <div className={`cal__rightBadge d-flex justify-content-center align-items-center ${endDate === startDate ? 'rounded-5' : 'rounded-2'} ${startDate <= 10 ? "cal__skyBadge" : (startDate > 10 && startDate <=15) ? 'cal__brownBadge' : (startDate > 15 && startDate <= 20) ? 'cal__newSkyBadge' : (startDate > 20 && startDate <= 31) ? 'cal__violetBadge' : '' }`}>
              <p className="m-0 text-center">{startDate}{endDate === startDate ? '' : `- ${endDate}`}</p>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
     <div className="calendar__holder">
      <div className='calendar__scoller pb-2'>
        <DnDCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          components={{
            event: Event,
          }}
        />
      </div>
      </div>
    </>
  )
}

export default CalendarCont