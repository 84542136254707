import React from "react";
import { Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import NewAppoint from "../../../Assets/Images/dashboard/dashImage/lotties/newAppoint.json";
import CalendarCont from "../CalendarCont";
import Notifications from "../Notifications";
import { Link, useNavigate } from "react-router-dom";

const DashCalendar = () => {
  const navigate = useNavigate();

  const handleTotalAppointment = () => {
    navigate("/appointment");
  };
  return (
    <>
      <Row>
        <Col lg={3} md={4} sm={6} className="mb-4">
          <div
            className="dash__singleBox dash__greenBox d-flex flex-column justify-content-between overflow-hidden rounded-4"
            onClick={handleTotalAppointment}
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../../Assets/Images/dashboard/dashImage/calendar.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">
                  Total Appointment
                </p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../../Assets/Images/dashboard/dashImage/greenGraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </div>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <div
            className="dash__singleBox dash__violetBox d-flex flex-column justify-content-between overflow-hidden rounded-4"
            onClick={handleTotalAppointment}
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../../Assets/Images/dashboard/dashImage/wallet.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">
                  Reschedule Appointment
                </p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../../Assets/Images/dashboard/dashImage/vioGraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </div>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <div
            className="dash__singleBox dash__redBox d-flex flex-column justify-content-between overflow-hidden rounded-4"
            onClick={handleTotalAppointment}
          >
            <div className="d-flex justify-content-between align-items-start px-3 pt-4">
              <img
                src={
                  require("../../../Assets/Images/dashboard/dashImage/calendarred.svg")
                    .default
                }
                className="img-fluid dash__singleBoxLogos rounded-3"
              />
              <div className="dash__singleBoxCountHolder">
                <p className="m-0 dash__singleBoxLabel text-end">
                  Cancel Appointment
                </p>
                <h6 className="m-0 dash__singleBoxValue text-end mt-1">0</h6>
              </div>
            </div>
            <img
              src={
                require("../../../Assets/Images/dashboard/dashImage/redgraph.svg")
                  .default
              }
              className="img-fluid  dash__singleBoxGraph mt-3"
            />
          </div>
        </Col>

        <Col lg={3} md={4} sm={6} className="mb-4">
          <Link to="/choose-required" className="text-decoration-none">
            <div className="dash__lottieBox d-flex flex-column justify-content-between overflow-hidden rounded-4">
              <div className="d-flex justify-content-between align-items-center  px-3 pt-1">
                <p className="m-0 dash__singleBoxWhiteLabel">
                  New Appointment Booking
                </p>
                <div className="dash__lottieHolder">
                  <Lottie animationData={NewAppoint} loop={true}></Lottie>
                </div>
              </div>
              <img
                src={
                  require("../../../Assets/Images/dashboard/dashImage/lottieGraph.svg")
                    .default
                }
                className="img-fluid  dash__singleBoxGraph"
              />
            </div>
          </Link>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={8} className="mb-4 mb-xxl-0">
          <CalendarCont />
        </Col>
        <Col lg={4}>
          <Notifications />
        </Col>
      </Row>
    </>
  );
};

export default DashCalendar;
