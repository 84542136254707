import React from "react";
import Lottie from "lottie-react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

const RejectReasonModal = (props) => {
  const { show, handleClose } = props;
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button>
        </Modal.Header>

        <Modal.Body className="">
          <div>
            <h6 className="fw_550">Reject Reason</h6>
            <p className="text_black_xs my-3">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo
            </p>
            <div className="text_red_bg mt-4">
              Cancelled By You on 23 March 2023
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RejectReasonModal;
