import React from "react";
import Modal from "react-bootstrap/Modal";
import Countdown, { zeroPad } from "react-countdown";

import { IoClose } from "react-icons/io5";

import doctorIcon from "../../../Assets/Images/doctorIcon.png";
import { useNavigate } from "react-router-dom";

const Timeout = (props) => {
  const { show, handleTimeoutReschedule, handleTimeoutCancel } = props;

  const navigate = useNavigate();

  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {zeroPad(hours)} : {zeroPad(minutes)} : {zeroPad(seconds)}
    </span>
  );
  return (
    <div>
      <Modal
        show={show}
        onHide={handleTimeoutCancel}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          {/* <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button> */}
        </Modal.Header>

        <Modal.Body className="">
          <div className="d-flex flex-column align-items-center">
            <img
              src={doctorIcon}
              alt="calender"
              className="img-fluid"
              style={{ width: "40px" }}
            />
            <p className="mdl_title_sm mt-3 mb-2">Timeout</p>

            <div className="timeout_wraper">
              <Countdown date={Date.now() + 600000} renderer={renderer} />
            </div>
            <p className="text_black_xs text-center mt-3">
              Your provider are scheduled in another appointment. You will wait
              for another 10 mins ( or ) Rescheduled now
            </p>
            <div className="d-flex flex-column  align-items-center justify-content-center gap-2  w_80">
              <button
                className="mdl_green_btn_sm mt-3 w-100"
                onClick={handleTimeoutReschedule}
              >
                Reschedule Appointment
              </button>
              <button
                className="mdl_gray_btn_sm mt-2 w-100"
                onClick={handleTimeoutCancel}
              >
                Cancel Appointment
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Timeout;
