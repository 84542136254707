import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Lottie from "lottie-react";
import successful from "../../../Assets/Images/dashboard/successful.json";

function PaymentSuccessModal(props) {
  const { show, handleClose } = props;

  return (
    <div>
      <div className="appoinment_booking_modal">
        <Modal
          show={show}
          onHide={handleClose}
          className="modalbox appoinment_booking_modal patient_waiting"
          centered
        >
          <Modal.Body>
            <div className="successfulimg">
              <Lottie animationData={successful} loop={true}></Lottie>
            </div>
            <p className="content header_txt booking">
              Your Payment Successful !
            </p>
            <p className="content bookquote f-15">
              Your payment was successful ! You can now continueTwin Tree care
            </p>

            <Button
              className="cmn-greenBtn home-btn mt-3"
              onClick={handleClose}
            >
              Go to Consultation
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default PaymentSuccessModal;
