import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Lottie from "lottie-react";
import successful from "../../../Assets/Images/dashboard/successful.json";
import cancel from "../../../Assets/Json/cancel.json";

function AppointmentCancelModal(props) {
  const { show, handleClose } = props;
  const [successfull, setSuccessfull] = useState(true);

  const handleCloseAppointment = () => {
    setSuccessfull(false);
  };
  return (
    <div>
      <div className="appoinment_booking_modal">
        <Modal
          show={show}
          onHide={handleClose}
          className="modalbox appoinment_booking_modal patient_waiting"
          centered
        >
          <Modal.Body>
            <div className="cancelimg">
              <Lottie animationData={cancel} loop={true}></Lottie>
            </div>
            <p className="content header_txt booking mt-4">Your Appointment</p>
            <p className="content header_txt booking">was cancelled</p>
            <p className="content bookquote">Have a great day ahead.</p>
            <Button
              className="cmn-greenBtn home-btn mt-3"
              onClick={handleClose}
            >
              Go to Waiting Room
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AppointmentCancelModal;
