import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import PaymentMethod from "../modals/PaymentMethod";
import WaitingTime from "../modals/WaitingTime";
import Timeout from "../modals/Timeout";
import PaymentSuccessModal from "../modals/PaymentSuccessfullModal";
import RescheduleAppointmentModal from "../modals/RescheduleAppointment";
import BookingSuccessfullModal from "../modals/BookingSuccessfullModal";
import ConfirmCancelAppointment from "../modals/ConfirmCancelAppointment";
import AppointmentCancelModal from "../modals/AppointmentCancelModal";

const PatientWaitingFaq = () => {
  const navigate = useNavigate();
  const [showPaymentMethod, setShowPaymentMethod] = useState(true);
  const [showWaitingTime, setShowWaitingTime] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showTimeout, setShowTimeout] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showBookingSuccess, setShowBookingSuccess] = useState(false);
  const [showConfirmCancelAppointment, setShowConfirmCancelAppointment] =
    useState(false);
  const [showAppoinmentCancel, setShowAppointmentCancel] = useState(false);

  const handlePayNow = () => {
    setShowPaymentMethod(false);
    setShowPaymentSuccess(true);
  };
  const handlePayLater = () => {
    setShowPaymentMethod(false);
  };
  const handleWaitMoreTime = () => {
    setShowWaitingTime(false);
    const timer = setTimeout(() => {
      setShowTimeout(true);
    }, 10000);
    return () => clearTimeout(timer);
  };
  const handleTimeoutCancel = () => {
    setShowTimeout(false);
    setShowConfirmCancelAppointment(true);
  };
  const handleTimeoutReschedule = () => {
    setShowTimeout(false);
    setShowReschedule(true);
  };
  const handleClosePaymentSuccess = () => {
    setShowPaymentSuccess(false);
  };
  const handleShowRescheduleAppointment = () => {
    setShowWaitingTime(false);
    setShowReschedule(true);
  };
  const handleNextRescheduleAppointment = () => {
    setShowReschedule(false);
    setShowBookingSuccess(true);
  };

  const handleCloseRescheduleAppointment = () => {
    setShowReschedule(false);
  };

  const handleCloseBookingSuccess = () => {
    setShowBookingSuccess(false);
  };

  const handleCloseConfirmCancel = () => {
    setShowConfirmCancelAppointment(false);
    setShowAppointmentCancel(true);
  };

  const handleCloseCancelAppointment = () => {
    setShowAppointmentCancel(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPaymentMethod(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPaymentMethod(false);
      setShowWaitingTime(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowPaymentMethod(false);
  //     setShowWaitingTime(false);
  //     setShowTimeout(true);
  //   }, 6000);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <>
      {/* start of modal import */}

      <PaymentMethod
        show={showPaymentMethod}
        handlePayNow={handlePayNow}
        handlePayLater={handlePayLater}
      />
      <WaitingTime
        show={showWaitingTime}
        handleWaitMoreTime={handleWaitMoreTime}
        handleShowRescheduleAppointment={handleShowRescheduleAppointment}
      />
      <Timeout
        show={showTimeout}
        handleTimeoutReschedule={handleTimeoutReschedule}
        handleTimeoutCancel={handleTimeoutCancel}
      />
      <PaymentSuccessModal
        show={showPaymentSuccess}
        handleClose={handleClosePaymentSuccess}
      />
      <RescheduleAppointmentModal
        show={showReschedule}
        handleNext={handleNextRescheduleAppointment}
        handleClose={handleCloseRescheduleAppointment}
      />

      <BookingSuccessfullModal
        show={showBookingSuccess}
        handleClose={handleCloseBookingSuccess}
      />

      <ConfirmCancelAppointment
        show={showConfirmCancelAppointment}
        handleClose={handleCloseConfirmCancel}
      />

      <AppointmentCancelModal
        show={showAppoinmentCancel}
        handleClose={handleCloseCancelAppointment}
      />

      {/* end of modal import */}
      <div className="waiting_section">
        <div className="container container_95">
          <div className="screen_ht">
            <div className="row py-5">
              <div className="col-12 col-lg-7 pe-lg-4">
                <div className="custom_video_player">
                  <ReactPlayer
                    url="https://youtu.be/ihGb5Y5aqAM?si=bvJJbJ1vA_GzNSvf"
                    controls={true}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5 mt-5 mt-lg-0 ps-lg-4">
                <div className="faq_bg">
                  <div className="custom_accor waiting_custom_accor">
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What happens in an online doctors appointment?
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          How can I contact the office?
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          What is the appointment cancellation policy?
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          Will I receive an account in a patient portal?
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>{" "}
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          Is online scheduling of appointments an option?
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>
                          What information will I need to do that?
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>
                          What forms will I need to fill out when I arrive?
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header>
                          What happens in an online doctors appointment?
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>
                          What is the appointment cancellation policy?
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>
                          What information will I need to do that?
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy eirmod tempor invidunt ut
                            labore et dolore magna aliquyam erat, sed diam
                            voluptua. At vero eos et accusam et justo duo
                            dolores et ea rebum. Stet clita kasd gubergren, no
                            sea takimata sanctus est Lorem ipsum dolor sit amet.
                            Lorem ipsum dolor sit amet, consetetur
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientWaitingFaq;
