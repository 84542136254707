import React, { useRef } from "react";
import "./App.css";
import "./custom.css";
import "./aligner.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "react-calendar/dist/Calendar.css";
import { Route, Routes } from "react-router-dom";
import Landing from "./UserPanel/Pages/Landing";
import SignUp from "./UserPanel/Pages/SignUp";
import OtpVerification from "./UserPanel/Pages/OtpVerification";
import CreatePassword from "./UserPanel/Pages/CreatePassword";
import Login from "./UserPanel/Pages/Login";
import Dashboard from "./UserPanel/Pages/DashboardPages/Dashboard";

import Appointments from "./UserPanel/Components/Appointments";
import AppointDetails from "./UserPanel/Components/AppointDetails";
import NewAppointment from "./UserPanel/Components/NewAppointment";
import NewAppointmentDate from "./UserPanel/Components/NewAppointmentDate";
import CancellationForm from "./UserPanel/Pages/CancellationForm";
import Records from "./UserPanel/Pages/Records";
import AddReport from "./UserPanel/Pages/AddReport";
import NewAppointBookingPage from "./UserPanel/Pages/DashboardPages/NewAppointBookingPage";
import ChooseDoctors from "./UserPanel/Components/ChooseDoctors";
import DoctorDetails from "./UserPanel/Components/DoctorDetails";
import RaiseGrievancePage from "./UserPanel/Pages/DashboardPages/RaiseGrievancePage";
import DoctorNewAppointment from "./DoctorPanel/DoctorNewAppointment";
import DoctorAppointment from "./DoctorPanel/DoctorAppointment";
import DoctorAppointmentDetail from "./DoctorPanel/DoctorAppointmentDetail";
import DoctorReschedule from "./DoctorPanel/DoctorReschedule";
import RateExperiencePage from "./UserPanel/Pages/DashboardPages/RateExperiencePage";
import Settings from "./UserPanel/Pages/Settings";
import TotalPatients from "./DoctorPanel/pages/TotalPatients";
import DoctorCareList from "./DoctorPanel/pages/DoctorCareList";
import ReferFriendPage from "./UserPanel/Pages/DashboardPages/ReferFriendPage";
import DoctorAddCare from "./DoctorPanel/pages/DoctorAddCare";
import DoctorAddReport from "./DoctorPanel/pages/DoctorAddReport";
import DoctorSettings from "./DoctorPanel/pages/DoctorSettings";
import ChooseRequiredPage from "./UserPanel/Pages/DashboardPages/ChooseRequiredPage";
import EducationDetails from "./DoctorPanel/pages/EducationDetails";
import DoctorRecords from "./DoctorPanel/pages/DoctorRecords";
import Availability from "./DoctorPanel/pages/Availability";
import AvailabilityAddCare from "./DoctorPanel/pages/AvailabilityAddCare";
import DoctorDashboard from "./DoctorPanel/pages/DoctorDashboard";
import CompletedAppointmentDetails from "./UserPanel/Components/CompletedAppointmentDetails";
import CancelledAppointmentDetails from "./UserPanel/Components/CancelledAppointmentDetails";
import UpcomingAppointmentDetails from "./DoctorPanel/components/UpcomingAppointmentDetails";

import AppointmentMeeting from "./WaitingRoom/PatientWaiting/modals/AppointmentMeeting";
import PatientWaitingFaq from "./WaitingRoom/PatientWaiting/pages/PatientWaitingFaq";
import RescheduleAppointments from "./DoctorPanel/components/RescheduleAppointments";
import RescheduleAppointmentModal from "./WaitingRoom/PatientWaiting/modals/RescheduleAppointment";
import BookingSuccessfullModal from "./WaitingRoom/PatientWaiting/modals/BookingSuccessfullModal";
import AppointmentCancelModal from "./WaitingRoom/PatientWaiting/modals/AppointmentCancelModal";
import PaymentSuccessModal from "./WaitingRoom/PatientWaiting/modals/PaymentSuccessfullModal";
import PatientDetails from "./WaitingRoom/DoctorWaiting/pages/PatientDetails";
import DoctorAppointmentMeeting from "./WaitingRoom/DoctorWaiting/modals/DoctorAppointmentMeeting";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/otp-verification" element={<OtpVerification />} />
        <Route exact path="/create-password" element={<CreatePassword />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/choose-required" element={<ChooseRequiredPage />} />
        <Route
          exact
          path="/new-appointment-booking"
          element={<NewAppointBookingPage />}
        />
        <Route exact path="/raise-grievance" element={<RaiseGrievancePage />} />
        <Route exact path="/rate-experience" element={<RateExperiencePage />} />
        <Route exact path="/refer-friend" element={<ReferFriendPage />} />
        {/* sakthi changes end*/}
        <Route path="/appointment" element={<Appointments />} />
        <Route path="/appointment-detail" element={<AppointDetails />} />
        <Route
          path="/completed-appointment-detail"
          element={<CompletedAppointmentDetails />}
        />{" "}
        <Route
          path="/cancelled-appointment-detail"
          element={<CancelledAppointmentDetails />}
        />
        <Route path="/new-appointment" element={<NewAppointment />} />
        <Route path="/new-appointmentdate" element={<NewAppointmentDate />} />
        <Route path="/cancellationform" element={<CancellationForm />} />
        <Route path="/records" element={<Records />} />
        <Route path="/addreport" element={<AddReport />} />
        <Route path="/choose-doctors" element={<ChooseDoctors />} />
        <Route
          path="/doctor-new-appointment"
          element={<DoctorNewAppointment />}
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/total-patients" element={<TotalPatients />} />
        {/* doctor links */}
        <Route exact path="/education-details" element={<EducationDetails />} />
        <Route path="/doctor-dashboard" element={<DoctorDashboard />}></Route>
        <Route path="/doctor-appointment" element={<DoctorAppointment />} />
        <Route
          path="/doctor-appointment-detail"
          element={<DoctorAppointmentDetail />}
        />{" "}
        <Route
          path="/doctor-upcoming-appointment-details"
          element={<UpcomingAppointmentDetails />}
        />
        <Route path="/doctor-reschedule" element={<DoctorReschedule />} />
        <Route path="/doctor-care-list" element={<DoctorCareList />} />
        <Route path="/doctor-addcare" element={<DoctorAddCare />} />
        <Route path="/doctor-addreport" element={<DoctorAddReport />} />
        <Route path="/doctor-settings" element={<DoctorSettings />} />
        <Route path="/doctor-detail" element={<DoctorDetails />} />
        <Route path="/doctor-records" element={<DoctorRecords />} />
        <Route path="/availability" element={<Availability />} />
        <Route path="/availability-addcare" element={<AvailabilityAddCare />} />
        {/* waiting room routes */}
        <Route
          path="/patient-appointment-meeting"
          element={<AppointmentMeeting />}
        />{" "}
        <Route path="/patient-waiting-faq" element={<PatientWaitingFaq />} />
        <Route
          path="/doctor-appointment-meeting"
          element={<DoctorAppointmentMeeting />}
        />{" "}
        <Route
          path="/appointment-patient-details"
          element={<PatientDetails />}
        />
        {/* <Route
          path="/reschedule-appointment"
          element={<RescheduleAppointmentModal />}
        />
        <Route
          path="/booking-successfull"
          element={<BookingSuccessfullModal />}
        />
        <Route
          path="/appointment-cancel"
          element={<AppointmentCancelModal />}
        />
        <Route path="/payment-successfull" element={<PaymentSuccessModal />} /> */}
      </Routes>
    </div>
  );
}

export default App;
