import React, { useRef, useState } from "react";

import { IoIosCamera } from "react-icons/io";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import DoctorSettingsAccordion from "../components/DoctorSettingsAccordion";

function DoctorSettings() {
  const [inputValue, setInputValues] = useState({
    laborder: "",
    labreport: "",
    labresults: "",
    medicalrecords: "",
    patientinstructions: "",
    visitsummary: "",
    mipsvisitsummary: "",
    letters: "",
    insurancecard: "",
    summary: "",
  });

  const [isImageUpload, setIsImageUpload] = useState(null);

  const imageRef = useRef(null);

  const {
    laborder,
    labreport,
    labresults,
    medicalrecords,
    patientinstructions,
    visitsummary,
    mipsvisitsummary,
    letters,
    insurancecard,
    summary,
  } = inputValue;

  const handleReportInput = (e) => {
    const { value, name } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleImageUpload = () => {
    imageRef.current.click();
  };
  const addReportData = [
    {
      id: 1,
      label: "Lab Order",
      type: "name",
      name: "laborder",
      value: laborder,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 2,
      label: "Lab Report",
      type: "file",
      name: "labreport",
      value: labreport,
      placeholder: "No Choose File",
      ischooseFile: true,
    },
    {
      id: 3,
      label: "Lab Order & Results",
      type: "name",
      name: "labresults",
      value: labresults,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 4,
      label: "Medical Records",
      type: "name",
      name: "medicalrecords",
      value: medicalrecords,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 5,
      label: "Patient Instructions",
      type: "name",
      name: "patientinstructions",
      value: patientinstructions,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 6,
      label: "Visit Summary",
      type: "name",
      name: "visitsummary",
      value: visitsummary,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 7,
      label: "MIPS Visit Summary",
      type: "name",
      name: "mipsvisitsummary",
      value: mipsvisitsummary,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 8,
      label: "Letters",
      type: "name",
      name: "letters",
      value: letters,
      placeholder: "Demo",
      ischooseFile: false,
    },
    {
      id: 9,
      label: "Insurance Card",
      type: "file",
      name: "insurancecard",
      value: insurancecard,
      placeholder: "Demo",
      ischooseFile: true,
    },
    {
      id: 10,
      label: "Summary",
      type: "name",
      name: "summary",
      value: summary,
      placeholder: "Demo",
      ischooseFile: false,
    },
  ];

  console.log("inputs", inputValue);

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="container px-xl-0">
          <div className="mt-4">
            <h5 className="text_green_lg">Settings</h5>

            <div className="row mx-auto mt-4">
              <div className="col-12 col-sm-11 col-md-10 col-lg-11 px-0">
                <div className="primary_card">
                  <div className="row mx-auto">
                    <div className="col-12 col-md-10 col-lg-8 mx-auto px-0">
                      <div className="d-flex justify-content-center justify-content-sm-start">
                        <input
                          type="file"
                          className="d-none"
                          ref={imageRef}
                          onChange={(e) =>
                            setIsImageUpload(
                              URL.createObjectURL(e.target.files[0])
                            )
                          }
                        />
                        <button
                          className="upload_image_wraper"
                          onClick={handleImageUpload}
                        >
                          {isImageUpload ? (
                            <img
                              src={isImageUpload}
                              alt="patient"
                              className="img_container_fit img-fluid"
                            />
                          ) : (
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                              <IoIosCamera fontSize={23} fill="#fff" />
                              <p className="text_white_xs text-center mb-0 mt-1">
                                Click to change photo
                              </p>
                            </div>
                          )}
                        </button>
                      </div>
                      <div className="mt_2rem">
                        <DoctorSettingsAccordion />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorSettings;
