import React from 'react';
import Form from "react-bootstrap/Form";
import { Link } from 'react-router-dom';
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";

const NewAppointment = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments doctornewappointment">
            <h4 class="h4">New Appointment Booking</h4>
            <div className="formbox">
                <div className="flexbox mb-4">
                    <div>
                        <p className="formhead">Doctor Fills Consultation Form :</p>
                    </div>
                    <div>
                        <Link className="btn btn-details bgdarkgreen me-3" to="/new-appointmentdate">Next</Link>
                        <Link className="btn btn-details" to="/">Back</Link>
                    </div>
                </div>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Choose Patient List <span className="text-danger">*</span></Form.Label>
                        <Form.Select>
                            <option>Lorem Ipsum</option>
                            <option>Lorem Ipsum</option>
                            <option>Lorem Ipsum</option>
                            <option>Lorem Ipsum</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Reason for consultation <span className="text-danger">*</span></Form.Label>
                        <Form.Control as="textarea"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Symptoms <span className="text-danger">*</span> </Form.Label>
                        <Form.Control as="textarea"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>History of similar symptoms/ illness <span className="text-danger">*</span> </Form.Label>
                        <Form.Control as="textarea"></Form.Control>
                    </Form.Group>
                </Form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default NewAppointment;