import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import { Link } from "react-router-dom";
import AvailabilityTable from "../table/AvailabilityTable";
import { Button } from "react-bootstrap";
import CareListDelete from "../modal/CareListDelete";

function Availability() {
  const [showCareDelete, setShowCareDelete] = useState(false);

  const [careListHeadData, setCareListHeadData] = useState([
    {
      id: 1,
      name: "ID",
    },
    {
      id: 2,
      name: "Service ID",
    },
    {
      id: 3,
      name: "Name",
    },
    {
      id: 4,
      name: "Doctors",
    },
    {
      id: 5,
      name: "Charges",
    },
    {
      id: 6,
      name: "Duration",
    },
    {
      id: 7,
      name: "Category",
    },
    {
      id: 8,
      name: "Status",
    },
    {
      id: 9,
      name: "Actions",
    },
  ]);
  const [careListBodyData, setCareListBodyData] = useState([
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
    {
      id: 1,
      trValue: [
        {
          id: 12053,
          serviceid: "256324",
          name: "Steve",
          doctor: "John",
          charges: "210",
          duration: "10H 20M",
          category: "GENERAL CARE",
          status: "Active",
        },
      ],
    },
  ]);

  const handleShowCareDelete = () => {
    setShowCareDelete(true);
  };
  const handleCloseCareDelete = () => {
    setShowCareDelete(false);
  };
  return (
    <>
      {/* start of modal import */}
      <CareListDelete
        show={showCareDelete}
        handleClose={handleCloseCareDelete}
      />

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4 availability_page">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h4 className="mb-0 text_black_md">Availability</h4>
              <Link to="/availability-addcare" className="text-decoration-none">
                <Button className="add-careBtn cmn-greenBtn d-flex align-items-center justify-content-between gap-2">
                  {" "}
                  <FaPlus />
                  Add Care
                </Button>
              </Link>
            </div>
            <div className="mt-4">
              {" "}
              <AvailabilityTable
                handleShowCareDelete={handleShowCareDelete}
                careListHeadData={careListHeadData}
                careListBodyData={careListBodyData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Availability;
