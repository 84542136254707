import React from "react";
import serviceLeaf from "../../Assets/Images/landing/service-leaf.png";
import { IoMailOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { RxCountdownTimer } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";
import { PiCalendarCheck } from "react-icons/pi";
import { PiCalendarXBold } from "react-icons/pi";
import { IoVideocam } from "react-icons/io5";
import { Link } from "react-router-dom/dist";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DashSidebar from "../Pages/DashboardPages/DashSidebar";
import DashHeader from "../Pages/DashboardPages/DashHeader";

const AppointDetails = () => {
  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments appointdetailsbox">
          <div className="patientsappointment">
            <div>
              <img src={serviceLeaf} alt="Image" className="profile" />
            </div>
            <div>
              <div className="appointdetails">
                <Row className="patientsbox">
                  <Col xs={12} md={6} lg={3}>
                    <div className="fleximg">
                      <div>
                        <p>Kelly</p>
                        <p>
                          <IoMailOutline /> <span>Kelly@gmail.com</span>
                        </p>
                        <p>
                          <FiPhone /> <span>+91-1250321462</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <p>Appointment Date & Time :</p>
                    <div className="flex flextime">
                      <div>
                        <p>
                          <RxCountdownTimer />
                        </p>
                      </div>
                      <div>
                        <p>
                          <span>25 Apr 2024 10:45 PM</span>
                          <div className="flex">
                            <span className="text-green">General Visit</span>
                            <span>|</span>
                            <span className="text-blue">Video Call</span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <p>Type of Appointment :</p>
                    <p>
                      <IoVideocam />
                      <span className="ps-2">25 Apr 2024 10:45 PM</span>
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <p>Visit Type :</p>
                    <p>
                      <IoVideocam />
                      <span className="ps-2">General Type</span>
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <p>
                      <span>Country & Zip Code :</span>
                      <small className="text-green">Canada</small>
                      <small className="text-blue">1023011530</small>
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <p>
                      <span>Gender & Date of Birth :</span>
                      <small className="text-green">Female</small>
                      <small className="text-blue">30-02-1996</small>
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <p>
                      <span>Height & Weight</span>
                      <small className="text-green">160 Cm</small>
                      <small className="text-blue">65 Kg</small>
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <p>
                      <span>Medication & Allergies</span>
                      <small className="text-green">Lorem Ipsum</small>
                      <small className="text-blue">Lorem Ipsum</small>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={6} className="mb-4 mb-lg-0">
                    <p>
                      <span>Reason for consultation :</span>
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolore nihil cupiditate ea in odio unde, excepturi
                      eligendi qui officiis dolor, beatae tempora itaque. Nemo
                      alias sunt dolorum nostrum aliquid eveniet!
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={3} className="mb-4 mb-md-0">
                    <p>
                      <span>Symptoms :</span>
                    </p>
                    <p>Lorem ipsum</p>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <p>
                      <span>Illness :</span>
                    </p>
                    <p>Lorem ipsum</p>
                  </Col>
                </Row>
                <div className="mt-5">
                  <p>
                    <span>Payment Type :</span>
                  </p>
                  {/* <p
                    className={
                      selectedUpcomingAppointment.paymentType === "Paid"
                        ? "text-green"
                        : "text_red_xs"
                    }
                  >
                    {selectedUpcomingAppointment.paymentType}
                  </p> */}
                  <p className="text-green">Paid</p>
                </div>
              </div>
              <div className="d-flex flex-wrap gap-3 justify-content-between">
                <div>
                  <Link
                    className="btn btn-green d-none"
                    to="/new-appointmentdate"
                  >
                    <span>
                      <PiCalendarCheck />
                    </span>{" "}
                    Completed
                  </Link>
                  <Link className="btn btn-violet" to="/new-appointmentdate">
                    <span>
                      <PiCalendarCheck />
                    </span>{" "}
                    Reschedule Appointment
                  </Link>
                  <Link className="btn btn-red" to="/cancellationform">
                    <span>
                      <PiCalendarXBold />
                    </span>{" "}
                    Cancelled Reason
                  </Link>
                </div>
                <button className="pm_button_one">Pay Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointDetails;
