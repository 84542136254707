import React from "react";
import Lottie from "lottie-react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

import calender from "../../Assets/Images/calender.png";
import { useNavigate } from "react-router-dom";

const AppointmentAccepted = (props) => {
  const { show, handleClose } = props;

  const navigate = useNavigate();

  const handleDoctorAppoinmentAccept = () => {
    handleClose();
    navigate("/doctor-appointment");
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button>
        </Modal.Header>

        <Modal.Body className="">
          <div className="d-flex flex-column align-items-center">
            <img
              src={calender}
              alt="calender"
              className="img-fluid"
              style={{ width: "40px" }}
            />
            <h6 className="fw_550 mt-3">Appointment Accepted</h6>
            <p className="text_black_xxs">
              Your Appointment has been scheduled on 23 Mar 2023
            </p>
            <button
              className="gren_btn mt-3"
              onClick={handleDoctorAppoinmentAccept}
            >
              Go to Appointment
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AppointmentAccepted;
