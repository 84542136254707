import { React } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import successful from "../../../Assets/Images/dashboard/successful.json";

const SuccessfulModal = (props) => {
  return (
    <Modal show={props.show} className="modalbox" centered>
      <Modal.Body>
        <div className="successfulimg">
          <Lottie animationData={successful} loop={true}></Lottie>
        </div>
        <p className="content booking">Booking Successful !</p>
        <p className="content bookquote">Have a great day ahead.</p>
        <Link className="btn bgdarkgreen" to="/dashboard">
          Home
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessfulModal;
