import React, { useState } from "react";
import signupImg from "../../Assets/Images/signup_Img.png";
import logo from "../../Assets/Images/footer-logo.png";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "../../Actions/common/common";
import { userpasswordcreation } from "../../Actions/Axios/userAxios";
function CreatePassword() {
  const location = useLocation()
  console.log("location", location);
  const email = location?.state?.Email

  const [formdata, setFormdata] = useState({})
  const [ValidateError, SetValidateError] = useState('')
  const navigate = useNavigate()
const dispatch = useDispatch();

  // const navigate = u
  console.log("formdata", formdata);
  const onchangeFunction = async (e) => {
    console.log("values", e.target.value)
    const { id, value } = e.target;
    console.log("datasval", id, value);
    setFormdata({ ...formdata, [id]: value })
  }
  const Validation = () => {
    let ValidateError = {}

    if (!formdata?.newpassword) {
      ValidateError.newpassword = "newpassword is required";
    }
    if (!formdata?.confirmpassword) {
      ValidateError.confirmpassword = "confirmpassword is required";
    }


    SetValidateError(ValidateError)

    return ValidateError;


  }
  const handleSubmit = async () => {

    var errors = {};
    let value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    }
    else {

      if (Object.keys(errors).length == 0) {

        console.log("erro length", Object.keys(errors).length, formdata)

        let formDatas = {
          "email": email,
          "password": formdata?.newpassword,
          "confirmpassword": formdata?.confirmpassword,
        }
        let resp = await userpasswordcreation(formDatas);
        console.log("responsee", resp);
        if (resp?.status == true) {
          toast.success(resp?.message)
          dispatch({
            type:"Register_patient",
            Patientregister:{patientRegister :resp?.data}
          })
          setTimeout(() => {
            navigate("/login", { state: { Email: email } })
          }, 1000);

        }
        else return toast.error(resp?.error?.confirmpassword)

      }
    }

  }

  return (
    <div className="cmn-signup create-pwd">
      <di className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_left_img">
              <img src={signupImg} alt="" className="img-fluid sign_up_img" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_input_sec">
              <div className="w-100">
                <div className="logo_sec d-flex align-items-center justify-content-center">
                  <img src={logo} alt="" className="img-fluid logo" />
                </div>
                <div className="input_details mt-4 pt-3">
                  <h3 className="heading_txt mb-0">Password Creation</h3>
                  <div className="mt-4">
                    <div className="">
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Create A New Password
                        </Form.Label>
                        <Form.Control type="text" className="cmn_input_field" id="newpassword" value={formdata?.newpassword} onChange={(e) => onchangeFunction(e)} />
                        {ValidateError && ValidateError.newpassword && <span className="error_msg">{ValidateError.newpassword}</span>}

                      </div>
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label">
                          Confirm a Password
                        </Form.Label>
                        <Form.Control type="text" className="cmn_input_field" id="confirmpassword" value={formdata?.confirmpassword} onChange={(e) => onchangeFunction(e)} />
                        {ValidateError && ValidateError.confirmpassword && <span className="error_msg">{ValidateError.confirmpassword}</span>}

                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      {/* <Link to="/login" className="text-decoration-none"> */}
                      <Button className="cmn-greenBtn" onClick={() => handleSubmit()}>Submit</Button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </di>
    </div>
  );
}

export default CreatePassword;
