import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { FaMinus } from "react-icons/fa6";
import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
import DoctorCustomInput from "../components/DoctorCustomInput";

function DoctorAddCare() {
  const navigation = useNavigate();
  const serviceCategoryOptions = [
    { value: "catogory1", label: "Category 1" },
    { value: "catogory2", label: "Category 2" },
    { value: "catogory3", label: "Category 3" },
  ];
  const serviceNameOptions = [
    { value: "servicename1", label: "Service Name 1" },
    { value: "servicename2", label: "Service Name 2" },
    { value: "servicename2", label: "Service Name 3" },
  ];
  const chargesOptions = [
    { value: "200", label: "200 $" },
    { value: "250", label: "250 $" },
    { value: "300", label: "300 $" },
  ];
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "In Active" },
  ];

  const handleAddCare = () => {
    navigation("/doctor-care-list");
  };

  return (
    <>
      {/* start of modal import */}

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4  custom_container_padding">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h5 className="text_black_md mb-0">Add Care</h5>
              <Link
                to="/doctor-care-list"
                className="d-flex align-items-center justify-content-between gap-2 gren_btn text-decoration-none"
              >
                {" "}
                <FaMinus />
                Close Care
              </Link>
            </div>
            <div className="mt-4">
              <div className="row mx-auto">
                <div className="col-sm-8 col-md-7 col-lg-6 col-xl-5 col-xxl-4 px-0">
                  <div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">
                        Service Category
                      </label>
                      <div className="mt-2 w-100">
                        <Select
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={serviceCategoryOptions}
                          placeholder="Service Category"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">Service Name</label>
                      <div className="mt-2 w-100">
                        <Select
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={serviceNameOptions}
                          placeholder="Service Name"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">Service Name</label>
                      <div className="mt-2 w-100">
                        <Select
                          isSearchable={false}
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          options={chargesOptions}
                          placeholder="200 $"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start mb-4">
                      <label className="custom_label px-0">Duration</label>
                      <div className="mt-2 w-100">
                        <DoctorCustomInput placeholder="HH : MM" />
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start ">
                      <label className="custom_label px-0">Status</label>
                      <div className="mt-2 w-100">
                        <Select
                          // value={selectedOption}
                          // onChange={handleChange}
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={statusOptions}
                          placeholder="Active"
                          classNamePrefix="custom_react_select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 mt_2rem">
                <button className="pm_button_two" onClick={handleAddCare}>
                  Cancel
                </button>
                <button className="pm_button_one" onClick={handleAddCare}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorAddCare;
