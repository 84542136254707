import { React, useState } from "react";
import serviceLeaf from "../../Assets/Images/landing/service-leaf.png";
import { IoMailOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { RxCountdownTimer } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";
import { PiCalendarCheck } from "react-icons/pi";
import { PiCalendarXBold } from "react-icons/pi";
import { Link } from "react-router-dom/dist";

const CancelledAppointments = (props) => {
  const [contents, setContents] = useState([
    {
      img: serviceLeaf,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
    },
    {
      img: serviceLeaf,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
    },
    {
      img: serviceLeaf,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
    },
    {
      img: serviceLeaf,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
    },
    {
      img: serviceLeaf,
      name: "Kelly",
      subname: "#Tw0212",
      email: "Kelly@gmail.com",
      phone: "+91-1250321462",
      datetime: "25 Apr 2024 10:45 PM",
      gv: "General Visit",
      vc: "Video Call",
    },
  ]);

  return (
    <>
      {contents.map((e) => (
        <div className="patientsappointment">
          <div className="patientsbox">
            <div>
              <div className="flex fleximg">
                <div>
                  <img src={e.img} alt="Image" className="patientimg" />
                </div>
                <div>
                  <p>{e.name}</p>
                  <small className="t-darkgreen">{e.subname}</small>
                </div>
              </div>
            </div>
            <div>
              <p>
                <IoMailOutline /> <span>{e.email}</span>
              </p>
              <p>
                <FiPhone /> <span>{e.phone}</span>
              </p>
            </div>
            <div>
              <div className="flex flextime">
                <div>
                  <p>
                    <RxCountdownTimer />
                  </p>
                </div>
                <div>
                  <p>
                    <span>{e.datetime}</span>
                    <div className="flex">
                      <span className="text-green">{e.gv}</span>
                      <span>|</span>
                      <span className="text-blue">{e.vc}</span>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            {!props.cancel && (
              <div>
                <div className="colorbox">
                  <div className="box green">
                    <IoEyeOutline />
                  </div>
                  {!props.complete && (
                    <>
                      <div className="box violet">
                        <PiCalendarCheck />
                      </div>
                      <div className="box red">
                        <PiCalendarXBold />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div>
              <Link
                className="btn btn-details"
                to={
                  props.complete
                    ? "/completed-appointment-detail"
                    : props.cancel
                    ? "/cancelled-appointment-detail"
                    : "/appointment-detail"
                }
              >
                View details
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CancelledAppointments;
