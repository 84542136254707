import { React, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import DashSidebar from "../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../UserPanel/Pages/DashboardPages/DashHeader";
import { IoReturnUpBack } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { LuClock8 } from "react-icons/lu";
import { MdDeleteOutline } from "react-icons/md";
import { Form } from "react-bootstrap";
import { InputGroup, Accordion } from "react-bootstrap";

const DoctorReschedule = () => {
  const days = [
    "Sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return (
    <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
      <div className="left_side_sec">
        <DashSidebar />
      </div>
      <div className="right_side_sec">
        <DashHeader />
        <div className="px-2 py-2 px-sm-4 py-sm-4 dashboard-content">
          {/* <DashHome /> */}
        </div>
        <div className="appointments appointmentsdate doctorreschedule">
          <Link to="/doctor-appointment" className="text-decoration-none">
            <h4 class="h4">
              Reschedule Appointment{" "}
              <span className="ps-1">
                <IoReturnUpBack />
              </span>{" "}
            </h4>
          </Link>
          <div className="formbox">
            <div className="flexbox mb-4">
              <div>
                <p className="formhead">Select Date & Time</p>
              </div>
              <div>
                <Link
                  className="btn btn-details bgdarkgreen"
                  to="/new-appointment"
                >
                  Next
                </Link>
              </div>
            </div>
            <div className="flexbox datebox">
              <div className="patientsappointment calendarbox">
                <Calendar />
              </div>
              <div className="patientsappointment dateboxtwo">
                <div className="slotsbox">
                  <div className="slotbody">
                    <p className="slothead">Booking Slots :</p>
                    <Form>
                      {days.map((d) => (
                        <>
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item>
                              <Accordion.Header>
                                <Form.Check type="checkbox" label={d} />
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="slots">
                                  <div className="flexbox mb-3 slotflex">
                                    <div>
                                      <span className="slottext">Slots</span>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        className="btn bgdarkgreen"
                                      >
                                        <span>
                                          <FiPlus />
                                        </span>{" "}
                                        Add Slots
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flexbox slottime">
                                    <div>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          value="10:00"
                                          disabled
                                        ></Form.Control>
                                        <InputGroup.Text>
                                          <LuClock8 />
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                    <div>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          value="12:00"
                                          disabled
                                        ></Form.Control>
                                        <InputGroup.Text>
                                          <LuClock8 />
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                  </div>
                                </div>
                                <div className="slots">
                                  <div className="flexbox mb-3 slotflex">
                                    <div>
                                      <span className="slottext">Slots</span>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-red"
                                      >
                                        <span>
                                          <MdDeleteOutline />
                                        </span>{" "}
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flexbox slottime">
                                    <div>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          value="10:00"
                                          disabled
                                        ></Form.Control>
                                        <InputGroup.Text>
                                          <LuClock8 />
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                    <div>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          value="12:00"
                                          disabled
                                        ></Form.Control>
                                        <InputGroup.Text>
                                          <LuClock8 />
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </>
                      ))}
                    </Form>
                  </div>
                  <div className="slotbottom">
                    <Link
                      className="btn btn-details me-3"
                      to="/doctor-dashboard"
                    >
                      Cancel
                    </Link>
                    <Link
                      className="btn btn-details bgdarkgreen"
                      to="/doctor-dashboard"
                    >
                      Confirm
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorReschedule;
