import { React, useState } from "react";
import doctor from "../../Assets/Images/dashboard/doctor-two.png";
import doctorthree from "../../Assets/Images/dashboard/doctor-three.png";
import care from "../../Assets/Images/dashboard/care.png";
import { Link } from "react-router-dom";
import DoctorDetails from "./DoctorDetails";

const Doctors = () => {
  const [doctors, setDoctors] = useState([
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctorthree,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
    {
      docimg: doctor,
      careimg: care,
      doctorname: "Dr.Akule Vivian",
      doctorsubname: "Diabetes",
    },
  ]);

  return (
    <div className="doctors">
      {doctors.map((e) => (
        <div className="patientsappointment">
          <div className="flexbox">
            <div>
              <img src={e.docimg} alt="Doctor" className="doctorimg" />
              <img src={e.careimg} alt="Doctor" className="doctoricon" />
            </div>
            <div>
              <p className="doctorname">{e.doctorname}</p>
              <span className="doctorsubname">{e.doctorsubname}</span>
              <div className="btntop">
                <Link className="btn btn-details me-2" to="/doctor-detail">
                  Details
                </Link>
                <Link className="btn btn-details bgdarkgreen" to="/">
                  Accepts
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Doctors;
