import React, { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import CustomInput from "./CustomInput";
import CustomInputTwo from "./CustomInputTwo";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "../../Actions/common/common";
import { edituserregister } from "../../Actions/Axios/userAxios";

const SettingsAccordion = (props) => {
  console.log("propsprops", props);
  const userInformationDataOne = [
    {
      id: 1,
      label: "First Name",
      type: "text",
    },
    {
      id: 2,
      label: "Last Name",
      type: "text",
    },
    {
      id: 3,
      label: "Country",
      type: "text",
    },
    {
      id: 4,
      label: "Zip Code",
      type: "text",
    },
    {
      id: 5,
      label: "Sex assigned at birth",
      type: "text",
    },
    {
      id: 6,
      label: "Date of Birth",
      type: "text",
    },
    {
      id: 7,
      label: "Email Address",
      type: "email",
    },
  ];
  const userInformationDataTwo = [
    {
      id: 1,
      label: "Mobile number",
      type: "text",
    },
    {
      id: 2,
      label: "Height",
      type: "text",
    },
    {
      id: 3,
      label: "Weight",
      type: "text",
    },
    {
      id: 4,
      label: "Medications",
      type: "text",
    },
    {
      id: 5,
      label: "Allergies",
      type: "text",
    },
    {
      id: 6,
      label: "lifestyle",
      type: "text",
    },
  ];
  const changePasswordData = [
    {
      id: 1,
      label: "Old Password",
      type: "password",
    },
    {
      id: 2,
      label: "New Password",
      type: "passwords",
    },
    {
      id: 3,
      label: "Confirm Password",
      type: "password",
    },
  ];

  const { patientRegister } = useSelector((state) => state.LoginReducer);
  console.log("payloadsettings", patientRegister);
  let fullNameList = new Array(patientRegister);
  const [formdata, setFormdata] = useState({});
  console.log("formdata", formdata);
  const [ValidateError, SetValidateError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setFormdata(patientRegister);
    // setAns(detail?.content)
    edituserregister();
  }, [patientRegister]);

  const onchangeFunction = async (e) => {
    console.log("values", e.target.value);
    e.preventDefault();
    const { id } = e.target;
    console.log("dkfjkgjkdjjg", id);
    if (id === "file") {
      var files = e.target.files[0];
      var formData = formdata;
      formData.file = files;
      console.log("files", files);
      setFormdata({ ...formData });
    } else {
      const { value, id } = e.target;
      var formData = formdata;
      formData[id] = value;
      console.log("values", value, id);
      setFormdata({ ...formData });
    }
  };
  // const result = Object.entries(patientRegister).map(([label, value]) => ({label, value}));
  // console.log("results",result);

  const Validation = () => {
    let ValidateError = {};

    if (!formdata.FirstName) {
      ValidateError.firstname = "firstname is required";
    }
    if (!formdata.LastName) {
      console.log("fdsgdfgdfgdfgdfg");
      ValidateError.lastname = "lastname is required";
    }
    if (!formdata?.Address) {
      ValidateError.Address = "Address is required";
    }
    if (!formdata.ZIPCode) {
      ValidateError.ZIPCode = "ZIPCode is required";
    }
    if (!formdata.Gender) {
      ValidateError.Gender = "Gender is required";
    }
    if (!formdata.DOB) {
      ValidateError.DOB = "DOB is required";
    }
    if (!formdata.Country) {
      ValidateError.Country = "Country is required";
    }
    if (!formdata.email) {
      ValidateError.email = "email is required";
    }
    if (!formdata.Mobileno) {
      ValidateError.Mobileno = "mobile is required";
    }
    if (!props?.file) {
      ValidateError.file = "file is required";
    }

    if (!formdata.height) {
      ValidateError.height = "height is required";
    }
    if (!formdata.weight) {
      ValidateError.weight = "weight is required";
    }
    if (!formdata.medications) {
      ValidateError.medications = "medications is required";
    }
    if (!formdata.allergies) {
      ValidateError.allergies = "allergies is required";
    }
    if (!formdata.lifestyle) {
      ValidateError.lifestyle = "lifestyle is required";
    }
    SetValidateError(ValidateError);

    return ValidateError;
  };

  const handleSubmit = async () => {
    var errors = {};
    let value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    } else {
      if (Object.keys(errors).length == 0) {
        console.log("erro length", Object.keys(errors).length, formdata);

        // let formDatas = {
        //   "FirstName": formdata?.firstname,
        //   "LastName": formdata?.lastname,
        //   "Address": formdata?.address,
        //   "ZIPCode": formdata?.zipcode,
        //   "Gender": formdata?.gender,
        //   "DOB": formdata?.Dob,
        //   "Country":formdata?.country,
        //   "email": formdata?.email,
        //   "Mobileno": formdata?.mobile

        // }

        const formDatas = new FormData();

        formDatas.append("FirstName", formdata?.FirstName);
        formDatas.append("LastName", formdata?.LastName);
        formDatas.append("Address", formdata?.Address);
        formDatas.append("ZIPCode", formdata?.ZIPCode);
        formDatas.append("Gender", formdata?.Gender);
        formDatas.append("DOB", formdata?.DOB);
        formDatas.append("Country", formdata?.Country);
        formDatas.append("email", formdata?.email);
        formDatas.append("Mobileno", formdata?.Mobileno);
        formDatas.append("id", formdata?._id);
        formDatas.append("image", props?.file);

        formDatas.append("height", formdata?.height);
        formDatas.append("weight", formdata?.weight);
        formDatas.append("medications", formdata?.medications);
        formDatas.append("allergies", formdata?.allergies);
        formDatas.append("lifestyle", formdata?.lifestyle);
        console.log("formDatas", formDatas);

        let resp = await edituserregister(formDatas);
        console.log("responsee", resp);
        if (resp?.status == true) {
          toast.success(resp?.msg);
          dispatch({
            type: "Register_patient",
            Patientregister: { patientRegister: resp?.data },
          });
          // localStorage.setItem("Email",resp?.data?.email)
          // setTimeout(() => {
          //   navigate("/otp-verification")
          // }, 1000);
        } else return toast.error(resp?.msg);
      }
    }
  };

  console.log("fullNameList", fullNameList);
  return (
    <div className="custom_accor">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>User Information</Accordion.Header>
          <Accordion.Body>
            <div>
              <div className="row">
                <div className="col-12 col-lg-6 pe-lg-4">
                  <div>
                    {/* {userInformationDataOne.map((item, index) => ( */}
                    {/* <div key={index} className="mb-4"> */}
                    {/* <label className="custom_label ps-0 mb-2">
                          {item.label}
                        </label> */}
                    <div>
                      <div>
                        <Form.Label className="cmn_input_label">
                          First Name
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.FirstName}
                          placeholder="Enter your first name"
                          onChange={(e) => onchangeFunction(e)}
                          id="FirstName"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="cmn_inp_btn mt-4">
                        <Form.Label className="cmn_input_label">
                          Last Name
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.LastName}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="LastName"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Country
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.Country}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="Country"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Zip Code
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.ZIPCode}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="ZIPCode"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Sex Assigned At Birth
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.Gender}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="Gender"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Address
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.Address}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="Address"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Date Of Birth
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.DOB}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="DOB"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Email Address
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.email}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="email"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Mobile Number
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.Mobileno}
                          placeholder="Enter your LastName"
                          onChange={(e) => onchangeFunction(e)}
                          id="Mobileno"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    {/* </div> */}
                    {/* // ))} */}
                  </div>
                </div>
                <div className="col-12 col-lg-6  ps-lg-4">
                  <div>
                    {/* {userInformationDataTwo.map((item, index) => (
                      <div key={index} className="mb-4">
                        <label className="custom_label ps-0 mb-2">
                          {item.label}
                        </label>
                        <div>
                          <CustomInputTwo />
                        </div>
                      </div>
                    ))} */}
                    <div>
                      <div>
                        <Form.Label className="cmn_input_label">
                          Height
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.height}
                          placeholder="Enter your height"
                          onChange={(e) => onchangeFunction(e)}
                          id="height"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Weight
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.weight}
                          placeholder="Enter your weight"
                          onChange={(e) => onchangeFunction(e)}
                          id="weight"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          Medications
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.medications}
                          placeholder="Enter your Medications"
                          onChange={(e) => onchangeFunction(e)}
                          id="medications"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          allergies
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.allergies}
                          placeholder="Enter your allergies"
                          onChange={(e) => onchangeFunction(e)}
                          id="allergies"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>

                    <div className="cmn_inp_btn mt-4">
                      <div>
                        <Form.Label className="cmn_input_label">
                          lifestyle
                        </Form.Label>
                        <input
                          type="text"
                          value={formdata?.lifestyle}
                          placeholder="Enter your lifestyle"
                          onChange={(e) => onchangeFunction(e)}
                          id="lifestyle"
                          className="custom_input w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap  align-items-center gap-3 mt_2rem">
                <button className="pm_button_two">Cancel</button>
                <button
                  className="pm_button_one"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Enable Two-step Verification</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            <div className="mt-3">
              <Form.Check
                type="checkbox"
                label="Allow user to turn on 2-step Verification"
                className="d-flex align-items-center custom_chckbox"
              ></Form.Check>

              <div className="mt-4 ps-5 ms-4">
                <p className="text_green_md">Enforcement</p>
                <div className="custom_radio_button">
                  <label class="container">
                    <input type="radio" name="radio" />
                    <span class="checkmark"></span>
                    Off
                  </label>
                  <label class="container">
                    <input type="radio" name="radio" />
                    <span class="checkmark"></span>
                    on
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Notification Preferences</Accordion.Header>
          <Accordion.Body>
            <div className="mt-4 ps-xxl-5 ms-xxl-4">
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0">Return and Refunds</p>
                <div className="custom_toggle">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className="mb-0">Lorem ipsum</p>
                <div className="custom_toggle">
                  <label class="switch">
                    <input type="checkbox" checked />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Change Password</Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-10 col-md-9 col-lg-8 col-xl-6 mx-auto">
                {changePasswordData.map((item, index) => (
                  <div key={index} className="mb-4">
                    <label className="custom_label ps-0 mb-2">
                      {item.label}
                    </label>
                    <div>
                      <CustomInputTwo />
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex flex-wrap justify-content-center  align-items-center gap-3 mt_2rem">
                <button className="pm_button_two">Cancel</button>
                <button className="pm_button_one">Submit</button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>{" "}
        <Accordion.Item eventKey="2">
          <Accordion.Header>Payment Information</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SettingsAccordion;
