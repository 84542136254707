import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

import DashSidebar from "../../UserPanel/Pages/DashboardPages/DashSidebar";
import DashHeader from "../../UserPanel/Pages/DashboardPages/DashHeader";
// import AppointmenCancelModal from "../modal/AppointmentCancelModal";
// import RejectReasonModal from "../modal/RejectReasonModal";

import RecordsTable from "../table/DoctorRecordsTable";
import { Link } from "react-router-dom";
// import Prescription from "../modal/Prescription";
import Prescription from "../../UserPanel/modal/Prescription";

function DoctorRecords() {
  const [showPrescription, setShowPrescription] = useState(false);

  const handleShowPrescription = () => {
    setShowPrescription(true);
  };
  const handleClosePrescription = () => {
    setShowPrescription(false);
  };
  return (
    <>
      {/* start of modal import */}
      <Prescription
        show={showPrescription}
        handleClose={handleClosePrescription}
      />

      {/* end of modal import */}
      <div className="dashboard_sec container-fluid d-flex ps-0 pe-0">
        <div className="left_side_sec">
          <DashSidebar />
        </div>
        <div className="right_side_sec">
          <DashHeader />

          <div className="mt-4">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h5 className="text_green_lg">Records</h5>
              <Link
                to="/doctor-addreport"
                className="d-flex align-items-center justify-content-between gap-2 border_less_button text-decoration-none"
              >
                {" "}
                <FaPlus />
                Add Report
              </Link>
            </div>
            <div className="mt-4">
              <RecordsTable handleShowPrescription={handleShowPrescription} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorRecords;
