import React from "react";
import bigLeaf from "../../Assets/Images/landing/big-leaf.png";
import StrokeTxt from "../../Assets/Images/landing/banner-txtStroke.png";
import whoWeAre from "../../Assets/Images/landing/who_we_are.png";
import flyingLeaf from "../../Assets/Images/landing/Flying-green-leaves.png";

import women from "../../Assets/Images/landing/women.png";
import child from "../../Assets/Images/landing/child.png";
import surgical from "../../Assets/Images/landing/surgical.png";
import leafGroup from "../../Assets/Images/landing/Flying-green-leaves.png";
import client from "../../Assets/Images/landing/client.png";
import rytArrow from "../../Assets/Images/landing/ryt-arrow.png";
import serviceLeaf from "../../Assets/Images/landing/service-leaf.png";
import contactUs from "../../Assets/Images/landing/contact_us.png";

import doctor1 from "../../Assets/Images/landing/doctor1.png";
import doctor2 from "../../Assets/Images/landing/doctor2.png";
import doctor3 from "../../Assets/Images/landing/doctor3.png";
import doctor4 from "../../Assets/Images/landing/doctor4.png";

import { Button } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";
function Land() {
  const services = [
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
  ];
  const doctors = [
    {
      img: doctor1,
      name: "Lorem ipsum",
      des: "Lorem ipsum",
    },
    {
      img: doctor2,
      name: "Lorem ipsum",
      des: "Lorem ipsum",
    },
    {
      img: doctor3,
      name: "Lorem ipsum",
      des: "Lorem ipsum",
    },
    {
      img: doctor4,
      name: "Lorem ipsum",
      des: "Lorem ipsum",
    },
  ];
  return (
    <div className="landing">
      <div className="landing_banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-xl-5 col-xxl-5 mb-5">
              <div className="left-sec">
                <p className="heading-txt mb-0 cmnLeaf_headerTxt">Welcome to</p>
                <p className="sub-heading-txt mb-0 cmn_land_subTxt">
                  Twin Tree <span className="bold-txt">OBGYN</span>
                  <p className="mb-0">& Integrated Health</p>
                </p>
                <p className="des mb-2 cmn_land_des">
                  Lorem ipsum dolor sit amet consectetur. Sed consectetur
                  phasellus aliquam tempus facilisi. Arcu dignissim neque ac at.
                  Sit eu ultrices egestas augue pharetra mattis pellentesque in.
                  Neque lectus pulvinar felis eget consequat. Massa mattis
                  turpis quisque suspendisse leo est volutpat amet vivamus.{" "}
                </p>
                <Button className="request-btn cmn-greenBtn cmn_land_btn">
                  Request For Appointment
                </Button>
              </div>
            </div>
            <div className="col-lg-7 col-xl-6 col-xxl-5">
              <div className="ryt-sec">
                {/* <div className="stroke-img"> */}
                <img src={bigLeaf} className="img-fluid bigLeaf_img" />
                <img src={StrokeTxt} className="img-fluid StrokeTxt_img" />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="who_we_are">
        <div className="container land_custom_container">
          <div className="row">
            <div className="order-2 order-lg-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="lft-sec">
                <img src={whoWeAre} className="img-fluid who_we_are_leaf" />
              </div>
            </div>

            <div className="order-1 order-lg-2 col-12 col-sm-12 col-md-12 col-lg-6 col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-lg-0">
              <div className="left-sec">
                <p className="heading-txt mb-0 cmnLeaf_headerTxt">Who We Are</p>
                <p className="sub-heading-txt mb-3 cmn_land_subTxt">
                  Lorem Ipsum Dummy
                </p>
                <p className="des cmn_land_des mb-3">
                  Lorem ipsum dolor sit amet consectetur. Sed consectetur
                  phasellus aliquam tempus facilisi. Arcu dignissim neque ac at.
                  Sit eu ultrices egestas augue pharetra mattis pellentesque in.
                  Neque lectus pulvinar felis eget consequat. Massa mattis
                  turpis quisque suspendisse leo est volutpat amet vivamus.
                </p>
                <p className="des cmn_land_des mb-3">
                  Lorem ipsum dolor sit amet consectetur. Sed consectetur
                  phasellus aliquam tempus facilisi. Arcu dignissim neque ac at.
                  Sit eu ultrices egestas augue pharetra mattis pellentesque in.
                  Neque lectus pulvinar felis eget consequat. Massa mattis
                  turpis quisque suspendisse leo est volutpat amet vivamus.
                </p>
                <Button className="read-more-btn cmn-greenBtn cmn_land_btn">
                  Read More
                  {/* <FaArrowRightLong className="ms-2 arrow_icon" /> */}
                  <GoArrowRight className="ms-2 arrow_icon fw-800" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how_we_work" name='bestServices'>
        <div className="container land_custom_container">
          <div className="header-sec text-center">
            <p className="heading-txt mb-0 cmnLeaf_headerTxt">How We Work</p>
            <p className="sub-heading-txt mb-1 cmn_land_subTxt">
              Our Best Services
            </p>
            <p className="des cmn_land_des mb-0">
              Lorem ipsum dolor sit amet consectetur. Sed consectetur phasellus
              aliquam tempus facilisi. Arcu dignissim neque ac at. Sit eu
              ultrices egestas augue pharetra mattis pellentesque in. Neque
              lectus pulvinar felis eget consequat. Massa mattis turpis quisque
              suspendisse leo est volutpat amet vivamus.
            </p>
          </div>
          <div className="services_list">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col1 mb-4">
                <div className="sec-1">
                  <img src={women} className="img-fluid women-img" />
                  <div className="details text-center">
                    <p className="mb-0 title-txt">Women's Health Services</p>
                    <p className="mb-0 des">
                      Lorem ipsum dolor sit amet consectetur. Odio dui aenean
                      dui gravida aliquam libero semper. Lobortis ipsum
                      consequat tempor a vel. Dictum arcu sem.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col2 mb-4">
                <div className="sec-2">
                  <div className="details text-center">
                    <p className="mb-0 title-txt">Pregnancy Services</p>
                    <p className="mb-0 des">
                      Lorem ipsum dolor sit amet consectetur. Odio dui aenean
                      dui gravida aliquam libero semper. Lobortis ipsum
                      consequat tempor a vel. Dictum arcu sem.
                    </p>
                  </div>
                  <img src={child} className="img-fluid child-img" />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col3">
                <div className="sec-3">
                  <img src={surgical} className="img-fluid surgical-img" />
                  <div className="details text-center">
                    <p className="mb-0 title-txt">Surgical Services</p>
                    <p className="mb-0 des">
                      Lorem ipsum dolor sit amet consectetur. Odio dui aenean
                      dui gravida aliquam libero semper. Lobortis ipsum
                      consequat tempor a vel. Dictum arcu sem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flying_leaf mt-3 mt-4 d-flex align-items-center justify-content-center">
            <Button className="read-more-btn cmn-greenBtn cmn_land_btn">
              View More Services
              <GoArrowRight className="ms-2 arrow_icon fw-800" />
            </Button>
          </div>
        </div>
        {/* <img src={leafGroup} alt="" className="flying_leaf img-fluid" /> */}
      </div>
      <div className="watch_story" name='shop'>
        <div className="container land_custom_container">
          <div className="row">
            <div className="flying-leaf d-flex align-items-center col-12 col-sm-12 col-md-12 col-lg-6 col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-lg-0">
              <div className="left-sec">
                <p className="heading-txt mb-0 cmnLeaf_headerTxt">
                  Watch Story
                </p>
                <p className="sub-heading-txt mb-3 cmn_land_subTxt">
                  Our Client Says
                </p>
                <p className="des cmn_land_des mb-3">
                  Lorem ipsum dolor sit amet consectetur. Sed consectetur
                  phasellus aliquam tempus facilisi. Arcu dignissim neque ac at.
                  Sit eu ultrices egestas augue pharetra mattis pellentesque in.
                  Neque lectus pulvinar felis eget consequat. Massa mattis
                  turpis quisque suspendisse leo est volutpat amet vivamus.
                </p>

                <Button className="read-more-btn cmn-greenBtn cmn_land_btn">
                  View More
                  <GoArrowRight className="ms-2 arrow_icon fw-800" />
                </Button>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="ryt-sec position-relative">
                <img src={client} className="img-fluid client_img" />
                <img src={rytArrow} className="img-fluid ryt_arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how_we_work_new" name='appointments'>
        <div className="container land_custom_container">
          <div className="header-sec text-center">
            <p className="heading-txt d-inline mb-0 cmnLeaf_headerTxt">
              How We Work
            </p>
            <p className="sub-heading-txt mb-1 cmn_land_subTxt">
              Our Best Services
            </p>
            <p className="des cmn_land_des mb-0">
              Lorem ipsum dolor sit amet consectetur. Sed consectetur phasellus
              aliquam tempus facilisi. Arcu dignissim neque ac at. Sit eu
              ultrices egestas augue pharetra mattis pellentesque in. Neque
              lectus pulvinar felis eget consequat. Massa mattis turpis quisque
              suspendisse leo est volutpat amet vivamus.
            </p>
          </div>
          <div className="service_lists">
            <div className="row">
              {services.map((item, index) => {
                return (
                  <div className="col col-12 col-sm-12 col-md-6 col-lg-4  mb-4 mb-lg-5">
                    <div className="cmn-sec">
                      <img
                        src={serviceLeaf}
                        className="img-fluid service_leaf"
                      />
                      <div className="content-sec">
                        <p className="mb-0 title-txt">Lorem Ipsum</p>
                        <p className="mb-0 des">
                          Lorem ipsum dolor sit amet consectetur. Sed
                          consectetur phasellus aliquam tempus facilisi. Arcu
                          dignissim neque ac at. Sit eu ultric......
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="land_contact_us">
        <div className="container land_custom_container">
          <div className="ins_sec">
            <div className="row">
              <div className="col-lg-6 lft_col mb-4">
                <div className="left-sec">
                  <p className="d-inline heading-txt mb-0 cmnLeaf_headerTxt">
                    Contact Us
                  </p>
                  <p className="sub-heading-txt mb-0 cmn_land_subTxt">
                    Lorem Ipsum Dummy
                  </p>
                  <p className="des cmn_land_des mb-3">
                    Lorem ipsum dolor sit amet consectetur. Sed consectetur
                    phasellus aliquam tempus facilisi. Arcu dignissim neque ac
                    at. Sit eu ultrices egestas augue pharetra mattis
                    pellentesque in. Neque lectus pulvinar felis eget consequat.
                    Massa mattis turpis quisque suspendisse leo est volutpat
                    amet vivamus.
                  </p>

                  <Button className="read-more-btn cmn-greenBtn cmn_land_btn">
                    View More
                    <GoArrowRight className="ms-2 arrow_icon fw-800" />
                  </Button>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="ryt-sec position-relative">
                  <img src={contactUs} className="img-fluid contact_us_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how_we_work new" name = 'meetDoctor'>
        <div className="container land_custom_container">
          <div className="header-sec text-center">
            <p className="heading-txt mb-0 cmnLeaf_headerTxt">How We Work</p>
            <p className="sub-heading-txt mb-1 cmn_land_subTxt">
              Meet Our Doctors
            </p>
            <p className="des cmn_land_des mb-0">
              Lorem ipsum dolor sit amet consectetur. Sed consectetur phasellus
              aliquam tempus facilisi. Arcu dignissim neque ac at. Sit eu
              ultrices egestas augue pharetra mattis pellentesque in. Neque
              lectus pulvinar felis eget consequat. Massa mattis turpis quisque
              suspendisse leo est volutpat amet vivamus.
            </p>
          </div>
          <div className="doctors_list mt-5">
            <div className="row">
              {doctors.map((item, index) => {
                return (
                  <>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col mb-4 mb-lg-0">
                      <div className="cmn-sec">
                        <img src={item.img} className="img-fluid doctor-img" />
                        <div className="details text-center">
                          <p className="mb-0 title-txt">{item.name}</p>
                          <p className="mb-0 des">{item.des}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="mt-3 mt-4 d-flex align-items-center justify-content-center">
            <Button className="read-more-btn cmn-greenBtn cmn_land_btn">
              View More
              <GoArrowRight className="ms-2 arrow_icon fw-800" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Land;
