import React from "react";
import Lottie from "lottie-react";
import Modal from "react-bootstrap/Modal";

import { IoClose } from "react-icons/io5";

import cancel from "../../Assets/Images/cancel.png";

const CancelAppointment = (props) => {
  const { show, handleClose } = props;
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="md"
        centered
        className="th_custom_modal "
      >
        <Modal.Header className="position-relative">
          <button
            className="circle_cls_button position-absolute"
            onClick={handleClose}
          >
            <IoClose fontSize={23} />
          </button>
        </Modal.Header>

        <Modal.Body className="">
          <div className="">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={cancel}
                alt="calender"
                className="img-fluid"
                style={{ width: "40px" }}
              />
            </div>

            <h6 className="fw_550 mt-3 text-center">Are you sure</h6>
            <p className="text_black_xxs text-center">
              Do you want to cancel this appointment?
            </p>
            <div class="mt_2rem d-flex flex-column">
              <label class="text_black_sm ps-0">
                Cancel Reason <span class="requried_icon">*</span>
              </label>
              <textarea class="mt-2 prm_textarea"></textarea>
            </div>
            <div className="d-flex align-items-center gap-3 mt-3">
              <button className="pm_button_two" onClick={handleClose}>
                Done
              </button>
              <button className="pm_button_one" onClick={handleClose}>
                Reschedule Appointment
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CancelAppointment;
