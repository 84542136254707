import axios from "axios";

import config from "../../config/config.js";
import {
  Decryptdata,
  EncryptData,
  AppenData,
} from "../Helper/EncryptDecrypt.js";

const jwttoken = localStorage.getItem("token");
console.log("jwttoken", jwttoken);

export const RegisterPatient = async (data) => {
  console.log("data", data);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.BACK_URL}/patient/userregister`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      data: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};

export const verify = async (data) => {
  console.log("data", data);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.BACK_URL}/patient/verify`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      data: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};

export const userpasswordcreation = async (data) => {
  console.log("data", data);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.BACK_URL}/patient/userpasswordcreation`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      data: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};

export const userlogin = async (data) => {
  console.log("data", data);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.BACK_URL}/patient/userlogin`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      data: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};

export const patienthelpdeskGrievance = async (data) => {
  console.log("data", data);
  try {
    // let formdata = AppenData(data)

    let resp = await axios({
      method: "post",
      url: `${config.BACK_URL}/patient/patienthelpdeskGrievance`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      data: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    console.log("respss", resp);

    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};

export const getPatientdetails = async (data) => {
  console.log("data", data);
  try {
    let resp = await axios({
      method: "get",
      url: `${config.BACK_URL}/patient/getPatientdetails`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      params: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};

export const edituserregister = async (data) => {
  console.log("edituserregister", data);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.BACK_URL}/patient/edituserregister`,
      // "headers":{
      //     'Content-Type': 'application/json',
      //     "Authorization": jwttoken
      // },
      data: data,
    });
    // resp.data = await Decryptdata(resp?.data)
    return resp.data;
  } catch (err) {
    console.log("err in gettok owner", err);
  }
};
// export const getCmsContent = async (data) => {

//     try {
//         var resp = await axios({

//             'method': 'GET',
//             'url': `${config.Admin_Url}/cms/getcmslist`,
//             "headers":{
//                 'Content-Type': 'application/json',
//                 "Authorization": jwttoken
//             },
//             params: { data: EncryptData(data) }
//         })
//         console.log("tokonwer resp", resp.data)
//         let response = Decryptdata(resp?.data)
//         return response;
//     }
//     catch (err) { console.log("err in gettok owner", err) }
// }

// export const Newsletter = async(data) =>{
//     console.log("emailletter",data);
//     console.log("tokensss",localStorage.getItem("token"));
//     let formdata = AppenData(data)
//     try{
//         let resp = await axios({

//             method: 'POST',
//             url: `${config.Admin_Url}/user/newsletter`,
//             'headers': {
//                 "Content-Type": 'application/json',
//                 "Authorization":localStorage.getItem("token")
//             },
//             data: formdata[0]

//         })
//         resp.data = await Decryptdata(resp?.data)
//         return resp.data;
//     }
//     catch(err){console.log("err in gettok owner",err)}
// }
