// import React, { useState } from "react";
// import signupImg from "../../Assets/Images/signup_Img.png";
// import logo from "../../Assets/Images/footer-logo.png";
// import Form from "react-bootstrap/Form";
// import { toast } from "react-toastify";

// import { Button } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import { useLocation } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
// import { getlogintype, isEmpty } from "../../Actions/common/common";
// import { userlogin } from "../../Actions/Axios/userAxios";
// function Login() {
//   const location = useLocation();
//   console.log("location_login", location);
//   // const email = location?.state?.Email
//   const [formdata, setFormdata] = useState({});
//   const [ValidateError, SetValidateError] = useState("");
//   const navigate = useNavigate();
//   // let email = localStorage.setItem("Email",formdata?.email)
//   // console.log("email",email);
//   const dispatch = useDispatch();

//   // const navigate = u
//   console.log("formdata", formdata);
//   const onchangeFunction = async (e) => {
//     console.log("values", e.target.value);
//     const { id, value } = e.target;
//     console.log("datasval", id, value);
//     setFormdata({ ...formdata, [id]: value });
//   };

//   const Validation = () => {
//     let ValidateError = {};

//     if (!formdata?.email) {
//       ValidateError.email = "email is required";
//     }
//     if (!formdata?.password) {
//       ValidateError.password = "password is required";
//     }

//     SetValidateError(ValidateError);

//     return ValidateError;
//   };

//   const handleSubmit = async () => {
//     var errors = {};
//     let value = Validation();

//     if (!isEmpty(value)) {
//       console.log("value", value);
//       SetValidateError(value);
//       toast.error("fix all validation");
//     } else {
//       if (Object.keys(errors).length == 0) {
//         console.log("erro length", Object.keys(errors).length, formdata);

//         let formDatas = {
//           email: formdata?.email,
//           password: formdata?.password,
//         };
//         let resp = await userlogin(formDatas);
//         console.log("responsee", resp);
//         if (resp?.status == true) {
//           toast.success(resp?.msg);
//           dispatch({
//             type: "Register_patient",
//             Patientregister: { patientRegister: resp?.data },
//           });
//           localStorage.setItem("token", resp?.token);
//           localStorage.setItem("email", resp?.data?.email);
//           localStorage.setItem("type", "patient");
//           getlogintype(localStorage.getItem("type"));

//           // console.log("localstrogarte", localStorage.length <= 0 ? false : true);

//           setTimeout(() => {
//             navigate("/dashboard", { state: { Email: resp?.data?.email } });
//           }, 1000);
//         } else return toast.error(resp?.msg);
//       }
//     }
//   };
//   return (
//     <div className="cmn-signup login">
//       <di className="container">
//         <div className="row">
//           <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
//             <div className="cmn_left_img">
//               <img src={signupImg} alt="" className="img-fluid sign_up_img" />
//             </div>
//           </div>
//           <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
//             <div className="cmn_input_sec">
//               <div className="w-100">
//                 <div className="logo_sec d-flex align-items-center justify-content-center">
//                   <img src={logo} alt="" className="img-fluid logo" />
//                 </div>
//                 <div className="input_details mt-4 pt-3">
//                   <h3 className="heading_txt mb-0">Login</h3>
//                   <div className="mt-4">
//                     <div className="">
//                       <div className="mb-3 mb-xxl-4">
//                         <Form.Label className="cmn_input_label">
//                           Email ID
//                         </Form.Label>
//                         <Form.Control
//                           type="text"
//                           className="cmn_input_field"
//                           id="email"
//                           value={formdata?.email}
//                           onChange={(e) => onchangeFunction(e)}
//                         />
//                         {ValidateError && ValidateError.email && (
//                           <span className="error_msg">
//                             {ValidateError.email}
//                           </span>
//                         )}
//                       </div>
//                       <div className="mb-3 mb-xxl-4">
//                         <Form.Label className="cmn_input_label">
//                           Password
//                         </Form.Label>
//                         <Form.Control
//                           type="password"
//                           className="cmn_input_field"
//                           id="password"
//                           value={formdata?.password}
//                           onChange={(e) => onchangeFunction(e)}
//                         />
//                         {ValidateError && ValidateError.password && (
//                           <span className="error_msg">
//                             {ValidateError.password}
//                           </span>
//                         )}
//                       </div>
//                     </div>
//                     <div className="cmn_inp_btn mt-4">
//                       {/* <Link to="/dashboard" className="text-decoration-none"> */}
//                       <Button
//                         className="cmn-greenBtn"
//                         onClick={() => handleSubmit()}
//                       >
//                         Submit
//                       </Button>
//                       {/* zsfsed */}
//                       {/* </Link> */}
//                     </div>
//                     <div className="already_have mt-4 text-center">
//                       <span className="">
//                         Don't have a account?
//                         <Link to="/signup" className="ms-2">
//                           Signup
//                         </Link>
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </di>
//     </div>
//   );
// }

// export default Login;

import React, { useState } from "react";
import signupImg from "../../Assets/Images/signup_Img.png";
import logo from "../../Assets/Images/footer-logo.png";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { FaEyeSlash, FaEye } from "react-icons/fa";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const handleSwitchPassword = () => {
    setPasswordShow(!passwordShow);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (email == "patient@gmail.com" && password == "Test@123") {
      localStorage.setItem("type", "patient");
      navigate("/dashboard");
    } else if (email == "doctor@gmail.com" && password == "Test@123") {
      localStorage.setItem("type", "doctor");
      navigate("/doctor-dashboard");
    } else {
      console.log("both are not");
    }
  };
  return (
    <div className="cmn-signup login">
      <di className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_left_img">
              <img src={signupImg} alt="" className="img-fluid sign_up_img" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col">
            <div className="cmn_input_sec">
              <div className="w-100">
                <div className="logo_sec d-flex align-items-center justify-content-center">
                  <img src={logo} alt="" className="img-fluid logo" />
                </div>
                <div className="input_details mt-4 pt-3">
                  <h3 className="heading_txt mb-0">Login</h3>
                  <div className="mt-4">
                    <div className="">
                      <div className="mb-3 mb-xxl-4">
                        <Form.Label className="cmn_input_label ps-0">
                          Email ID
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => handleEmail(e)}
                          type="text"
                          placeholder="Enter your email"
                          className="cmn_input_field"
                        />
                      </div>
                      <div className="">
                        <Form.Label className="cmn_input_label ps-0">
                          Password
                        </Form.Label>

                        {/* <Form.Control
                          type="text"
                          className="cmn_input_field"
                          onChange={(e) => handlePassword(e)}
                        /> */}

                        <div className="auth_pass_wrap">
                          <div className="row h-100">
                            <div className="col-10 px-0">
                              <input
                                type={passwordType}
                                name="password"
                                className="h-100"
                                placeholder="Enter your password"
                                onChange={(e) => handlePassword(e)}
                              />
                            </div>
                            <div className="col-2 px-0 d-flex justify-content-end">
                              <button
                                onClick={handleSwitchPassword}
                                className="auth_pass_icons"
                              >
                                {passwordShow ? (
                                  <FaEye fontSize={16} />
                                ) : (
                                  <FaEyeSlash fontSize={16} />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cmn_inp_btn mt-5">
                      <Button className="cmn-greenBtn" onClick={handleSubmit}>
                        Submit
                      </Button>
                      {/* </Link> */}
                    </div>
                    <div className="already_have mt-4 text-center">
                      <span className="">
                        Don't have a account?
                        <Link to="/signup" className="ms-2">
                          Signup
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </di>
    </div>
  );
}

export default Login;
